import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '../../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '../../../../../@jumbo/components/Common/formElements/AppTextInput';
import Button from '@material-ui/core/Button';
import { requiredMessage, lettersOnlyMessage } from '../../../../../@jumbo/constants/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import {
  addNewClassCategory,
  getClassCategoryList,
  updateClassCategoryList,
  addManuallyClassCategory,
} from '../../../../../redux/actions/ClassCategory';
import { emptySpace, numberOnly, numberOnly2, stringOnly, onlyStringWithSpace } from '../../../../../@jumbo/constants/ValidationRegex';
import { Typography } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import InputAdornment from '@material-ui/core/InputAdornment';
import NoRecordFound from '../NoRecordFound';
import { capitalizeFLetter } from '@jumbo/utils/commonHelper';
import AppSelectBox from '../../../../../@jumbo/components/Common/formElements/AppSelectBox';
import InputLabel from '@material-ui/core/InputLabel';
// import AddToWarehouse from './AddToWarehouse'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import DialogActions from '@material-ui/core/DialogActions';
import ErrorTable from '../ErrorTable';
import Divider from '@material-ui/core/Divider';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ErrorIcon from '@material-ui/icons/Error';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },

  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.text.primary,
    },
  },
  serviceTypeList: {
    fontSize: 14,
    letterSpacing: 0.25,
    textOverflow: 'ellipsis',
    wordWrap: 'break-word',
  },
  typeList: {
    width: 535,
    fontSize: 14,
    height: 300,
    backgroundColor: theme.palette.background.default,
    overflow: 'auto',
  },
  imageButton: {
    fontSize: '10px',
    marginLeft: '5px',
    color: theme.palette.text.primary,
    textOverflow: 'ellipsis',
    maxWidth: '200px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  imageRoot: {
    marginTop: '5%',
  },
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
    '& .MuiFormLabel-root': {
      color: '#909090',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      color: theme.palette.text.primary,
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
  errText: {
    color: 'red',
    marginLeft: '16px',
    marginRight: '14px',
    fontSize: '0.75rem',
    marginTop: '3px',
    textAlign: 'left',
    fontFamily: 'Normal',
    fontWeight: 400,
    lineHeight: 1.66,
  },
  appSelectBox: {
    color: '#c7c3bf',
    '& .MuiFormLabel-root': {
      color: '#c7c3bf',
    },
  },
  titleRoot2: {
    fontWeight: '300',
    marginLeft: '5px',
    fontSize: 14,
    textOverflow: 'ellipsis',
    maxWidth: '350px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  paper: {
    position: 'relative',
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '1000px',
      // width: '100%',
      minWidth: '500px',
    },
  },
}));

const AddEditUser = ({ open, onCloseDialog, getSystemCtegorieParams, setOpenWareHouseDialog,setAddButton,addButton,setErrroDialog }) => {
  const classes = useStyles();
  const { currentUser, error } = useSelector(({ CLASSCATEGORY }) => CLASSCATEGORY);
  const { users } = useSelector(({ SYSTEMCATEGORIE }) => SYSTEMCATEGORIE);
  // console.log('currentuser', currentUser);
  const {
    orderBy,
    order,
    debouncedSearchTerm,
    page,
    rowsPerPage,
    filterOptions,
    classCategory,
    id,
  } = getSystemCtegorieParams;
  const [classFile, setClassFile] = useState('');
  const [systemCategorie, setSystemCategorie] = useState('');
  const [classCategoryId, setClassCategoryId] = useState('');
  const [csvError, setCsvError] = useState('');
  const [systemCategorieError, setSystemCategorieError] = useState('');
  const [classCategoryError, setClassCategoryError] = useState('');
  const [value, setValue] = React.useState('1');
  const [addManually, setAddManually] = React.useState(true);
  const dispatch = useDispatch();
  // const [errroDialog, setErrroDialog] = React.useState(false);
  const handleChange = event => {
    setValue(event.target.value);
    setCsvError('');
    setSystemCategorieError('');
    setClassCategoryError('');
  };
  useEffect(() => {
    if (value == 1) setAddManually(false);
    if (value == 2) setAddManually(true);
  }, [value]);

  useEffect(() => {
    if (currentUser) {
      setSystemCategorie(currentUser?.system_category_details?.name);
      setClassCategoryId(currentUser?.name);
    }
  }, [currentUser]);

  const onSubmitClick = () => {
    if (!classCategoryId.match(onlyStringWithSpace)) {
      setClassCategoryError(lettersOnlyMessage);
    }
    if (!classCategoryId || classCategoryId.match(emptySpace)) {
      setClassCategoryError(requiredMessage);
    }
    if (!systemCategorie) {
      setSystemCategorieError(requiredMessage);
    }
    if (
      classFile.type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
      classFile.type != 'text/csv'
    ) {
      setCsvError('Please upload excel or csv file');
    }
    // if( classFile.type !='text/csv'){
    //   setCsvError("Please choose Excel file.");
    // }
    if (!classFile) {
      setCsvError(requiredMessage);
    }

    if (
      (classFile &&
        systemCategorie &&
        classFile.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
      (classFile && systemCategorie && classFile.type == 'text/csv')
    ) {
      setAddButton(true)
      onUserSave();
      onCloseDialog();
    }
    if (systemCategorie && classCategoryId && !classCategoryId.match(emptySpace) && classCategoryId.match(onlyStringWithSpace)) {
      onUserSave();
    }
  };

  const onUserSave = () => {
    if (currentUser) {
      // alert("edit")
      const formData = {
        category_name: classCategoryId,
      };

      dispatch(
        updateClassCategoryList(currentUser?._id, formData, () => {
          onCloseDialog();
          dispatch(
            getClassCategoryList(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions, classCategory, id),
          );
        }),
      );
    }
    if (addManually) {
      // alert("manually")
      const manuallyData = {
        parent_id: systemCategorie,
        name: classCategoryId,
      };
      dispatch(
        addManuallyClassCategory(manuallyData, () => {
          onCloseDialog();
          dispatch(
            getClassCategoryList(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions, classCategory, id),
          );
        }),
      );
    }
    // else
    if (classFile) {
      // alert("bulk")
      const formData = new FormData();
      formData.append('categoryImportFile', classFile);
      formData.append('type', 2);
      formData.append('_id', systemCategorie);

      dispatch(
        addNewClassCategory(
          formData,
          {
            setErrroDialog: () => setErrroDialog(true),
            onCloseDialog: () => onCloseDialog(),
            setAddButton: () =>setAddButton(false)
          },
          () => {
            // onCloseDialog();
            dispatch(
              getClassCategoryList(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions, classCategory, id),
            );
          },
        ),
      );
    }
  };
  const handleAddSystemCategory = e => {
    setSystemCategorie(prev => e.target.value);
    setSystemCategorieError('');
  };
  const handelClose = () => {
    setErrroDialog(false);
    onCloseDialog();
  };

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>
        {currentUser ? 'Edit Class Category' : 'Import Class Category'}
      </DialogTitle>
      <Box>
      </Box>
      <DialogContent dividers>
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
          <GridContainer>
            {currentUser ? (
              <>
                <Grid item xs={12} sm={12}>
                  <AppTextInput
                    className={currentUser ? '' : classes.root}
                    fullWidth
                    disabled={true}
                    variant="outlined"
                    label="System Category Name"
                    value={currentUser?.system_category_details?.name}
                    // onChange={e => {
                    //   setSystemCategorie(prev => e.target.value);
                    //   setSystemCategorieError('');
                    // }}
                    helperText={currentUser ? null : systemCategorieError}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <AppTextInput
                    className={classes.root}
                    fullWidth
                    variant="outlined"
                    label="Class Category Name"
                    value={classCategoryId}
                    onChange={e => {
                      setClassCategoryId(e.target.value);
                      setClassCategoryError('');
                      setAddManually(false);
                    }}
                    helperText={classCategoryError}
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} sm={12} className={currentUser ? classes.appSelectBox : classes.root}>
                  <AppSelectBox
                    fullWidth
                    data={users?.data}
                    disabled={currentUser ? true : false}
                    label="System Category Name"
                    valueKey="_id"
                    variant="outlined"
                    labelKey="name"
                    value={systemCategorie}
                    onChange={e => {
                      // setSystemCategorie(prev => e.target.value);
                      // setSystemCategorieError('');
                      // currentUser?'': setAddManually(false)
                      handleAddSystemCategory(e);
                    }}
                    helperText={currentUser ? null : systemCategorieError}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl component="fieldset">
                    <RadioGroup aria-label="query" name="query1" value={value} onChange={handleChange}>
                      <FormControlLabel value="1" control={<Radio />} label="Bulk Import" />
                      <FormControlLabel value="2" control={<Radio />} label="Add Class Category" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {value == 2 ? (
                  <Grid item xs={12} sm={12}>
                    <AppTextInput
                      className={classes.root}
                      fullWidth
                      variant="outlined"
                      label="Class Category Name"
                      value={classCategoryId}
                      // onKeyPress={event => {
                      //   if (['-', '+', '.', ' '].includes(event.key)) {
                      //     event.preventDefault();
                      //   }
                      // }}
                      onChange={e => {
                        setClassCategoryId(e.target.value);
                        setClassCategoryError('');
                      }}
                      helperText={classCategoryError}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={12}>
                    <Button variant="contained" component="label">
                      <CloudUploadIcon />
                      <p className={classes.imageButton}>{classFile?.size ? classFile?.name : 'Choose Excel File'}</p>
                      <input
                        type="file"
                        hidden
                        onChange={event => {
                          setClassFile(event.target.files[0]);
                          setCsvError('');
                        }}
                        accept=".xlsx"
                      />
                    </Button>{' '}
                    <br />
                    <p className={classes.errText} style={{ color: 'red' }}>
                      {csvError}
                    </p>
                  </Grid>
                )}
              </>
            )}
          </GridContainer>
        </Box>
        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button onClick={onCloseDialog} variant="outlined">
            Cancel
          </Button>

          <Box ml={2}>
            <Button variant="contained" color="primary" style={{ minWidth: '100px' }} onClick={onSubmitClick} disabled={addButton&&!currentUser?true:false}>
              {/* {value == '1' ? 'Import' : 'Add'} */}
              {currentUser ? 'Update' : value == '1' ? 'Import' : 'Add'}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditUser;

AddEditUser.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
