import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import TableRow from '@material-ui/core/TableRow';
import { timeFromNow } from '@jumbo/utils/dateHelper';
import { Block, CheckCircleOutline, Delete, Edit, Mail, MoreHoriz, Visibility } from '@material-ui/icons';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import CmtAvatar from '@coremat/CmtAvatar';
import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { sentMailToUser, updateUserStatus } from 'redux/actions/Users';
import { getSERVICETYPE, updateSERVICETYPEStatus } from 'redux/actions/ServiceType';
import { getTenantBlukImport } from 'redux/actions/TenantBlukImport';
import { Activate, Deactivate } from '@jumbo/constants/TableLableConstant';
// import DownloadIcon from '@material-ui/icons/DownloadIcon';
// import DownloadIcon from '@mui/icons-material/Download';
import GetAppIcon from '@material-ui/icons/GetApp';

import moment from 'moment';
const useStyles = makeStyles(theme => ({
  titleRoot: {
    // marginRight: '15px',
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    fontWeight: '300',
    color: theme.palette.text.primary,
    textOverflow: 'ellipsis',
    width:'150px',
    maxWidth: '150px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  fileName: {
    marginLeft: '15px',
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    fontWeight: '300',
    color: theme.palette.text.primary,
    textOverflow: 'ellipsis',
    width:'150px',
    maxWidth: '150px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  titleRoot2: {
    marginLeft: '20px',
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    fontWeight: '300',
    color: theme.palette.text.primary,
    textOverflow: 'ellipsis',
    maxWidth: '100px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  textSize: {
    fontWeight: 300,
  },
}));

// const getUserActions = user => {
//   const actions = [
//     { action: 'view', label: 'View', icon: <Visibility /> },
// { action: 'edit', label: 'Edit', icon: <Edit /> },
// { action: 'email', label: 'Email', icon: <Mail /> },
// ];

// if (user.status === 1) {
//   actions.push({ action: 'suspend', label: Deactivate, icon: <Block /> });
// } else {
//   actions.push({
//     action: 'activate',
//     label: Activate,
//     icon: <CheckCircleOutline />,
//   });
// }

// actions.push({ action: 'delete', label: 'Delete', icon: <Delete /> });
//   return actions;
// };

const UserListRow = ({ row, isSelected, onRowClick, onUserEdit, onUserDelete, onUserView, getTenantParams, indexId }) => {
  const { orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions } = getTenantParams;
  console.log('sercivelistrow', row);

  const classes = useStyles();
  const dispatch = useDispatch();

  // console.log("rowfile",row)
  const onUserMenuClick = menu => {
    if (menu.action === 'view') {
      onUserView(row);
    } else if (menu.action === 'suspend') {
      dispatch(updateSERVICETYPEStatus({ _id: row._id, status: 2 }, refreshContactList));
    } else if (menu.action === 'activate') {
      dispatch(updateSERVICETYPEStatus({ _id: row._id, status: 1 }, refreshContactList));
    }
  };

  const handelFileDownload = () => {
    window.location.href = row?.file_link;
  };

  function refreshContactList() {
    // console.log("page",orderBy, order,filterOptions, debouncedSearchTerm, page, rowsPerPage)
    dispatch(getTenantBlukImport(orderBy, order, filterOptions, debouncedSearchTerm, page, rowsPerPage));
  }
  const labelId = `enhanced-table-checkbox-${row?._id}`;
  const isItemSelected = isSelected(row?._id);
  // const userActions = getUserActions(row);

  return (
    <TableRow
      hover
      // onClick={event => onRowClick(event, row.id)}
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row?._id}
      selected={isItemSelected}>
      {/* <TableCell></TableCell> */}
      <TableCell component="th" id={labelId} scope="row" padding="none">
        <Typography className={classes.titleRoot2} component="div" variant="h4">
          {indexId + 1}
        </Typography>
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        <Typography className={classes.fileName} component="div" variant="h4">
          {row?.file_name}
        </Typography>
      </TableCell>

      <TableCell component="th" id={labelId} scope="row" padding="none">
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {!row?.user_details ? '-' : row?.user_details.first_name}
        </Typography>
      </TableCell>

      <TableCell>
      <Typography className={classes.titleRoot} component="div" variant="h4">
        {row.created_at ? moment(row.created_at * 1000).format('DD/MM/YYYY ') : <b>-</b>}
      </Typography>
      </TableCell>
      {/* <TableCell><GetAppIcon onClick={handelFileDownload}/></TableCell> */}
      <TableCell>
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {/* {!row?.model?"-":row?.model} */}
          <GetAppIcon onClick={handelFileDownload} style={{ cursor: 'pointer' }} />
        </Typography>
      </TableCell>

      <TableCell className={classes.textSize}>{row?.status === 1 ? `Success` : 'Failure'}</TableCell>

      <TableCell align="center" style={{ cursor: 'pointer' }}>
        <Visibility onClick={() => onUserView(row)} />
      </TableCell>

      {/* <TableCell align="center" onClick={event => event.stopPropagation()}>
        <CmtDropdownMenu items={userActions} onItemClick={onUserMenuClick} TriggerComponent={<Edit />} />
      </TableCell> */}
    </TableRow>
  );
};

export default React.memo(UserListRow);
