import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import CmtAvatar from '../../../../@coremat/CmtAvatar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import { useDispatch, useSelector } from 'react-redux';
import CmtList from '../../../../@coremat/CmtList';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import useStyles from './index.style';
import { Block, CheckCircleOutline } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import { getContactUsDetails } from '../../../../redux/actions/ContactUsQuery';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import FaceIcon from '@material-ui/icons/Face';
import moment from 'moment';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import ScheduleIcon from '@material-ui/icons/Schedule';

const UserDetailView = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  const { currentUser } = useSelector(({ CONTACTUSQUERY }) => CONTACTUSQUERY);
  const { details } = useSelector(({ CONTACTUSQUERY }) => CONTACTUSQUERY);
  console.log('current user', details);

  const {
    username,
    email,
    status,
    phones,
    company,
    designation,
    profile_pic,
    starred,
    _id,
    query,
    created_at,
    image,
  } = currentUser;
  // const dispatch = useDispatch()
  //   useEffect(() => {
  //     dispatch(
  //       getContactUsDetails( _id)
  //     )
  //     },[])
  console.log('error', image);
  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <Box className={classes.userInfoRoot}>
        <Box mr={3} display="flex" alignItems="center">
          <Box className={classes.avatarView} mr={{ xs: 4, md: 6 }}>
            <CmtAvatar size={70} src={profile_pic} alt={username} />
          </Box>

          <Box mt={-2}>
            <Box
              display="flex"
              alignItems="center"
              style={{
                fontSize: 14,
                letterSpacing: 0.25,
                textOverflow: 'ellipsis',
                maxWidth: '350px',
                display: 'block',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}>
              <Typography className={classes.titleRoot}>{username?.toUpperCase()}</Typography>
              <Box ml={1}>
                {/* <Checkbox
                  icon={<StarBorderIcon />}
                  checkedIcon={<StarIcon style={{ color: '#FF8C00' }} />}
                  checked={starred}
                /> */}
              </Box>
            </Box>
            {(designation || company) && (
              <Box mt={-1}>
                {designation && <Typography className={classes.subTitleRoot}>{designation}</Typography>}
                {company && <Typography className={classes.subTitleRoot}>@{company}</Typography>}
              </Box>
            )}
          </Box>
        </Box>
        <Box ml="auto" mt={-2} display="flex" alignItems="center">
          <Box ml={1}>
            <Tooltip title={status}>
              <IconButton aria-label="filter list">
                {status === 'suspended' && <Block color="primary" />}
                {status === 'active' && <CheckCircleOutline color="primary" />}
              </IconButton>
            </Tooltip>
          </Box>
          <Box ml={1}>
            <IconButton onClick={onCloseDialog}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box px={6} py={5}>
        <Box mb={5} component="p" color="common.dark" className={classes.titleRoot1}>
          Contact Us Query Detail
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <FaceIcon />

          <Box ml={5} component="p" className="pointer" display="flex">
            <Typography className={classes.titleRoot1}>Name : </Typography>
            <Typography className={classes.titleRoot2}>{username}</Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <EmailIcon />
          <Box ml={5} component="p" className="pointer" display="flex">
            <Typography className={classes.titleRoot1}>Email :</Typography>
            <Typography className={classes.titleRoot2}>{email}</Typography>
          </Box>
        </Box>

        <Box display="flex" mb={{ xs: 4, sm: 7 }}>
          <LiveHelpIcon />
          <Box ml={5} component="p" className="pointer" style={{ whiteSpace: 'nowrap' }} display="flex">
            {/* Query :{' '} */}
            <Typography className={classes.titleRoot1}>Query :</Typography>
            <Typography className={classes.titleRoot2}>{query}</Typography>
          </Box>{' '}
          {/* <Box>
            <span
              style={{
                fontSize: 14,
                letterSpacing: 0.25,
                textOverflow: 'ellipsis',
                maxWidth: '300px',
              }}>
              {' '}
              {query}{' '}
            </span>
          </Box> */}
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 5 }}>
          <GridContainer>
            {details?.image?.map(item => (
              <Grid item xs={6} lg={6} sm={6} className={classes.profileSidebar} alignalignSelf="center">
                <img src={item} alt="contactUsImage" width="200px" />
              </Grid>
            ))}
          </GridContainer>
        </Box>

        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 5 }}>
          <CalendarTodayOutlinedIcon />
          <Box ml={5} display="flex">
            <Typography className={classes.titleRoot1}>Date :</Typography>
            <Typography className={classes.titleRoot2}>{moment(created_at * 1000).format('DD/MM/YYYY')}</Typography>
          </Box>
        </Box>

        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 5 }}>
          <ScheduleIcon />
          <Box ml={5} display="flex">
            <Typography className={classes.titleRoot1}>Time :</Typography>
            <Typography className={classes.titleRoot2}>{moment(created_at * 1000).format('hh:mm A')}</Typography>
          </Box>
        </Box>

        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 5 }}>
          {status === 1 ? <CheckCircleOutline /> : <Block />}
          <Box ml={5} display="flex">
            <Typography className={classes.titleRoot1}>Status :</Typography>
            <Typography className={classes.titleRoot2}>{status === 1 ? 'Open' : 'Closed'}</Typography>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default UserDetailView;

UserDetailView.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
