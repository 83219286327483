import { fetchError, fetchStart, fetchSuccess } from './Common';
// import axios from 'axios';
import axios from '../../services/auth/jwt/config';
import { history } from 'redux/store';
import {
  // ADD_ISSUEMANAGEMENT,
  DELETE_BULK_ISSUEMANAGEMENT,
  DELETE_ISSUEMANAGEMENT,
  EDIT_ISSUEMANAGEMENT,
  FETCH_DATA_SUCCESS,
  GET_ISSUEMANAGEMENT,
  SET_ISSUEMANAGEMENT_DETAILS,
} from '../../@jumbo/constants/ActionTypes';
import { addIssue, listIssue, updateIssue, updateIssueApi,deleteIssueApi } from '../../@jumbo/constants/ApiConstatnt';
import { setAuthUser } from './Auth';

// get FM provider list
export const getIssueManagement = (sortBy, sort, searchTerm, page, limit, filterOptions, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());

    axios
      .get(listIssue, {
        params: { sortBy: `${sortBy}:${sort}`, q: searchTerm, page: page + 1, limit: limit, status: filterOptions[0] },
      })
      .then(data => {
        
        // if(searchTerm && !data?.data?.data?.providers?.length && data?.data?.data?.page>1) {dispatch(getIssueManagement(sortBy, sort, searchTerm, 0, limit, filterOptions, callbackFun))}
        if (data.status === 200) {
          // dispatch(fetchSuccess());
          dispatch({type:FETCH_DATA_SUCCESS,})
          dispatch({ type: GET_ISSUEMANAGEMENT, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Issue list not found.') {
          if (callbackFun) callbackFun();
          dispatch(fetchError());
          dispatch({ type: GET_ISSUEMANAGEMENT, payload: [] });
        } 
       else if(error?.response?.status===401 ){
          dispatch(setAuthUser(null));
          history.push('/signin')
        } 
        
        else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

// set current user for view tab
export const setCurrentIssueManagement = user => {
  return dispatch => {
    dispatch({ type: SET_ISSUEMANAGEMENT_DETAILS, payload: user });
  };
};

// add new FM provider 
export const addNewIssueManagement = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(addIssue, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('New Issue added successfully.'));
          }, 2000);

          // dispatch({ type:  ADD_ISSUEMANAGEMENT, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if(error?.response?.status===401 ){
          dispatch(setAuthUser(null));
          history.push('/signin')
        } else{
  
        
          dispatch(fetchError(error?.response?.data?.message));}
      });
  };
};

export const updateIssueManagementStatus = (data, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(updateIssue, data)
      .then(response => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Issue status updated successfully.'));
          }, 2000);
          // dispatch({ type: EDIT_USER, payload: response.data });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if(error?.response?.status===401 ){
          dispatch(setAuthUser(null));
          history.push('/signin')
        } else{
  
        
          dispatch(fetchError(error?.response?.data?.message));}
      });
  };
};
export const updateIssueData = (id,user, callbackFun) => {
  return dispatch => {
    
    dispatch(fetchStart());
    axios
      .put(updateIssueApi+id, user)
      .then(data => {
        setTimeout(() => {
          dispatch(fetchSuccess('Issue updated successfully .'));
        }, 2000);
          if (callbackFun) callbackFun();
       
      })
      .catch(error => {
        
        if(error?.response?.status===401 ){
          dispatch(setAuthUser(null));
          history.push('/signin')
        } 
        else {
          console.log('error',error?.response?.status)
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};
// all function below are not in use currntly
export const deleteBulkUsers = (userIds, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/users/bulk-delete', { userIds })
      .then(response => {
        if (response.status === 200) {
          dispatch(fetchSuccess('Selected users were deleted successfully.'));
          dispatch({ type: DELETE_BULK_ISSUEMANAGEMENT, payload: userIds });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const deleteUser = (userId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete(deleteIssueApi+userId)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess('Issue deleted successfully.'));
          // dispatch({ type: DELETE_ISSUEMANAGEMENT, payload: userId });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const sentMailToUser = () => {
  return dispatch => {
    dispatch(fetchSuccess('Email has been sent to user successfully'));
  };
};

export const updateUser = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/users', user)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess('Selected user was updated successfully.'));
          dispatch({ type: EDIT_ISSUEMANAGEMENT, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};
