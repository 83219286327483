import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
const useStyles = makeStyles({
  table: {
    minWidth: 650,
    '& .MuiTableCell-head': {
      fontWeight: '600',
    },
  },
  tableRow: {
    fontWeight: '300',
  },
  tableRow1: {
    color: 'white',
    backgroundColor: 'red',
  },
  descriptionField: {
    fontSize: 14,
    fontWeight: '300',
    maxHeight: "80px",
    textOverflow: 'ellipsis',
    // maxWidth: "50px",
    overflow: 'auto',
    wordBreak: 'break-all',
    // backgroundColor: '#f4f4f7',
    // color: '#3B1A45',
    minWidth: '200px',
  },
    errorDescriptionField: {
    color: 'white',
    backgroundColor: 'red',
    fontSize: 14,
    fontWeight: '300',
    maxHeight: "80px",
    textOverflow: 'ellipsis',
    overflow: 'auto',
    wordBreak: 'break-all',
    minWidth: '200px',
  },
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    textOverflow: 'ellipsis',
    maxWidth: '250px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  errorTableHeadOther: {
    fontSize: 16,
    fontWeight: '600',
    backgroundColor: '#f4f4f7',
    color: '#3B1A45',
  },
});

export default function ErrorTable() {
  const classes = useStyles();
  const { details } = useSelector(({ BULKCONTRACTOR }) => BULKCONTRACTOR);
  const { details_table } = useSelector(({ BULKCONTRACTOR }) => BULKCONTRACTOR);
  const { error_details, rows_with_errors } = details_table;
  const [indexValue, setindexValue] = useState([]);
  
  const rows = [
    '',
    'email',
    'first_name',
    'last_name',
    'vendor_email',
    'service_type_name',
    'contractor_certificate_name',
    'document_name (others)',
    'description (others)',
    'expiry_date',
    'contact_number',
  ];

  const rowsChangeName = [
    '',
    'Row No.',
    'Email',
    'First Name',
    'Last Name',
    'Vendor Email',
    'Service Type Name',
    'Contractor Certificate Name',
    'Document Name (others)',
    'Description (others)',
    'Expiry Date',
    'Contact Number',
  ];

  useEffect(() => {
    const ErrorValue = [];
    const arr = rows.map((item, index) => {
      const err = error_details.forEach(error => {
        if (index == error.column) {
          return ErrorValue.push({ id: item, row: error.row, msg: error.message });
        }
      });

      setindexValue(ErrorValue);
    });
  }, []);

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table" id="table">
        <TableHead>
          <TableRow>
            {/* {rows.map((item, index) => {
              return (
                <> */}
                                  {rowsChangeName?.map((data, index) =>{
                  return (
                  <TableCell className={classes.errorTableHeadOther}>{data}</TableCell>
                  );
                })}
            {/* //     </>
            //   );
            // })} */}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows_with_errors?.map((row, index) => {
            return (
              <>
                <TableRow className={classes.tableRow}>
                  <TableCell></TableCell>
                  <TableCell component="th" scope="row" onClick={({ target }) => console.log(target.textContent)}>
                      <Typography
                        className={
                          indexValue?.find(item => item.id == 'row' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {row?.row == 'FieldEmpty' ? '-' : row?.row}{' '}
                      </Typography>
                    </TableCell>
                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'email' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'email' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'email' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {row?.email == 'FieldEmpty' ? '-' : row?.email}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'first_name' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'first_name' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'first_name' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {row?.first_name == 'FieldEmpty' ? '-' : row?.first_name}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'last_name' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'last_name' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'last_name' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {row?.last_name == 'FieldEmpty' ? '-' : row?.last_name}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'vendor_email' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'vendor_email' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'vendor_email' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {row?.vendor_email == 'FieldEmpty' ? '-' : row?.vendor_email}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'service_type_name' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'service_type_name' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'service_type_name' && item.row == row.row)
                            ? classes.errorDescriptionField
                            : classes.descriptionField
                        }>
                        {row?.service_type_name == 'FieldEmpty' ? '-' : row?.service_type_name}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>
                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'contractor_certificate_name' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'contractor_certificate_name' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'contractor_certificate_name' && item.row == row.row)
                            ? classes.errorDescriptionField
                            : classes.descriptionField
                        }>
                        {row?.contractor_certificate_name == 'FieldEmpty' ? '-' : row?.contractor_certificate_name}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'document_name (others)' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'document_name (others)' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'document_name (others)' && item.row == row.row)
                            ? classes.errorDescriptionField
                            : classes.descriptionField
                        }>
                        {row['document_name (others)'] == 'FieldEmpty' ? '-' : row['document_name (others)']}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'description (others)' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'description (others)' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'description (others)' && item.row == row.row)
                            ? classes.errorDescriptionField
                            : classes.descriptionField
                        }>
                        {row['description (others)'] == 'FieldEmpty' ? '-' : row['description (others)']}
                      </Typography>
                    </TableCell>
                  </Tooltip>
                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'expiry_date' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'expiry_date' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'expiry_date' && item.row == row.row)
                            ? classes.errorDescriptionField
                            : classes.descriptionField
                        }>
                        {row?.expiry_date == 'FieldEmpty' ? '-' : row?.expiry_date}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'contact_number' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'contact_number' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'contact_number' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {row?.contact_number == 'FieldEmpty' ? '-' : row?.contact_number}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>
                </TableRow>
              </>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
