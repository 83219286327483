import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Divider from '@material-ui/core/Divider';
import { useDispatch, useSelector } from 'react-redux';
import { handelServiceRequsetApproveWork, getServiceRequsetDetails } from '../../../../../redux/actions/ServiceRequest';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 200,
  },
}));

export default function ApproveWork() {
  const { details } = useSelector(({ SERVICEREQUEST }) => SERVICEREQUEST);
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handelApproveSubmit = () => {
    dispatch(
      handelServiceRequsetApproveWork(details?._id, () => {
        setOpen(false);
      }),
    );
    dispatch(getServiceRequsetDetails(details?._id));
  };

  return (
    <Box>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Approve Work
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Approve Work'}</DialogTitle>
        <Divider />
        <DialogContent>
          <Box flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <DialogContentText id="alert-dialog-description">Are You Sure You Wish to Approve this Work ?</DialogContentText>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setOpen(false)} variant="outlined">
            Cancel
          </Button>
          <Button onClick={handelApproveSubmit} color="primary" autoFocus style={{ minWidth: '100px' }} variant="contained">
            Approve
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
