import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';
import {
  GET_CLASSCATEGORYLIST,
  SET_CLASSCATEGORY_DETAILS,
  GET_CLASSCATEGORYDETAILS,
  SET_CLASSCATEGORY_ERROR,
} from '../../@jumbo/constants/ActionTypes';
import {
  addClassCategory,
  listClassCategory,
  classCategoryStatusUpdate,
  classCategoryupdate,
  classCategoriedetails,
  deleteClassCategorie,
  addClassCategoryManually,
} from '../../@jumbo/constants/ApiConstatnt';

// get Item list
export const getClassCategoryList = (sortBy, sort, searchTerm, page, limit, filterOptions, category, id, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(listClassCategory, {
        params: {
          sortBy: `${sortBy}:${sort}`,
          search: searchTerm,
          page: page + 1,
          limit: limit,
          status: filterOptions[0],
          type: category,
          _id: id,
        },
      })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_CLASSCATEGORYLIST, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError());
          dispatch({ type: GET_CLASSCATEGORYLIST, payload: [] });
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Item list not found.') {
          dispatch({ type: GET_CLASSCATEGORYLIST, payload: [] });
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

// set current user for view tab
export const setClassCategoryList = user => {
  return dispatch => {
    dispatch({ type: SET_CLASSCATEGORY_DETAILS, payload: user });
  };
};

// add new FM provider
export const addNewClassCategory = (user, callbackFun, listCall) => {
  return dispatch => {
    dispatch(fetchStart());
    dispatch(fetchSuccess('Class category bulk import process has been start.'));
    axios
      .post(addClassCategory, user)
      .then(data => {
        if (data) {
          if (data?.data?.data?.red_snakbar) {
            setTimeout(() => {
              dispatch(fetchError(data?.data?.message));
              // dispatch(fetchSuccess('New Class Category added successfully.'));
            }, 2000);
          } else {
            setTimeout(() => {
              dispatch(fetchSuccess(data?.data?.message));
              // dispatch(fetchSuccess('New Class Category added successfully.'));
            }, 2000);
          }

          if (callbackFun) {
            callbackFun.setErrroDialog();
          }
          if (listCall) listCall(data.data);
          // }

          dispatch({ type: SET_CLASSCATEGORY_ERROR, payload: data?.data });
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
        callbackFun.onCloseDialog();
        callbackFun.setAddButton()
      });
  };
};

// add manually class category
export const addManuallyClassCategory = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(addClassCategoryManually, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('New class category added successfully.'));
          }, 2000);
          // if(AddToWarehouse){
          //   dispatch({ type: GET_WAREHOUSELIST, payload: data.data.data });
          // }
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

export const updateClassCategoryStatus = (data, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(classCategoryStatusUpdate, data)
      .then(response => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Class category status updated successfully.'));
          }, 3000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

export const getClassCategoryDetails = (userId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(classCategoriedetails + userId)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_CLASSCATEGORYDETAILS, payload: data.data.data });

          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Item List not found.') {
          dispatch(fetchError(error?.response?.data?.message));
          dispatch({ type: GET_CLASSCATEGORYDETAILS, payload: [] });
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

export const setClassCategoryDetails = user => {
  return dispatch => {
    dispatch({ type: SET_CLASSCATEGORY_DETAILS, payload: user });
  };
};

export const deleteClassCategory = (userId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete(deleteClassCategorie + userId)
      .then(data => {
        if (data.status === 200) {
          setTimeout(() => {
            dispatch(fetchSuccess('Class category deleted successfully.'));
          }, 2000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError());
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

export const sentMailToUser = () => {
  return dispatch => {
    dispatch(fetchSuccess('Email has been sent to user successfully'));
  };
};

export const updateClassCategoryList = (id, user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(classCategoryupdate + id, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Class category updated successfully.'));
          }, 3000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};
