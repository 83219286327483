import { fetchError, fetchStart, fetchSuccess } from './Common';
// import axios from 'axios';
import axios from '../../services/auth/jwt/config';

import {
  GET_TENANTBLUKIMPORT,
  FETCH_DATA_SUCCESS,
  GET_EXPORT_DATA,
  ADD_TENANTBULKIMPORT,
  SET_TENANTBULK_IMPORT_DETAILS,
  GET_TENANT_SAMPLE_FILES_DETAILS,
  GET_TENANTDETAILS_TABLE,
} from '@jumbo/constants/ActionTypes';
import {
  listTenantBlukImport,
  addnewTenantBulkImport,
  getExportData,
  tenantBulkImortDetails,
  sampleFileDownload,
} from './../../@jumbo/constants/ApiConstatnt';
// import { listTenantBlukImport } from '@jumbo/constants/ApiConstatnt';
// import {listTenantBlukImport , addServiceType,updateServiceTypeStatus, updateServiceType,deleteServiceType} from './../../@jumbo/constants/ApiConstatnt'
import { history } from 'redux/store';
import { setAuthUser } from './Auth';

export const getTenantBlukImport = (sortBy, sort, filterOptions, searchTerm, page, limit, tenantType, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(listTenantBlukImport, {
        params: {
          sortBy: `${sortBy}:${sort}`,
          search: searchTerm,
          page: page + 1,
          limit: limit,
          status: filterOptions[0],
          type: tenantType,
        },
      })
      .then(data => {
        if (data.status === 200) {
          // dispatch(fetchSuccess());
          dispatch({ type: FETCH_DATA_SUCCESS });
          dispatch({ type: GET_TENANTBLUKIMPORT, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Tenant bulk list not found.') {
          if (callbackFun) callbackFun();
          dispatch(fetchError());
          dispatch({ type: GET_TENANTBLUKIMPORT, payload: [] });
        } else if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

// export const setCurrentTenantBluk = TenantBulkUser => {
//   return dispatch => {
//     dispatch({ type: SET_TENANTBULK_IMPORT_DETAILS, payload: TenantBulkUser });
//   };
// };
// GET_TENANTDETAILS_TABLE
export const setCurrentTenantBluk = (_id, onlyRowData, callbackFun) => {
  return dispatch => {
    if(!onlyRowData) {
      dispatch(fetchStart());
      axios
        .get(tenantBulkImortDetails + _id, 0)
        .then(data => {
          if (data.status === 200) {
            dispatch({ type: FETCH_DATA_SUCCESS });
            dispatch({ type: SET_TENANTBULK_IMPORT_DETAILS, payload: data.data.data });
            if (callbackFun) callbackFun(data.data);
          } else {
            dispatch(fetchError('There was something issue in responding server.'));
          }
        })
        .catch(error => {
          if (error?.response?.data?.message === 'Tenant bulk details not found.') {
            if (callbackFun) callbackFun();
            dispatch(fetchError());
            dispatch({ type: SET_TENANTBULK_IMPORT_DETAILS, payload: [] });
          } else if (error?.response?.status === 401) {
            dispatch(setAuthUser(null));
            history.push('/signin');
          } else {
            dispatch(fetchError(error?.response?.data?.message));
          }
        });
    }
    else {
      dispatch(fetchStart());
      axios
        .get(tenantBulkImortDetails + _id, {
          params: { rows_with_errors_field: onlyRowData }
        })
        .then(data => {
          if (data.status === 200) {
            dispatch({ type: FETCH_DATA_SUCCESS });
            dispatch({ type: GET_TENANTDETAILS_TABLE, payload: data.data.data });
            if (callbackFun) callbackFun(data.data);
          } else {
            dispatch(fetchError('There was something issue in responding server.'));
          }
        })
        .catch(error => {
          if (error?.response?.data?.message === 'Tenant bulk details not found.') {
            if (callbackFun) callbackFun();
            dispatch(fetchError());
            dispatch({ type: GET_TENANTDETAILS_TABLE, payload: [] });
          } else if (error?.response?.status === 401) {
            dispatch(setAuthUser(null));
            history.push('/signin');
          } else {
            dispatch(fetchError(error?.response?.data?.message));
          }
        });
    }
  };
};

export const getTenantSampleFileDetails = (userId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(sampleFileDownload + userId, { params: { type: userId } })
      .then(data => {
        if (data.status === 200) {
          dispatch({ type: FETCH_DATA_SUCCESS });
          dispatch({ type: GET_TENANT_SAMPLE_FILES_DETAILS, payload: data.data.data });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Item List not found.') {
          dispatch(fetchError(error?.response?.data?.message));
          dispatch({ type: GET_TENANT_SAMPLE_FILES_DETAILS, payload: [] });
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

export const getExportSampleFileData = (userId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios({
      method: 'get',
      url: `${getExportData}?fm_admin_id=${userId}`,
      // url: 'https://node.inheritxdev.in/QDIS-Dev/v1/bulkImport/asset/export-data?fm_admin_id=623d560b9b94062fc1b1fdd9',
      responseType: 'blob',
    })
      .then(response => {
        console.log('responsedata', response);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
        dispatch(fetchSuccess('FM admin data exported successfully.'));
        if (callbackFun) callbackFun(response.data);
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Item List not found.') {
          dispatch(fetchError(error?.response?.data?.message));
          dispatch({ type: GET_EXPORT_DATA, payload: [] });
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

export const addNewBulkImport = (user, callbackFun, listCall) => {
  return dispatch => {
    // dispatch(fetchStart());
    dispatch(fetchSuccess('Bulk import process has been started.'));
    axios
      .post(addnewTenantBulkImport, user)
      .then(data => {
        console.log('added data', data);
        if (data) {
          // dispatch(fetchSuccess('Is in Progressing....'));

          if (data?.data?.data?.red_snakbar) {
            setTimeout(() => {
              dispatch(fetchError(data?.data?.message));
            }, 2000);
          } else {
            setTimeout(() => {
              dispatch(fetchSuccess(data?.data?.message));
            }, 2000);
          }
          // dispatch({ type:  ADD_FMPROVIDER, payload: data.data });
          if (callbackFun) {
            callbackFun.setErrroDialog();
          }
          if (listCall) listCall(data.data);
          dispatch({ type: ADD_TENANTBULKIMPORT, payload: data?.data });
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
          callbackFun.onCloseDialog();
          callbackFun.setAddButton();
        }
      });
  };
};

// export const sentMailToUser = () => {
//   return dispatch => {
//     dispatch(fetchSuccess('Email has been sent to user successfully'));
//   };
// };

// export const updateServiceTypeData = (id,user, callbackFun) => {
//   return dispatch => {

//     dispatch(fetchStart());
//     axios
//       .put(updateServiceType+id, user)
//       .then(data => {
//         setTimeout(() => {
//           dispatch(fetchSuccess('Service Type updated successfully .'));
//         }, 2000);
//           if (callbackFun) callbackFun();

//       })
//       .catch(error => {

//         if(error?.response?.status===401 ){
//           dispatch(setAuthUser(null));
//           history.push('/signin')
//         }
//         else {
//           console.log('error',error?.response?.status)
//           dispatch(fetchError(error?.response?.data?.message));
//         }
//       });
//   };
// };

// export const updateSERVICETYPEStatus = (data, callbackFun) => {
//   return dispatch => {
//     dispatch(fetchStart());
//     axios
//       .put(updateServiceTypeStatus, data)
//       .then(response => {
//         if (data) {
//           setTimeout(() => {
//             dispatch(fetchSuccess('Service Type status updated successfully.'));
//           }, 2000);
//           // dispatch({ type: EDIT_USER, payload: response.data });
//           if (callbackFun) callbackFun();
//         } else {
//           dispatch(fetchError('There was something issue in responding server.'));
//         }
//       })
//       .catch(error => {
//         if (error?.response?.status === 401) {
//           dispatch(setAuthUser(null));
//           history.push('/signin');
//         } else {
//           dispatch(fetchError(error?.response?.data?.message));
//         }
//       });
//   };
// };

// export const deleteBulkUsers = (userIds, callbackFun) => {
//   return dispatch => {
//     dispatch(fetchStart());
//     axios
//       .put('/users/bulk-delete', { userIds })
//       .then(response => {
//         if (response.status === 200) {
//           dispatch(fetchSuccess('Selected users were deleted successfully.'));
//           dispatch({ type: DELETE_BULK_SERVICETYPE, payload: userIds });
//           if (callbackFun) callbackFun();
//         } else {
//           dispatch(fetchError('There was something issue in responding server.'));
//         }
//       })
//       .catch(error => {
//         dispatch(fetchError('There was something issue in responding server'));
//       });
//   };
// };

// export const deleteUser = (userId, callbackFun) => {
//   return dispatch => {
//     dispatch(fetchStart());
//     axios
//       .delete(deleteServiceType+userId)
//       .then(data => {
//         if (data.status === 200) {
//           dispatch(fetchSuccess('Service type deleted successfully.'));
//           // dispatch({ type: DELETE_SERVICETYPE, payload: userId });
//           if (callbackFun) callbackFun();
//         } else {
//           dispatch(fetchError('There was something issue in responding server.'));
//         }
//       })
//       .catch(error => {
//         dispatch(fetchError(error?.response?.data?.message));
//       });
//   };
// };
