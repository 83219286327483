import React from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import BuildIcon from '@material-ui/icons/Build';
import { useSelector } from 'react-redux';
import CmtList from '../../../../../@coremat/CmtList';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import useStyles from './index.style';
import { Block, CheckCircleOutline } from '@material-ui/icons';
import CategoryIcon from '@material-ui/icons/Category';


const UserDetailView = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  const { details } = useSelector(({ CLASSCATEGORY }) => CLASSCATEGORY);
  const { name, status,system_category_details} = details;
  // console.log("name",status)

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <Box className={classes.userInfoRoot}>
        <Box mr={3} display="flex" alignItems="center">
          <Box className={classes.avatarView} mr={{ xs: 4, md: 6 }}></Box>

          <Box mt={-2}>
            <Box display="flex" alignItems="center">
              <Typography className={classes.titleRoot}>Class Category Detail</Typography>
              <Box ml={1}></Box>
            </Box>
          </Box>
        </Box>
        <Box ml="auto" mt={-2} display="flex" alignItems="center">
          <Box ml={1}></Box>
          <Box ml={1}>
            <IconButton onClick={onCloseDialog}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box px={6} py={5}>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <CategoryIcon />
          <Box ml={5}  display="flex">
            <Typography className={classes.titleRoot1}>Class Category Name : </Typography>
            <Typography className={classes.titleRoot2}>{name}</Typography>
          </Box>
        </Box>

        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <CategoryIcon />
          <Box ml={5}  display="flex">
            <Typography className={classes.titleRoot1}>System Category Name : </Typography>
            <Typography className={classes.titleRoot2}>{system_category_details?.name}</Typography>
          </Box>
        </Box>
        

        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 5 }}>
          {status === 1 ? <CheckCircleOutline /> : <Block />}
          <Box ml={5} display="flex">
          <Typography className={classes.titleRoot1}>Status (Active/Inactive) :</Typography>
            <Typography className={classes.titleRoot2}>{status === 1 ? 'Active' : 'Inactive'}</Typography>

             </Box>
        </Box>
      </Box>
  </Dialog>
  );
};

export default UserDetailView;

UserDetailView.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
