import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';
import {
  GET_ASSETLIST,
  SET_CLASSCATEGORY_DETAILS,
  GET_ASSETDETAILS,
  GET_ASSETDETAILS_TABLE,
  GET_ERRORLIST,
  SET_ASSETTYPE_EXPORT,
} from '../../@jumbo/constants/ActionTypes';
import {
  addAsset,
  listAsset,
  classCategoryStatusUpdate,
  classCategoryupdate,
  assetdetails,
  deleteClassCategorie,
  assettypeimportfile,
} from '../../@jumbo/constants/ApiConstatnt';

// get Item list
export const getAssetList = (sortBy, sort, searchTerm, page, limit, filterOptions, Asset, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(listAsset, {
        params: {
          sortBy: `${sortBy}:${sort}`,
          search: searchTerm,
          page: page + 1,
          limit: limit,
          status: filterOptions[0],
          type: Asset,
        },
      })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_ASSETLIST, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError());
          dispatch({ type: GET_ASSETLIST, payload: [] });
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Item list not found.') {
          dispatch({ type: GET_ASSETLIST, payload: [] });
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

// set current user for view tab
export const setClassCategoryList = user => {
  return dispatch => {
    dispatch({ type: SET_CLASSCATEGORY_DETAILS, payload: user });
  };
};

// add new FM provider
export const addNewAsset = (user, callbackFun, listCall) => {
  return dispatch => {
    // dispatch(fetchStart());
    dispatch(fetchSuccess('Bulk import process has been start.'));
    axios
      .post(addAsset, user)
      .then(data => {
        if (data) {
          if (data?.data?.data?.red_snakbar) {
            setTimeout(() => {
              dispatch(fetchError(data?.data?.message));
            }, 2000);
          } else {
            setTimeout(() => {
              dispatch(fetchSuccess(data?.data?.message));
            }, 2000);
          }
          if (callbackFun) {
            callbackFun.setErrroDialog();
            // callbackFun.onCloseDialog();
          }
          if (listCall) listCall(data.data);
          dispatch({ type: GET_ERRORLIST, payload: data?.data });
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        // callbackFun.onCloseDialog()

        dispatch(fetchError(error?.response?.data?.message));
        callbackFun.onCloseDialog();
        callbackFun.setAddButton();
      });
  };
};

export const updateClassCategoryStatus = (data, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(classCategoryStatusUpdate, data)
      .then(response => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Class category status updated successfully.'));
          }, 3000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

export const getAssetDetails = (userId, onlyRowData, callbackFun) => {
  return dispatch => {
    if(!onlyRowData){
      dispatch(fetchStart());
      axios
        .get(assetdetails + userId, 0)
        .then(data => {
          if (data.status === 200) {
            dispatch(fetchSuccess());
            dispatch({ type: GET_ASSETDETAILS, payload: data.data.data });
  
            if (callbackFun) callbackFun();
          } else {
            dispatch(fetchError('There was something issue in responding server.'));
          }
        })
        .catch(error => {
          if (error?.response?.data?.message === 'Item List not found.') {
            dispatch(fetchError(error?.response?.data?.message));
            dispatch({ type: GET_ASSETDETAILS, payload: [] });
          } else {
            dispatch(fetchError(error?.response?.data?.message));
          }
        });
    }
    else {
      dispatch(fetchStart());
      axios
        .get(assetdetails + userId, {
          params: { rows_with_errors_field: onlyRowData }
        })
        .then(data => {
          if (data.status === 200) {
            dispatch(fetchSuccess());
            dispatch({ type: GET_ASSETDETAILS_TABLE, payload: data.data.data });
  
            if (callbackFun) callbackFun();
          } else {
            dispatch(fetchError('There was something issue in responding server.'));
          }
        })
        .catch(error => {
          if (error?.response?.data?.message === 'Item List not found.') {
            dispatch(fetchError(error?.response?.data?.message));
            dispatch({ type: GET_ASSETDETAILS_TABLE, payload: [] });
          } else {
            dispatch(fetchError(error?.response?.data?.message));
          }
        });
    }
  };
};

export const setClassCategoryDetails = user => {
  return dispatch => {
    dispatch({ type: SET_CLASSCATEGORY_DETAILS, payload: user });
  };
};

export const deleteClassCategory = (userId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete(deleteClassCategorie + userId)
      .then(data => {
        if (data.status === 200) {
          setTimeout(() => {
            dispatch(fetchSuccess('Class category deleted successfully.'));
          }, 2000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError());
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

export const sentMailToUser = () => {
  return dispatch => {
    dispatch(fetchSuccess('Email has been sent to user successfully'));
  };
};

export const updateClassCategoryList = (id, user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(classCategoryupdate + id, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Class category updated successfully.'));
          }, 3000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

// export const getImportFile = (userId, callbackFun) => {
//   return dispatch => {
//     dispatch(fetchStart());
//     axios
//       .get(assettypeimportfile, {
//         params: { fm_admin_id:userId},
//       },{
//         headers: {
//             'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
//         }
//     })

//       .then(data => {
//         if (data.status === 200) {

//           dispatch(fetchSuccess("gjhdfjh"));
//           dispatch({ type: SET_ASSETTYPE_EXPORT, payload: data.data.data });

//           if (callbackFun) callbackFun();
//         } else {
//           dispatch(fetchError('There was something issue in responding server.'));
//         }
//       })
//       .catch(error => {
//         if (error?.response?.data?.message === 'Item List not found.') {
//           dispatch(fetchError(error?.response?.data?.message));
//           dispatch({ type: SET_ASSETTYPE_EXPORT, payload: [] });
//         } else {
//           dispatch(fetchError(error?.response?.data?.message));
//         }
//       });
//   };
// };

export const getImportFile = (userId, callbackFun, dialog) => {
  return dispatch => {
    dispatch(fetchStart());
    axios({
      method: 'get',
      url: `${assettypeimportfile}?fm_admin_id=${userId}`,
      // url: 'https://node.inheritxdev.in/QDIS-Dev/v1/bulkImport/asset/export-data?fm_admin_id=623d560b9b94062fc1b1fdd9',
      responseType: 'blob',
    })
      .then(response => {
        console.log('responsedata', response);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
        dispatch(fetchSuccess('FM admin data exported successfully.'));
        if (callbackFun) callbackFun(response.data);
      })
      .catch(error => {
        if (error?.response?.status === 400) {
          dispatch(fetchError('Data not found'));
          dialog.onCloseDialog();
          // dispatch({ type: SET_ASSETTYPE_EXPORT, payload: [] });
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};
