import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Block, CheckCircleOutline, Delete, Edit, Visibility } from '@material-ui/icons';
import CmtDropdownMenu from '../../../../../@coremat/CmtDropdownMenu';
import {  Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { sentMailToUser } from '../../../../../redux/actions/Users';
import { getTypeCategoryList, updateTypeCategoryStatus } from '../../../../../redux/actions/TyepCategory';
import { Activate, Deactivate } from '../../../../../@jumbo/constants/stringConstant';
const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    fontWeight:"300",
    color: theme.palette.text.primary,
    textOverflow: 'ellipsis',
    maxWidth: '150px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  textSize:{
    fontWeight:300
  }
}));

const UserListRow = ({
  row,
  isSelected,
  
  onUserEdit,
  onUserDelete,
  onUserView,
  getSERVICETYPEParams,
  indexId,
}) => {
  const { orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions,typeCategory,id } = getSERVICETYPEParams;
  const { authUser } = useSelector(({ auth }) => auth);
  
  const classes = useStyles();
  const dispatch = useDispatch();
  
  const getUserActions = user => {
    const actions =  [
          { action: 'view', label: 'View', icon: <Visibility /> },
          { action: 'edit', label: 'Edit', icon: <Edit /> },
          
        ];

        if (user.status === 1) {
          actions.push({ action: 'suspend', label: Deactivate, icon: <Block /> });
        } else {
          actions.push({
            action: 'activate',
            label: Activate,
            icon: <CheckCircleOutline />,
          });
        }
    actions.push({ action: 'delete', label: 'Delete', icon: <Delete /> });
    return actions;
  };
  const onUserMenuClick = menu => {
    if (menu.action === 'view') {
      onUserView(row);
    } else if (menu.action === 'edit') {
      onUserEdit(row);
    } else if (menu.action === 'email') {
      dispatch(sentMailToUser());
    } else if (menu.action === 'suspend') {
      dispatch(updateTypeCategoryStatus({ _id: row._id, status: 2 }, refreshContactList));
    } else if (menu.action === 'activate') {
      dispatch(updateTypeCategoryStatus({ _id: row._id, status: 1 }, refreshContactList));
    } else if (menu.action === 'delete') {
      onUserDelete(row);
    }
  };

  function refreshContactList() {
    dispatch(getTypeCategoryList(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions,typeCategory,id));
  }
  const labelId = `enhanced-table-checkbox-${row?._id}`;
  const isItemSelected = isSelected(row?._id);
  const userActions = getUserActions(row);

  return (
    <TableRow hover role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={row?._id} selected={isItemSelected}>
      <TableCell padding="checkbox"></TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {indexId + 1}
        </Typography>
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {row?.name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {!row?.class_category_details?"-":row?.class_category_details.name}
        </Typography>
      </TableCell>
      
      <TableCell className={classes.textSize}>{row?.status === 1 ? `Active` : 'Inactive'}</TableCell>
      <TableCell align="center" onClick={event => event.stopPropagation()}>
        <CmtDropdownMenu items={userActions} onItemClick={onUserMenuClick} TriggerComponent={<Edit />} />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(UserListRow);
