import React from "react";
import { Redirect, Route, Switch } from "react-router";
import Login from "./Auth/Login";
import Signup from "./Auth/Register";
import ForgotPassword from "./Auth/ForgotPassword";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import TourGuide from "./TourGuide";
// coustom page imported here
import FMProviderManagement from "./modules/FMProviderManagement/index";
import SubAdmin from "./modules/SubAdmin/index";
import Profile from "./Apps/Profile/index";
import ServiceActivity from "./modules/ServiceActivity/index";
import IssueManagement from "./modules/IssueManagement/index";
import ContactUsQueries from "./modules/ContactUsQueries/index";
import ServiceType from "./modules/ServicesType/index";
import AboutUs from "./ContentManager/AboutUs/index";
import PrivacyPolicy from "./ContentManager/PrivacyPolicy/index";
import TermsAndConditions from "./ContentManager/TermsAndCondition/index";
import FAQ from "./ContentManager/FAQ/index";
// import LayoutBuilder from './LayoutBuilder';
import Home from "./Dashboards/Home";
import Settings from "./Dashboards/Settings";
import Priorities from "./modules/Priorities/index";
import ServiceRequest from "./modules/ServiceRequest/index";
import BulkImportCategory from "./modules/BulkImportCategory/index";
import BulkImport from "./modules/BulkImport/index";
import { project_name } from "../config";
import { Helmet } from "react-helmet";

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);

  return (
    <Route
      {...rest}
      render={(props) =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/signin",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  const { authUser } = useSelector(({ auth }) => auth);

  const location = useLocation();

  if (location.pathname === "" || location.pathname === "/") {
    return <Redirect to={"/dashboard"} />;
  } else if (authUser && location.pathname === "/signin") {
    return <Redirect to={"/dashboard"} />;
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{project_name}-Admin Dashboard</title>
        <meta name="title" content={project_name} />
        <meta name="description" content={project_name} />
        <meta name="og:title" content={project_name} />
        <meta name="og:description" content={project_name} />
      </Helmet>
      <Switch>
        <RestrictedRoute path="/dashboard" component={Home} />

        <Route path="/signin" component={Login} />
        <Route path="/fm-admin-panel" component={Signup} />
        <Route path="/forgot-password" component={ForgotPassword} />
        {/* coustome routes */}
        <RestrictedRoute
          path="/fmprovidermanagement"
          component={FMProviderManagement}
        />
        <RestrictedRoute path="/subadminmanagement" component={SubAdmin} />
        <RestrictedRoute path="/profile" component={Profile} />
        <RestrictedRoute
          path="/serviceactivities"
          component={ServiceActivity}
        />
        <RestrictedRoute path="/issuemanagement" component={IssueManagement} />
        <RestrictedRoute path="/servicetypes" component={ServiceType} />
        <RestrictedRoute
          path="/contactusqueries"
          component={ContactUsQueries}
        />
        {/* ServiceRequest */}
        {/* <RestrictedRoute path="/ServiceRequest" component={ServiceRequest} /> */}
        <RestrictedRoute path="/priorities" component={Priorities} />
        <RestrictedRoute path="/aboutus" component={AboutUs} />
        <RestrictedRoute path="/privacypolicy" component={PrivacyPolicy} />
        <RestrictedRoute
          path="/termsandconditions"
          component={TermsAndConditions}
        />
        <RestrictedRoute path="/faq" component={FAQ} />
        <RestrictedRoute
          path="/bulkimportcategory"
          component={BulkImportCategory}
        />
        <RestrictedRoute path="/bulkimport" component={BulkImport} />
        <RestrictedRoute path="/settings" component={Settings} />

        {/*<Route path="/layout-builder" component={LayoutBuilder} />*/}
      </Switch>

      {location.pathname !== "/signin" &&
        location.pathname !== "/signup" &&
        location.pathname !== "/forgot-password" && <TourGuide />}
    </React.Fragment>
  );
};

export default Routes;
