import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';
import { GET_SAMPLEFILE } from '../../@jumbo/constants/ActionTypes';
import { samplefile } from '../../@jumbo/constants/ApiConstatnt';

// get sample file

export const getSampleFile = (userId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(samplefile + userId, { params: { type: userId } })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_SAMPLEFILE, payload: data.data.data });
          // dispatch(fetchSuccess(data?.data?.message));
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Item List not found.') {
          dispatch(fetchError(error?.response?.data?.message));
          dispatch({ type: GET_SAMPLEFILE, payload: [] });
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};
