import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import GridContainer from '../../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles({
  table: {
    minWidth: 370,
    "& .MuiTableCell-head": {
      fontWeight:"600",
  },
  },
  tableRow: {
    fontWeight:"300",
  },
  titleRoot1:{
    fontWeight:"600",
    whiteSpace: 'nowrap',
    '&.MuiTypography-body1':{
      fontSize: 14,
    }
    
  },
});

export default function UserTable() {
  const classes = useStyles();
  return (
    <>
      <GridContainer>
        <Grid item xs={6} sm={6} className={classes.titleRoot1} >
          Items Used in Service :
          <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Item Used</TableCell>
                  <TableCell>Quantity</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow className={classes.tableRow}>
                  <TableCell component="th" scope="row"></TableCell>
                  <TableCell>{}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={6} sm={6} className={classes.titleRoot1}>
          Service Activities :
          <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Services Performed</TableCell>
                  <TableCell>price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow className={classes.tableRow}>
                  <TableCell component="th" scope="row"></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </GridContainer>
    </>
  );
}
