import {
  GET_ASSETTYPELIST,
  SET_ASSETTYPE_UPDATE,
  GET_ASSETTYPEDETAILS,
  SET_ASSETTYPE_ERROR
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  users: [],
  currentUser: null,
  details:null,
  ManufacturerList:null,
  wareHouse:null,
  error:null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ASSETTYPELIST: {
      return {
        ...state,
        users: action.payload,
      };
    }

    
    case SET_ASSETTYPE_ERROR: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case GET_ASSETTYPEDETAILS: {
      return {
        ...state,
        details: action.payload,
      };
    }

    case SET_ASSETTYPE_UPDATE: {
      return {
        ...state,
        currentUser: action.payload,
      };
    }
    
    default:
      return state;
  }
};
