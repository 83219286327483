import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import moment from 'moment';
import GetAppIcon from '@material-ui/icons/GetApp';
import { setServiceRequestQuotation } from '../../../../../redux/actions/ServiceRequest';
import { useSelector, useDispatch } from 'react-redux';
import NoRecordFound from './NoRecordFound';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
    '& .MuiDialog-paperWidthSm': {
      width: '100%',
      maxWidth: '800px',
    },
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 200,
  },

  table: {
    minWidth: 600,
    '& .MuiTableCell-head': {
      fontWeight: '600',
    },
  },

  tableRow: {
    fontWeight: '300',
  },
  border: {
    color: theme.palette.text.primary,

    '&:focus': {
      border: `2px solid ${theme.palette.text.primary}`,
    },
  },
  titleRoot: {
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.text.primary,
    textOverflow: 'ellipsis',
    maxWidth: '250px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

export default function RequestQuation({ handelOpenAssign }) {
  const [open, setOpen] = React.useState(false);
  const [checkData, setCheckData] = useState('');
  const [checkError, setCheckError] = useState('');
  const { quotationList } = useSelector(({ SERVICEREQUEST }) => SERVICEREQUEST);
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClickOpenQuatation = () => {
    setOpen(true);
  };

  const handelOnSubmit = () => {
    let checData1 = { ...checkData, requestId: quotationList?._id };

    if (checData1?.requestId) {
      handelOpenAssign();
      dispatch(setServiceRequestQuotation(checData1));
      setOpen(false);
    } 
    
    else if(quotationList?.quotations?.length) {
      setCheckError('Please select atleast one checkbox .');
    }
    

  };

  const handleFileDownload = doc => {
    if (doc.questionnaire_vendor_doc) window.open(doc.questionnaire_vendor_doc, '_self');
  };

  const onFilterOptionClick = data => {
    setCheckData(data, quotationList?._id);
    setCheckError('');
  };
  return (
    <Box>
      <Button variant="outlined" color="primary" onClick={handleClickOpenQuatation}>
        Quotations
      </Button>
      <Dialog
        className={classes.dialogRoot}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" alignItems="center">
          {'Quatations'}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Box flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>#</TableCell>
                    <TableCell>Quotation By</TableCell>
                    <TableCell>Sent On</TableCell>
                    <TableCell>Quoted Amount</TableCell>
                    <TableCell>Quotationnaire</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {quotationList?.quotations ? (
                    quotationList?.quotations?.map((row, index) => (
                      <TableRow className={classes.tableRow}>
                        <TableCell>
                          {' '}
                          <Checkbox onChange={e => onFilterOptionClick(row)} />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {index + 1}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row?.vendor_id?.first_name}
                          {''}
                          {row?.vendor_id?.last_name}
                        </TableCell>
                        <TableCell>
                          <Typography className={classes.titleRoot}>
                            {moment(row?.created_at * 1000).format('DD/MM/YYYY')}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography className={classes.titleRoot}>{row?.charges}</Typography>
                        </TableCell>
                        <TableCell style={{ cursor: 'pointer' }} onClick={() => handleFileDownload(row)}>
                          <Typography></Typography>
                          <GetAppIcon />{' '}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5} rowSpan={5}>
                        <NoRecordFound>{'There are no records found.'}</NoRecordFound>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <p>{checkError}</p>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setOpen(false)} variant="outlined">
            Cancel
          </Button>
          <Button onClick={handelOnSubmit} color="primary" autoFocus variant="contained">
            select
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
