import {
  GET_TENANTBLUKIMPORT,
  ADD_TENANTBULKIMPORT,
  SET_TENANTBULK_IMPORT_DETAILS,
  GET_TENANT_SAMPLE_FILES_DETAILS,
  GET_EXPORT_DATA,
  GET_TENANTDETAILS_TABLE,
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  TenantBulkUser: [],
  CurrentTenantBulk: null,
  details_table:null,
  filesDetails: null,
  errorData: null,
  exportData: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TENANTBLUKIMPORT: {
      return {
        ...state,
        TenantBulkUser: action.payload,
      };
    }
    case ADD_TENANTBULKIMPORT: {
      return {
        ...state,
        errorData: action.payload,
      };
    }
    case SET_TENANTBULK_IMPORT_DETAILS: {
      return {
        ...state,
        CurrentTenantBulk: action.payload,
      };
    }
    case GET_TENANTDETAILS_TABLE: {
      return {
        ...state,
        details_table: action.payload,
      };
    }
    case GET_TENANT_SAMPLE_FILES_DETAILS: {
      return {
        ...state,
        filesDetails: action.payload,
      };
    }
    case GET_EXPORT_DATA: {
      return {
        ...state,
        exportData: action.payload,
      };
    }
    //   case ADD_SERVICETYPE: {
    //     return {
    //       ...state,
    //       users: [action.payload, ...state.users],
    //     };
    //   }
    //   case EDIT_SERVICETYPE: {
    //     return {
    //       ...state,
    //       users: state.users.map(user => (user.id === action.payload.id ? action.payload : user)),
    //     };
    //   }
    //   case DELETE_SERVICETYPE: {
    //     return {
    //       ...state,
    //       users: state.users.filter(user => user.id !== action.payload),
    //     };
    //   }
    //   case DELETE_BULK_SERVICETYPE: {
    //     return {
    //       ...state,
    //       users: state.users.filter(user => !action.payload.includes(user.id)),
    //     };
    //   }
    default:
      return state;
  }
};
