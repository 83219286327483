import { GET_VIRSION } from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  VirsionList: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_VIRSION: {
      return { ...state, VirsionList: action.payload };
    }
    
    default:
      return state;
  }
};
