import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';
import {
  DELETE_BULK_SERVICEREQUEST,
  GET_SERVICEREQUEST,
  SET_SERVICEREQUEST_DETAILS,
  GET_SERVICEREQUESTDETAILS,
  GET_SERVICEREQUESTADD,
  GET_SERVICEREQUESTQUOTATIONLIST,
  GET_SERVICEREQUESTQUOTATIOCHECKNLIST,
} from '../../@jumbo/constants/ActionTypes';
import {
  servicerequestrequsetquotation,
  listservicerequest,
  servicerequeststatusupdate,
  servicerequestdetails,
  servicerequestrequsetapprovework,
  servicerequestadd,
  servicerequestrequsetquotationlist,
  deleteservicerequest,
} from '../../@jumbo/constants/ApiConstatnt';
import { history } from 'redux/store';

// get SERVICE REQUEST list
export const getServiceRequest = (sortBy, sort, searchTerm, page, limit, filterOptionsStatus, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());

    axios
      .get(listservicerequest, {
        params: {
          sortBy: `${sortBy}:${sort}`,
          search: searchTerm,
          page: page + 1,
          limit: limit,
          // service_status: JSON.stringify(filterOptionsStatus),
          service_status: filterOptionsStatus?.join()
        },
      })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_SERVICEREQUEST, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else if (data.status === 204) {
          dispatch(fetchError());
          dispatch({ type: GET_SERVICEREQUEST, payload: [] });
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Service request content data not found.') {
          dispatch(fetchError());
          dispatch({ type: GET_SERVICEREQUEST, payload: [] });
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

// set current user for view tab
export const setCurrentServiceRequset = user => {
  return dispatch => {
    dispatch({ type: SET_SERVICEREQUEST_DETAILS, payload: user });
  };
};

export const setEdittServiceRequset = user => {
  return dispatch => {
    dispatch({ type: GET_SERVICEREQUESTADD, payload: user });
  };
};

export const handelServiceRequsetApproveWork = (serviceRequestId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(servicerequestrequsetapprovework + serviceRequestId)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Approved work successfully.'));
          }, 2000);

          dispatch(getServiceRequsetDetails(serviceRequestId));
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

// add Assign item
export const addNewServiceRequestData = (serviceRequestId, vendorId, contractorId, user, callbackFun, chnageVendor) => {
  console.log('chnage vendor', chnageVendor);
  return dispatch => {
    dispatch(fetchStart());

    axios
      .post(
        contractorId
          ? servicerequestadd + serviceRequestId + '/' + vendorId + '/' + contractorId
          : servicerequestadd + serviceRequestId + '/' + vendorId,
        user,
      )
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess(chnageVendor ? 'Assigned vendor change successfully.' : 'Vendor assigned successfully.'));
          }, 3000);
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
       
        if(error?.response?.status===303){
          dispatch(fetchError('Please add atleast one indcution in job location to assign vendor to service request.'));
          localStorage.setItem('l_d_i',5)
          setTimeout(() => {
            history.push(`/location-detail/${error?.response?.data?.data?.site_id}`);
          }, 2000);
          }
          else{
        dispatch(fetchError(error?.response?.data?.message));
          }
      });
  };
};

export const addRequsetQuotation = (serviceRequestId, user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(servicerequestrequsetquotation + serviceRequestId, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Service request quotation request sent successfully.'));
          }, 2000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

export const updateServiceRequestStatus = (data, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(servicerequeststatusupdate, data)
      .then(response => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Service request status updated successfully.'));
          }, 3000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

export const getServiceRequsetDetails = userId => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(servicerequestdetails + userId)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_SERVICEREQUESTDETAILS, payload: data.data.data });
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Item List not found.') {
          dispatch(fetchError(error?.response?.data?.message));
          dispatch({ type: GET_SERVICEREQUESTDETAILS, payload: [] });
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

export const getServiceRequsetQuotation = userId => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(servicerequestrequsetquotationlist + userId)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_SERVICEREQUESTQUOTATIONLIST, payload: data.data.data });
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Item List not found.') {
          dispatch(fetchError(error?.response?.data?.message));
          dispatch({ type: GET_SERVICEREQUESTQUOTATIONLIST, payload: [] });
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

export const setServiceRequestQuotation = user => {
  return dispatch => {
    dispatch({ type: GET_SERVICEREQUESTQUOTATIOCHECKNLIST, payload: user });
  };
};

export const setItemListDetails = user => {
  return dispatch => {
    dispatch({ type: SET_SERVICEREQUEST_DETAILS, payload: user });
  };
};

// all function below are not in use currntly
export const deleteBulkUsers = (userIds, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/users/bulk-delete', { userIds })
      .then(response => {
        if (response.status === 200) {
          dispatch(fetchSuccess('Selected users were deleted successfully.'));
          dispatch({ type: DELETE_BULK_SERVICEREQUEST, payload: userIds });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const deleteUser = (userId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete(deleteservicerequest + userId)
      .then(data => {
        if (data.status === 200) {
          setTimeout(() => {
            dispatch(fetchSuccess('Service request deleted successfully.'));
          }, 3000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const sentMailToUser = () => {
  return dispatch => {
    dispatch(fetchSuccess('Email has been sent to user successfully'));
  };
};

export const updateItemList = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('', user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Assign item updated successfully.'));
          }, 3000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};
