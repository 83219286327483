import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '../../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '../../../../../@jumbo/components/Common/formElements/AppTextInput';
import Button from '@material-ui/core/Button';
import { requiredMessage, lettersOnlyMessage } from '../../../../../@jumbo/constants/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import {
  addNewSystemCategorie,
  getSystemCategorieList,
  updateSystemCategorieList,
  addManuallySystemCategorie,
} from '../../../../../redux/actions/SystemCategorie';
import { emptySpace, numberOnly, numberOnly2, stringOnly, onlyStringWithSpace } from '../../../../../@jumbo/constants/ValidationRegex';
import { Typography } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import InputAdornment from '@material-ui/core/InputAdornment';
import NoRecordFound from '../NoRecordFound';
import { capitalizeFLetter } from '@jumbo/utils/commonHelper';
import AppSelectBox from '../../../../../@jumbo/components/Common/formElements/AppSelectBox';
import InputLabel from '@material-ui/core/InputLabel';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Divider from '@material-ui/core/Divider';
import ErrorTable from '../ErrorTable';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ErrorIcon from '@material-ui/icons/Error';
import DialogActions from '@material-ui/core/DialogActions';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },

  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.text.primary,
    },
  },
  serviceTypeList: {
    fontSize: 14,
    letterSpacing: 0.25,
    textOverflow: 'ellipsis',
    wordWrap: 'break-word',
  },
  typeList: {
    width: 535,
    fontSize: 14,
    height: 300,
    backgroundColor: theme.palette.background.default,
    overflow: 'auto',
  },
  imageButton: {
    fontSize: '10px',
    marginLeft: '5px',
    color: theme.palette.text.primary,
    textOverflow: 'ellipsis',
    maxWidth: '200px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  imageRoot: {
    marginTop: '5%',
  },
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
    '& .MuiFormLabel-root': {
      color: '#909090',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      color: theme.palette.text.primary,
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
  errText: {
    color: 'red',
    marginLeft: '4px',
    marginRight: '14px',
    fontSize: '0.75rem',
    marginTop: '3px',
    textAlign: 'left',
    fontFamily: 'Normal',
    fontWeight: 400,
    lineHeight: 1.66,
  },
  titleRoot2: {
    fontWeight: '300',
    marginLeft: '5px',
    fontSize: 14,
    textOverflow: 'ellipsis',
    maxWidth: '350px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    // marginLeft:"-10px"
  },
  titleRoot1: {
    fontWeight: '600',
    whiteSpace: 'nowrap',
    '&.MuiTypography-body1': {
      fontSize: 14,
    },
  },
  paper: {
    position: 'relative',
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '1000px',
      // width: '100%',
      minWidth: '500px',
    },
  },
}));

const AddEditUser = ({ open, onCloseDialog, getSystemCtegorieParams, setOpenWareHouseDialog ,setErrroDialog,addButton,setAddButton}) => {
  const classes = useStyles();
  const { currentUser, error } = useSelector(({ SYSTEMCATEGORIE }) => SYSTEMCATEGORIE);
  const { orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions, syatemCategory } = getSystemCtegorieParams;
  const [csvFile, setCsvFile] = useState('');
  const [systemCategorie, setSystemCategorie] = useState('');
  const [csvError, setCsvError] = useState('');
  const [systemCategorieError, setSystemCategorieError] = useState('');
  const [value, setValue] = React.useState('1');
  const [valueCheck, setValueCheck] = React.useState(true);
  const dispatch = useDispatch();
  // const [errroDialog, setErrroDialog] = React.useState(false);

  useEffect(() => {
    if (currentUser) {
      setSystemCategorie(currentUser?.name);
    }
  }, [currentUser]);

  const onSubmitClick = add => {
    if (!systemCategorie.match(onlyStringWithSpace)) {
      setSystemCategorieError(lettersOnlyMessage);
    }
    if (!systemCategorie || systemCategorie.match(emptySpace)) {
      setSystemCategorieError(requiredMessage);
    }
    if (csvFile.type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && csvFile.type != 'text/csv') {
      setCsvError('Please upload excel or csv file');
    }

    if (!csvFile) {
      setCsvError(requiredMessage);
    }

    if (
      (csvFile.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && csvFile) ||
      (csvFile && csvFile.type == 'text/csv')
    ) {
      setAddButton(true)
      onUserSave();
      onCloseDialog();
    }
    if (systemCategorie && !systemCategorie.match(emptySpace) && systemCategorie.match(onlyStringWithSpace)) {
      onUserSave();
    }
  };

  const onUserSave = () => {
    if (currentUser) {
      const formData1 = {
        category_name: systemCategorie,
      };
      // formData.append('category_name', systemCategorie);
      dispatch(
        updateSystemCategorieList(currentUser?._id, formData1, () => {
          onCloseDialog();
          dispatch(
            getSystemCategorieList(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions, syatemCategory),
          );
        }),
      );
      // setValueCheck(false)
    }
    if (systemCategorie && valueCheck && !currentUser) {
      const manuallyAdd = {
        name: systemCategorie,
      };
      dispatch(
        addManuallySystemCategorie(manuallyAdd, () => {
          onCloseDialog();
          setValueCheck(false);
          dispatch(
            getSystemCategorieList(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions, syatemCategory),
          );
        }),
      );
    } else if (csvFile) {
      // alert("bulk")
      const formData = new FormData();
      formData.append('categoryImportFile', csvFile);
      formData.append('type', 1);

      dispatch(
        addNewSystemCategorie(
          formData,
          {
            setErrroDialog: () => setErrroDialog(true),
            onCloseDialog: () => onCloseDialog(),
            setAddButton: () =>setAddButton(false)
          },
          () => {
            // onCloseDialog();
            dispatch(
              getSystemCategorieList(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions, syatemCategory),
            );
          },
        ),
      );

      // if (!AddToWarehouse) {
      //   dispatch(
      //     addNewItemsList(formData, false, () => {
      //       onCloseDialog();
      //       dispatch(getSystemCategorieList(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
      //     }),
      //   );
      // } else {
      //   dispatch(
      //     addNewItemsList(formData, true, () => {
      //       onCloseDialog();
      //       setOpenWareHouseDialog(true);
      //       dispatch(getSystemCategorieList(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
      //     }),
      //   );
      // }
    }
  };
  const handleChange = event => {
    setValue(event.target.value);
    setSystemCategorieError('');
    setCsvError('');
    setValueCheck(false);
  };
  const handelClose = () => {
    setErrroDialog(false);
    onCloseDialog();
  };

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>
        {currentUser ? 'Edit System Category' : 'Import System Category'}
      </DialogTitle>
      <Box>
        {/* <Dialog
          className={classes.paper}
          open={errroDialog}
          onClose={handelClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-description">Error Details</DialogTitle>
          <Divider />
          <DialogContent>
            <Box className={classes.userInfoRoot}>
              <Box px={6} py={5}>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }} ml={{ xs: -6 }}>
                  <ImportExportIcon />
                  <Box ml={3} display="flex">
                    <Typography className={classes.titleRoot1}>Total Rows Imported : </Typography>
                    <Typography className={classes.titleRoot2}>{error?.data?.total_rows_imported}</Typography>
                  </Box>
                </Box>

                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }} ml={{ xs: -6 }}>
                  <KeyboardArrowRightIcon />
                  <Box ml={3} display="flex">
                    <Typography className={classes.titleRoot1}>Total Rows Processed : </Typography>
                    <Typography className={classes.titleRoot2}>{error?.data?.total_rows_processed}</Typography>
                  </Box>
                </Box>

                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 5 }} ml={{ xs: -6 }}>
                  <ErrorIcon />
                  <Box ml={3} display="flex">
                    <Typography className={classes.titleRoot1}>Total Rows With Errors:</Typography>
                    <Typography className={classes.titleRoot2}>{error?.data?.total_rows_with_errors}</Typography>
                  </Box>
                </Box>
              </Box>
              {error?.data?.error.length > 0 ? (
                <ErrorTable />
              ) : (
                <Box display="flex" alignItems="center" mb={{ xs: 2, sm: 1 }} mt={{ xs: 2 }}>
                  <Typography className={classes.titleRoot2}>{error?.message}</Typography>
                </Box>
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handelClose} autoFocus variant="outlined">
              Cancel
            </Button>
          </DialogActions>
        </Dialog> */}
      </Box>
      <DialogContent dividers>
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
          <GridContainer>
            {currentUser ? (
              <Grid item xs={12} sm={12}>
                <AppTextInput
                  className={classes.root}
                  fullWidth
                  variant="outlined"
                  label="System Category Name"
                  value={systemCategorie}
                  onChange={e => {
                    setSystemCategorie(e.target.value);
                    setSystemCategorieError('');
                    setValueCheck(false);
                  }}
                  helperText={systemCategorieError}
                />
              </Grid>
            ) : (
              <>
                <Grid item xs={12} sm={12}>
                  <FormControl component="fieldset">
                    <RadioGroup aria-label="query" name="query1" value={value} onChange={handleChange}>
                      <FormControlLabel value="1" control={<Radio />} label="Bulk Import" />
                      <FormControlLabel value="2" control={<Radio />} label="Add System Category" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {value == 2 ? (
                  <Grid item xs={12} sm={12}>
                    <AppTextInput
                      className={classes.root}
                      fullWidth
                      variant="outlined"
                      label="System Category Name"
                      value={systemCategorie}
                      // onKeyPress={event => {
                      //   if (['-', '+', '.', ' '].includes(event.key)) {
                      //     event.preventDefault();
                      //   }
                      // }}
                      onChange={e => {
                        setSystemCategorie(e.target.value);
                        setSystemCategorieError('');
                      }}
                      helperText={systemCategorieError}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={12}>
                    <Button variant="contained" component="label">
                      <CloudUploadIcon />
                      <p className={classes.imageButton}>{csvFile?.size ? csvFile?.name : 'Choose Excel File'}</p>
                      <input
                        type="file"
                        hidden
                        onChange={event => {
                          setCsvFile(event.target.files[0]);
                          setCsvError('');
                        }}
                        // accept=".csv,text/csv"
                        accept=".xlsx"
                      />
                    </Button>{' '}
                    <br />
                    {currentUser ? <img src={csvFile} width="200px" className={classes.imageRoot} /> : ''}
                    <p className={classes.errText} style={{ color: 'red' }}>
                      {csvError}
                    </p>
                  </Grid>
                )}
              </>
            )}
          </GridContainer>
        </Box>
        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button onClick={onCloseDialog} variant="outlined">
            Cancel
          </Button>

          <Box ml={2}>
            <Button variant="contained" color="primary" style={{ minWidth: '100px' }} onClick={() => onSubmitClick(true)} disabled={addButton&&!currentUser?true:false}>
              {currentUser ? 'Update' : value == '1' ? 'Import' : 'Add'}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditUser;

AddEditUser.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
