import {
  GET_CLASSCATEGORYLIST,
  SET_CLASSCATEGORY_DETAILS,
  GET_CLASSCATEGORYDETAILS,
  SET_CLASSCATEGORY_ERROR
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  users: [],
  currentUser: null,
  details:null,
  ManufacturerList:null,
  wareHouse:null,
  error:null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CLASSCATEGORYLIST: {
      return {
        ...state,
        users: action.payload,
      };
    }

    case GET_CLASSCATEGORYDETAILS: {
      return {
        ...state,
        details: action.payload,
      };
    }
    
    case SET_CLASSCATEGORY_ERROR: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case SET_CLASSCATEGORY_DETAILS: {
      return {
        ...state,
        currentUser: action.payload,
      };
    }
    
    default:
      return state;
  }
};
