import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import GridContainer from '../../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import { KeyboardDatePicker } from '@material-ui/pickers';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import moment from 'moment';
import { addRequsetQuotation, getServiceRequsetDetails } from '../../../../../redux/actions/ServiceRequest';
import { useSelector, useDispatch } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import { requiredMessage } from '../../../../../@jumbo/constants/ErrorMessages';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
    '& .MuiDialog-paperWidthSm': {
      width: '100%',
      maxWidth: '350px',
    },
  },
  helperText: {
    marginLeft: '5px',
    '& .MuiFormHelperText-root': {
      color: 'red',
      marginLeft: '10px',
    },
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 200,
  },
  imageButton: {
    fontSize: '10px',
    marginLeft: '5px',
    color: theme.palette.text.primary,
    textOverflow: 'ellipsis',
    maxWidth: '200px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

export default function RequestQuation() {
  const [open, setOpen] = React.useState(false);
  const [dueDate, setDueDate] = useState(new Date());
  const [quotationDate, setquotationDate] = useState(new Date());
  const [document, setDocument] = useState('');
  const [dueDateError, setDueDateError] = useState('');
  const [quotationDateError, setQuotationDateError] = useState('');
  const [documentError, setDocumentError] = useState('');
  const { details } = useSelector(({ SERVICEREQUEST }) => SERVICEREQUEST);
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleClickOpenBtn = () => {
    setOpen(true);
  };

  const handleSubmitRequestQuation = () => {
    if (!dueDate) {
      setDueDateError(requiredMessage);
    }
    
    if (moment(dueDate).format('x') < moment().format('x')) {
      setDueDateError("Please select valid date, you can't select today's date or past date.");
    }
    
    if (moment(quotationDate).format('x') < moment().format('x')) {
      setQuotationDateError("Please select valid date, you can't select today's date or past date.");
    }
    
    if (
      moment(quotationDate).format('x') > moment(dueDate).format('x') ||
      moment(quotationDate).format('x') === moment(dueDate).format('x')
    ) {
      setQuotationDateError('Please select quotation needed by date less than due date.');
    }
    if (!quotationDate) {
      setQuotationDateError(requiredMessage);
    }
    if (
      moment(quotationDate).format('x') >= moment().format('x') &&
      moment(dueDate).format('x') >= moment().format('x') &&
      moment(quotationDate).format('x') < moment(dueDate).format('x')
    ) {
      onUserSave();
    }
  };

  const onUserSave = () => {
    const formData = new FormData();
    formData.append('due_date', moment(dueDate, 'DD/MM/YYYY').format('X'));
    formData.append('quotation_needed_by', moment(quotationDate, 'DD/MM/YYYY').format('X'));
    formData.append('questionnaire_doc', document);

    dispatch(
      addRequsetQuotation(details?._id, formData, () => {
        setOpen(false);
        setDocument('');
      }),
    );
    dispatch(getServiceRequsetDetails(details?._id));
  };
  const handleDateChange = date => {
    setDueDate(date);
    setDueDateError('');
  };

  const handleDateChange1 = date => {
    setquotationDate(date);
    setQuotationDateError('');
  };
  const closeDialogBtn = () => {
    setOpen(false);
    setquotationDate(new Date());
    setDueDate(new Date());
    setDocument('');
    setQuotationDateError('');
    setDueDateError('');
  };

  const handleDialogclose = () => {
    setOpen(false);
    setquotationDate(new Date());
    setDueDate(new Date());
    setDocument('');
    setQuotationDateError('');
    setDueDateError('');
  };
  return (
    <Box>
      <Button variant="outlined" color="primary" onClick={handleClickOpenBtn}>
        Request Quotation
      </Button>
      <Dialog
        className={classes.dialogRoot}
        open={open}
        onClose={handleDialogclose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Request Quotation'}</DialogTitle>
        <Divider />
        <DialogContent>
          <Box flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={10} sm={10}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="Due Date"
                  format="DD/MM/YYYY"
                  value={dueDate}
                  minDate={new Date()}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  className={classes.helperText}
                  helperText={dueDateError}
                />
              </Grid>
              <Grid item xs={10} sm={10}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="Quotation Needed By"
                  format="DD/MM/YYYY"
                  value={quotationDate}
                  minDate={new Date()}
                  onChange={handleDateChange1}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  className={classes.helperText}
                  helperText={quotationDateError}
                />
              </Grid>
              <Grid item xs={8} sm={8}>
                <Button variant="contained" component="label" mt={5}>
                  <CloudUploadIcon />
                  <p className={classes.imageButton}>{document ? document?.name : 'Questionnaire'}</p>
                  <input
                    type="file"
                    name="file"
                    hidden
                    accept=".pdf,.doc, .docx, .odt, .xls, .xlsx, .ods, .ppt, .pptx, .txt"
                    onChange={e => {
                      setDocument(e.target.files[0]);
                      setDocumentError('');
                    }}
                  />
                </Button>
                <p className="MuiFormHelperText-root MuiFormHelperText-contained MuiFormHelperText-marginDense">
                  {documentError}
                </p>
              </Grid>
            </GridContainer>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={closeDialogBtn} variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleSubmitRequestQuation} color="primary" autoFocus variant="contained">
            Assign
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
