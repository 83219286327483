import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TypeCategory from './TypeCategory/index'
import SystemCategories from './SystemCategories/index'
import ClassCategories from './ClassCategories/index'
import SubTypeCategory from './SubTypeCategory/index'
import AssetType from './AssetType/index'
import Manufacuture from './Manufacuture/index'
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import { useSelector } from 'react-redux';
const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    marginBottom: '10px',
    
  },
});
const breadcrumbs = [
  { label: 'Home', link: '/' },
  { label: 'Bulk Import Category', isActive: true },
];
export default function CenteredTabs() {
  const classes = useStyles();
  const { authUser } = useSelector(({ auth }) => auth);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <PageContainer heading="Bulk Import Category"  breadcrumbs={breadcrumbs}>
      <Paper className={classes.root}>
        <Tabs value={value}  indicatorColor="primary" textColor="primary">
          <Tab label="System Category"  onClick={e=>setValue(0)}/>
          <Tab label="Class Category"  onClick={e=>setValue(1)}/>
          <Tab label="Type Category" onClick={e=>setValue(2)} />
          <Tab label="Subtype Category" onClick={e=>setValue(3)} />
          <Tab label="Manufacturer" onClick={e=>setValue(4)} />
          <Tab label="Asset Type"  onClick={e=>setValue(5)}/>
        </Tabs>
      </Paper>
      {value === 0 ? <SystemCategories /> : null}
     {value === 1 ? <ClassCategories /> : null}
      {value === 2 ? <TypeCategory /> : null}
      {value === 3 ? <SubTypeCategory /> : null}
      {value === 4 ? <Manufacuture /> : null}
      {value === 5 ? <AssetType /> : null}
    </PageContainer>
  );
}
