import {
  ADD_ITEMSLIST,
  DELETE_BULK_ITEMSLIST,
  DELETE_ITEMSLIST,
  EDIT_ITEMSLIST,
  GET_ITEMSLIST,
  SET_ITEMSLIST_DETAILS,
  GET_ITEMSLISTDETAILS,
  GET_MANUFACTURERLIST,GET_WAREHOUSELIST,
  GET_SYSTEMCATEGORYERROR
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  users: [],
  currentUser: null,
  details:null,
  ManufacturerList:null,
  wareHouse:null,
  error:null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ITEMSLIST: {
      return {
        ...state,
        users: action.payload,
      };
    }

    case GET_ITEMSLISTDETAILS: {
      return {
        ...state,
        details: action.payload,
      };
    }
    

    case GET_SYSTEMCATEGORYERROR: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case GET_MANUFACTURERLIST:{
      return {
        ...state,
        ManufacturerList: action.payload,
      };
    }

    case GET_WAREHOUSELIST:{
      return {
        ...state,
        wareHouse: action.payload,
      };
    }

    case SET_ITEMSLIST_DETAILS: {
      return {
        ...state,
        currentUser: action.payload,
      };
    }
    case ADD_ITEMSLIST: {
      return {
        ...state,
        users: [action.payload, ...state.users],
      };
    }
    case EDIT_ITEMSLIST: {
      return {
        ...state,
        users: state.users.map(user => (user.id === action.payload.id ? action.payload : user)),
      };
    }
    case DELETE_ITEMSLIST: {
      return {
        ...state,
        users: state.users.filter(user => user.id !== action.payload),
      };
    }
    case DELETE_BULK_ITEMSLIST: {
      return {
        ...state,
        users: state.users.filter(user => !action.payload.includes(user.id)),
      };
    }
    default:
      return state;
  }
};
