import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';
import {
  GET_SUBTYPECATEGORYLIST,
  SET_SUBTYPECATEGORY_UPDATEDETAILS,
  GET_SUBTYPECATEGORYDETAILS,
  SET_SUBTYPECATEGORY_ERROR,
} from '../../@jumbo/constants/ActionTypes';
import {
  addSubTypeCategory,
  listSubTypeCategory,
  subtypeCategoryStatusUpdate,
  subtypeCategoryupdate,
  subtypeCategoriedetails,
  deleteSubTypeCategorie,
  addSubTypeCategoryManually,
} from '../../@jumbo/constants/ApiConstatnt';

// get Item list
export const getSubTypeCategoryList = (sortBy, sort, searchTerm, page, limit, filterOptions, category, id, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(listSubTypeCategory, {
        params: {
          sortBy: `${sortBy}:${sort}`,
          search: searchTerm,
          page: page + 1,
          limit: limit,
          status: filterOptions[0],
          type: category,
          _id: id,
        },
      })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_SUBTYPECATEGORYLIST, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError());
          dispatch({ type: GET_SUBTYPECATEGORYLIST, payload: [] });
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Item list not found.') {
          dispatch({ type: GET_SUBTYPECATEGORYLIST, payload: [] });
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

// set current user for view tab
export const setSubTypeCategoryList = user => {
  return dispatch => {
    dispatch({ type: SET_SUBTYPECATEGORY_UPDATEDETAILS, payload: user });
  };
};

// add new FM provider
export const addNewSubTypeCategory = (user, callbackFun, listCall) => {
  return dispatch => {
    // dispatch(fetchStart());
    dispatch(fetchSuccess('Subtype bulk import process has been start.'));
    axios
      .post(addSubTypeCategory, user)
      .then(data => {
        if (data) {
          if (data?.data?.data?.red_snakbar) {
            setTimeout(() => {
              dispatch(fetchError(data?.data?.message));
              // dispatch(fetchSuccess('New SubType Category added successfully.'));
            }, 2000);
          } else {
            setTimeout(() => {
              dispatch(fetchSuccess(data?.data?.message));
              // dispatch(fetchSuccess('New SubType Category added successfully.'));
            }, 2000);
          }

          if (callbackFun) {
            callbackFun.setErrroDialog();
            // callbackFun.onCloseDialog();
          }
          if (listCall) listCall(data.data);
          // }

          dispatch({ type: SET_SUBTYPECATEGORY_ERROR, payload: data?.data });
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
        callbackFun.onCloseDialog();
        callbackFun.setAddButton()
      });
  };
};

// add manually subtype category
export const addManuallySubTypeCategory = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(addSubTypeCategoryManually, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('New subtype category added successfully.'));
          }, 2000);
          // if(AddToWarehouse){
          //   dispatch({ type: GET_WAREHOUSELIST, payload: data.data.data });
          // }
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

export const updateSubTypeCategoryStatus = (data, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(subtypeCategoryStatusUpdate, data)
      .then(response => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Subtype category status updated successfully.'));
          }, 3000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

export const getSubTypeCategoryDetails = (userId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(subtypeCategoriedetails + userId)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_SUBTYPECATEGORYDETAILS, payload: data.data.data });

          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Item List not found.') {
          dispatch(fetchError(error?.response?.data?.message));
          dispatch({ type: GET_SUBTYPECATEGORYDETAILS, payload: [] });
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

export const setClassCategoryDetails = user => {
  return dispatch => {
    dispatch({ type: SET_SUBTYPECATEGORY_UPDATEDETAILS, payload: user });
  };
};

export const deleteSubTypeCategory = (userId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete(deleteSubTypeCategorie + userId)
      .then(data => {
        if (data.status === 200) {
          setTimeout(() => {
            dispatch(fetchSuccess('Subtype category deleted successfully.'));
          }, 2000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError());
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

export const sentMailToUser = () => {
  return dispatch => {
    dispatch(fetchSuccess('Email has been sent to user successfully'));
  };
};

export const updateSubTypeCategoryList = (id, user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(subtypeCategoryupdate + id, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Subtype category updated successfully.'));
          }, 3000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};
