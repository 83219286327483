import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import PageContainer from "../../../@jumbo/components/PageComponents/layouts/PageContainer";
import { useSelector } from "react-redux";
import AssetBulkImport from "./AssetBulkImport";
import LocationBulkImport from "./LocationBulkImport";
import ContractorBulkImport from "./ContractorBulkImport";
import VendorBulkImport from "./VendorBulkImport";
import TenantBulkImport from "./TenantBulkImport";
import { Button } from "@material-ui/core";

import TypeCategory from "./TypeCategory/index";
import SystemCategories from "./SystemCategories/index";
import ClassCategories from "./ClassCategories/index";
import SubTypeCategory from "./SubTypeCategory/index";
import AssetType from "./AssetType/index";
import Manufacuture from "./Manufacuture/index";
import Divider from "@material-ui/core/Divider";
import Label from "@material-ui/icons/Label";
import Typography from "@material-ui/core/Typography";
import socketIO from "socket.io-client";
// const socket = io();
const socket = socketIO.connect("http://3.232.191.189:3012");
console.log("yesss", socket);
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: "11px",

    // maxWidth: '1500px',
  },
  root1: {
    flexGrow: 1,
    marginBottom: "4px",
    // backgroundColor: theme.palette.primary.main,
    // maxWidth: '1500px',
  },
  root2: {
    flexGrow: 1,
    marginBottom: "4px",
    // maxWidth: '1500px',
  },
  label: {
    color: "white",
    // color: theme.palette.text.primary,
    padding: "10px",
    fontFamily: "Normal",
    marginTop: "4px",
    marginBottom: "6px",
    marginLeft: "9px",
    fontSize: "0.875rem",
    fontWeight: 400,
    cursor: "pointer",
    // backgroundColor: "#04AA6D"
  },
  color: {
    // backgroundColor: theme.palette.primary.main,
    backgroundColor: "white",
    color: theme.palette.text.primary,
    padding: "10px",
    fontFamily: "Normal",
    marginTop: "4px",
    // marginBottom: '6px',
    marginLeft: "9px",
    cursor: "pointer",
  },
  button: {
    color: "primary",
    "&.Mui-focusVisible": {
      // border:"red",
      // border:"#fc8119"
      focusVisible: "none",
    },
  },
  box: {
    backgroundColor: theme.palette.primary.main,
  },
  tabClass: {
    "& .MuiTab-wrapper": {
      maxWidth: "35px",
      whiteSpace: "breakSpaces",
    },
    "& .MuiTab-root": {
      minWidth: "140px",
    },
  },
}));
const breadcrumbs = [
  { label: "Home", link: "/" },
  { label: "Bulk Import", isActive: true },
];
export default function CenteredTabs() {
  const classes = useStyles();
  const { authUser } = useSelector(({ auth }) => auth);
  const [value, setValue] = React.useState(0);
  const [category, setCategory] = React.useState(true);
  const [valueCategory, setValueCategory] = React.useState(0);
  const [buttonValue, setButtonValue] = React.useState(1);

  console.log("value", value);
  // useEffect(() => {
  //   console.log('socket', socket)
  //   socket.on('connect', () => {
  //     console.log('socket connected')
  //   });
  //   socket.on('message', (data) => {
  //     console.log(data);
  //   });

  //   socket.on('disconnect', () => {
  //     console.log('socket disconected')
  //   });

  //   return () => {
  //     socket.off('connect');
  //     socket.off('disconnect');

  //   };
  // }, [])
  const handleChange = (event, newValue) => {
    setValue(0);
    // setValue(2);
    setButtonValue(1);
    setCategory(true);
    setValueCategory();
  };
  const handleChange1 = (event, newValue) => {
    // setValue(0);
    setValue(2);
    setButtonValue(1);
    // setCategory(true);
    // setValueCategory();
  };
  const handelCategory = () => {
    setValueCategory(0);
    setButtonValue(2);
    setCategory(false);
    // setValue();
    // setValue();
  };

  // return (
  //   <PageContainer heading="Bulk Import" breadcrumbs={breadcrumbs}>
  //     <Paper className={classes.root}>
  {
    /* { category?<> <Tabs value={value} indicatorColor="primary" textColor="primary">
          <Tab label="Asset Bulk Import" onClick={handleChange} />
          <Tab label="Location Bulk Import"   onClick={e => setValue(1)} />
          <Tab label="Contractor Bulk Import" onClick={e => setValue(2)} />
          <Tab label="Vendor Bulk Import" onClick={e => setValue(3)} />
          <Tab label="Tenant Bulk Import" onClick={e => setValue(4)} /></Tabs></>:<> <Tabs value={valueCategory} indicatorColor="primary" textColor="primary"><Tab label="System Category"  />
          <Tab label="Class Category"  onClick={e=>setValueCategory(1)}/>
          <Tab label="Type Category" onClick={e=>setValueCategory(2)} />
          <Tab label="Subtype Category" onClick={e=>setValueCategory(3)} />
          <Tab label="Manufacture" onClick={e=>setValueCategory(4)} />
          <Tab label="Asset Type"  onClick={e=>setValueCategory(5)}/>
 </Tabs>
        </>} */
  }
  {
    /* 
        <Tabs value={value} indicatorColor="primary" textColor="primary">
          <Tab label="Asset Bulk Import" onClick={handleChange} />
          <Tab label="Location Bulk Import" onClick={e => setValue(1)} />
          <Tab label="Contractor Bulk Import" onClick={e => setValue(2)} />
          <Tab label="Vendor Bulk Import" onClick={e => setValue(3)} />
          <Tab label="Tenant Bulk Import" onClick={e => setValue(4)} />
        </Tabs>
      </Paper>

      <Paper className={classes.root1}>
        <Box display="flex">
          {value === 1 || value === 2 || value === 3 || value === 4 ? null : (
            <>
              <span className={buttonValue === 1 || value === 0 ? classes.color : classes.label} onClick={handleChange}>
                IMPORT ASSET
              </span>
              <span className={buttonValue === 2 ? classes.color : classes.label} onClick={handelCategory}>
                IMPORT CATEGORY
              </span>
            </>
          )}
        </Box>
      </Paper>

      <Paper className={classes.root2}>
        {!category && !value ? (
          <>
            {' '}
            <Tabs value={valueCategory} indicatorColor="primary" textColor="primary">
              <Tab label="System Category" onClick={e => setValueCategory(0)} />
              <Tab label="Class Category" onClick={e => setValueCategory(1)} />
              <Tab label="Type Category" onClick={e => setValueCategory(2)} />
              <Tab label="Subtype Category" onClick={e => setValueCategory(3)} />
              <Tab label="Manufacture" onClick={e => setValueCategory(4)} />
              <Tab label="Asset Type" onClick={e => setValueCategory(5)} />
            </Tabs>
          </>
        ) : null}
      </Paper>

      
  //     {value === 0 && category ? <AssetBulkImport /> : null}
  //     {value === 1 ? <LocationBulkImport /> : null}
  //     {value === 2 ? <ContractorBulkImport /> : null}
  //     {value === 3 ? <VendorBulkImport /> : null}
  //     {value === 4 ? <TenantBulkImport /> : null}

  //     {valueCategory === 0 && !value && !category ? <SystemCategories /> : null}
  //     {valueCategory === 1 && !value ? <ClassCategories /> : null}
  //     {valueCategory === 2 && !value ? <TypeCategory /> : null}
  //     {valueCategory === 3 && !value ? <SubTypeCategory /> : null}
  //     {valueCategory === 4 && !value ? <Manufacuture /> : null}
  //     {valueCategory === 5 && !value ? <AssetType /> : null}
  //   </PageContainer>
  // ); */
  }

  return (
    <PageContainer heading="Bulk Import" breadcrumbs={breadcrumbs}>
      <Paper className={classes.root1}>
        {/* </Paper>


      <Paper className={classes.root}> */}

        <Tabs value={value} indicatorColor="primary" textColor="primary">
          <Tab label="Locations" onClick={handleChange} />
          {/* <Tab label="Locations"   onClick={e => setValue(0)}/> */}
          <Tab label="Tenants" onClick={(e) => setValue(1)} />
          <Tab
            label="Assets"
            onClick={(e) => {
              setValue(2);
              setButtonValue(1);
            }}
          />

          {/* <Tab label="Assets" onClick={handleChange} />           */}
          <Tab label="Vendors" onClick={(e) => setValue(3)} />
          <Tab label="Contractors" onClick={(e) => setValue(4)} />
        </Tabs>

        {/* { category?<> <Tabs value={value} indicatorColor="primary" textColor="primary">
          <Tab label="Assets" onClick={handleChange} />
          <Tab label="Locations"   onClick={e => setValue(1)} />
          <Tab label="Contractors" onClick={e => setValue(2)} />
          <Tab label="Vendors" onClick={e => setValue(3)} />
          <Tab label="Tenants" onClick={e => setValue(4)} /></Tabs></>:<> <Tabs value={valueCategory} indicatorColor="primary" textColor="primary"><Tab label="System Category"  />
          <Tab label="Class Category"  onClick={e=>setValueCategory(1)}/>
          <Tab label="Type Category" onClick={e=>setValueCategory(2)} />
          <Tab label="Subtype Category" onClick={e=>setValueCategory(3)} />
          <Tab label="Manufacture" onClick={e=>setValueCategory(4)} />
          <Tab label="Asset Type"  onClick={e=>setValueCategory(5)}/>
 </Tabs>
        </>} */}
      </Paper>

      <Paper className={classes.root2}>
        {value === 2 || (!category && !value) ? (
          <Box display="flex" className={classes.box}>
            <span
              className={buttonValue === 1 ? classes.color : classes.label}
              onClick={handleChange1}
            >
              IMPORT ASSETS
            </span>
            <span
              className={buttonValue === 2 ? classes.color : classes.label}
              onClick={handelCategory}
            >
              IMPORT CATEGORIES
            </span>
            {/* </>
          )} */}
          </Box>
        ) : null}

        {!category && value === 2 && buttonValue === 2 ? (
          <>
            {" "}
            <Tabs
              value={valueCategory}
              indicatorColor="primary"
              tex-tColor="primary"
              className={classes.tabClass}
            >
              <Tab
                label="System Category"
                onClick={(e) => {
                  setValueCategory(0);
                  setValue(2);
                }}
              />
              <Tab
                label="Class Category"
                onClick={(e) => setValueCategory(1)}
              />
              <Tab label="Type Category" onClick={(e) => setValueCategory(2)} />
              <Tab
                label="Subtype Category"
                onClick={(e) => setValueCategory(3)}
              />
              <Tab label="Manufacturer" onClick={(e) => setValueCategory(4)} />
              <Tab label="Asset Type" onClick={(e) => setValueCategory(5)} />
            </Tabs>
          </>
        ) : null}
        {/* </Paper> */}

        {/* {<AssetBulkImport />} */}
        {value === 0 && category ? (
          <LocationBulkImport socket={socket} />
        ) : null}
        {value === 1 ? <TenantBulkImport socket={socket} /> : null}
        {value === 2 && buttonValue === 1 ? (
          <AssetBulkImport socket={socket} />
        ) : null}
        {value === 3 ? <VendorBulkImport socket={socket} /> : null}
        {value === 4 ? <ContractorBulkImport socket={socket} /> : null}
      </Paper>
      <Paper className={classes.root2}>
        {/* {valueCategory === 0 && !value && !category?:null} */}
        {valueCategory === 0 &&
        !category &&
        value === 2 &&
        buttonValue === 2 ? (
          <SystemCategories />
        ) : null}
        {valueCategory === 1 && value === 2 && buttonValue === 2 ? (
          <ClassCategories />
        ) : null}
        {valueCategory === 2 && value === 2 && buttonValue === 2 ? (
          <TypeCategory />
        ) : null}
        {valueCategory === 3 && value === 2 && buttonValue === 2 ? (
          <SubTypeCategory />
        ) : null}
        {valueCategory === 4 && value === 2 && buttonValue === 2 ? (
          <Manufacuture />
        ) : null}
        {valueCategory === 5 && value === 2 && buttonValue === 2 ? (
          <AssetType />
        ) : null}
      </Paper>
    </PageContainer>
  );
}
