import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '../../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '../../../../../@jumbo/components/Common/formElements/AppTextInput';
import Button from '@material-ui/core/Button';
import { requiredMessage, lettersOnlyMessage } from '../../../../../@jumbo/constants/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import {
  addNewAssetType,
  getAssetTypeList,
  updateAssetTypeList,
  addManuallyAssetType,
} from '../../../../../redux/actions/AssetType';
import { emptySpace, numberOnly, numberOnly2, stringOnly, onlyStringWithSpace } from '../../../../../@jumbo/constants/ValidationRegex';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import AppSelectBox from '../../../../../@jumbo/components/Common/formElements/AppSelectBox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import DialogActions from '@material-ui/core/DialogActions';
// import ErrorTable from './ErrorTable';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ErrorIcon from '@material-ui/icons/Error';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },

  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.text.primary,
    },
  },
  serviceTypeList: {
    fontSize: 14,
    letterSpacing: 0.25,
    textOverflow: 'ellipsis',
    wordWrap: 'break-word',
  },
  typeList: {
    width: 535,
    fontSize: 14,
    height: 300,
    backgroundColor: theme.palette.background.default,
    overflow: 'auto',
  },
  imageButton: {
    fontSize: '10px',
    marginLeft: '5px',
    color: theme.palette.text.primary,
    textOverflow: 'ellipsis',
    maxWidth: '200px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  imageRoot: {
    marginTop: '5%',
  },
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
    '& .MuiFormLabel-root': {
      color: '#909090',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      color: theme.palette.text.primary,
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
  errText: {
    color: 'red',
    marginLeft: '16px',
    marginRight: '14px',
    fontSize: '0.75rem',
    marginTop: '3px',
    textAlign: 'left',
    fontFamily: 'Normal',
    fontWeight: 400,
    lineHeight: 1.66,
  },
  appSelectBox: {
    color: '#c7c3bf',
    '& .MuiFormLabel-root': {
      color: '#c7c3bf',
    },
  },
  titleRoot2: {
    fontWeight: '300',
    marginLeft: '5px',
    fontSize: 14,
    textOverflow: 'ellipsis',
    maxWidth: '350px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  paper: {
    position: 'relative',
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '1000px',
      // width: '100%',
      minWidth: '500px',
    },
  },
}));

const AddEditUser = ({ open, onCloseDialog, getSystemCtegorieParams, setOpenWareHouseDialog,setErrroDialog,setAddButton,addButton }) => {
  const classes = useStyles();
  const { currentUser, error } = useSelector(({ ASSETTYPE }) => ASSETTYPE);
  const { orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions } = getSystemCtegorieParams;
  const [classFile, setClassFile] = useState('');
  const [assetType, setAssetType] = useState('');
  const [csvError, setCsvError] = useState('');
  const [assetTypeError, setAssetTypeError] = useState('');
  const dispatch = useDispatch();
  const [value, setValue] = React.useState('1');
  const [valueCheck, setValueCheck] = React.useState(true);

  const handleChange = event => {
    setValue(event.target.value);
    setCsvError('');
    setAssetTypeError('');
    setValueCheck(false);
  };

  useEffect(() => {
    if (currentUser) {
      setAssetType(currentUser?.name);
    }
  }, [currentUser]);

  const onSubmitClick = () => {
    if (!assetType.match(onlyStringWithSpace)) {
      setAssetTypeError(lettersOnlyMessage);
    }
    if (!assetType || assetType.match(emptySpace)) {
      setAssetTypeError(requiredMessage);
    }

    if (
      classFile &&
      classFile.type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
      classFile.type != 'text/csv'
    ) {
      setCsvError('Please upload excel or csv file');
    }

    if (!classFile) {
      setCsvError(requiredMessage);
    }
    if (
      (classFile && classFile.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
      (classFile && classFile.type == 'text/csv')
    ) {
      setAddButton(true)
      onUserSave();
      onCloseDialog();
    }
    if (assetType && assetType.match(onlyStringWithSpace) && !assetType.match(emptySpace)) {
      onUserSave();
    }
  };

  const onUserSave = () => {
    if (currentUser) {
      const formData = {
        asset_type_name: assetType,
      };
      dispatch(
        updateAssetTypeList(currentUser?._id, formData, () => {
          onCloseDialog();
          dispatch(getAssetTypeList(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
        }),
      );
    }
    if (assetType && valueCheck && !currentUser) {
      const manuallyData = {
        asset_type_name: assetType,
      };
      dispatch(
        addManuallyAssetType(manuallyData, () => { onCloseDialog(); setValueCheck(false);
          dispatch(getAssetTypeList(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
        }),
      );
    }

    if (classFile) {
      const formData = new FormData();
      formData.append('assetTypeImportFile', classFile);

      dispatch(
        addNewAssetType( formData, { setErrroDialog: () => setErrroDialog(true), onCloseDialog: () => onCloseDialog(), setAddButton: () =>setAddButton(false) },
          () => { dispatch(getAssetTypeList(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions)) },
        ),
      );
    }
  };
  const handelClose = () => {
    setErrroDialog(false);
    onCloseDialog();
  };

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>{currentUser ? 'Edit Asset Type' : 'Import Asset Type'}</DialogTitle>
      <Box>
        {/* <Dialog
          className={classes.paper}
          open={errroDialog}
          onClose={handelClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-description">Error Details</DialogTitle>
          <Divider />
          <DialogContent>
            <Box className={classes.userInfoRoot}>
              <Box px={6} py={5}>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }} ml={{ xs: -6 }}>
                  <ImportExportIcon />
                  <Box ml={3} display="flex">
                    <Typography className={classes.titleRoot1}>Total Rows Imported : </Typography>
                    <Typography className={classes.titleRoot2}>{error?.data?.total_rows_imported}</Typography>
                  </Box>
                </Box>

                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }} ml={{ xs: -6 }}>
                  <KeyboardArrowRightIcon />
                  <Box ml={3} display="flex">
                    <Typography className={classes.titleRoot1}>Total Rows Processed : </Typography>
                    <Typography className={classes.titleRoot2}>{error?.data?.total_rows_processed}</Typography>
                  </Box>
                </Box>

                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 5 }} ml={{ xs: -6 }}>
                  <ErrorIcon />
                  <Box ml={3} display="flex">
                    <Typography className={classes.titleRoot1}>Total Rows With Errors:</Typography>
                    <Typography className={classes.titleRoot2}>{error?.data?.total_rows_with_errors}</Typography>
                  </Box>
                </Box>
              </Box>
              {error?.data?.error.length > 0 ? (
                <ErrorTable />
              ) : (
                <Box display="flex" alignItems="center" mb={{ xs: 2, sm: 1 }} mt={{ xs: 2 }}>
                  <Typography className={classes.titleRoot2}>{error?.message}</Typography>
                </Box>
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handelClose} autoFocus variant="outlined">
              Cancel
            </Button>
          </DialogActions>
        </Dialog> */}
      </Box>
      <DialogContent dividers>
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
          <GridContainer>
            {currentUser ? (
              <>
                <Grid item xs={12} sm={12}>
                  <AppTextInput
                    className={classes.root}
                    fullWidth
                    variant="outlined"
                    label="Asset Type Name"
                    value={assetType}
                    onChange={e => {
                      setAssetType(e.target.value);
                      setAssetTypeError('');
                      setValueCheck(false);
                    }}
                    helperText={assetTypeError}
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} sm={12}>
                  <FormControl component="fieldset">
                    <RadioGroup aria-label="query" name="query1" value={value} onChange={handleChange}>
                      <FormControlLabel value="1" control={<Radio />} label="Bulk Import" />
                      <FormControlLabel value="2" control={<Radio />} label="Add Asset Type" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {value == 2 ? (
                  <Grid item xs={12} sm={12}>
                    <AppTextInput
                      className={classes.root}
                      fullWidth
                      variant="outlined"
                      label="Asset Type Name"
                      value={assetType}
                      // onKeyPress={event => {
                      //   if (['-', '+', '.', ' '].includes(event.key)) {
                      //     event.preventDefault();
                      //   }
                      // }}
                      onChange={e => {
                        setAssetType(e.target.value);
                        setAssetTypeError('');
                      }}
                      helperText={assetTypeError}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={12}>
                    <Button variant="contained" component="label">
                      <CloudUploadIcon />
                      <p className={classes.imageButton}>{classFile?.size ? classFile?.name : 'Choose Excel File'}</p>
                      <input
                        type="file"
                        hidden
                        onChange={event => {
                          setClassFile(event.target.files[0]);
                          setCsvError('');
                        }}
                        accept=".xlsx"
                      />
                    </Button>{' '}
                    <br />
                    <p className={classes.errText} style={{ color: 'red' }}>
                      {csvError}
                    </p>
                  </Grid>
                )}
              </>
            )}
          </GridContainer>
        </Box>
        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button onClick={onCloseDialog} variant="outlined">
            Cancel
          </Button>

          <Box ml={2}>
            <Button variant="contained" color="primary" style={{ minWidth: '100px' }} onClick={onSubmitClick} disabled={addButton&&!currentUser?true:false}>
              {currentUser ? 'Update' : value == '1' ? 'Import' : 'Add'}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditUser;

AddEditUser.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
