import React from 'react';
import TableCell from '@material-ui/core/TableCell';
// import Checkbox from '@material-ui/core/Checkbox';
import TableRow from '@material-ui/core/TableRow';
// import { timeFromNow } from '../../../../@jumbo/utils/dateHelper';
import { Block, CheckCircleOutline, Delete, Edit, Mail, Visibility } from '@material-ui/icons';
import CmtDropdownMenu from '../../../../@coremat/CmtDropdownMenu';
// import CmtAvatar from '../../../../@coremat/CmtAvatar';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { sentMailToUser } from '../../../../redux/actions/Users';
// import { getSERVICETYPE, updateSERVICETYPEStatus } from '../../../../redux/actions/ServiceType';
// import { Activate, Deactivate } from '../../../../@jumbo/constants/TableLableConstant';
import { getContactUsQuery, updateContactUsQueryStatus } from '../../../../redux/actions/ContactUsQuery';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    fontWeight: '300',
    color: theme.palette.common.dark,
    textOverflow: 'ellipsis',
    maxWidth: '150px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  titleRoot2: {
    marginLeft:'15px',
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    fontWeight: '300',
    color: theme.palette.common.dark,
    textOverflow: 'ellipsis',
    maxWidth: '150px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  titleRoot3: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    fontWeight: '300',
    color: theme.palette.common.dark,
    textOverflow: 'ellipsis',
    maxWidth: '100px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'break-spaces',
  },
}));

const getUserActions = user => {
  const actions = [
    { action: 'view', label: 'View', icon: <Visibility /> },
  ];

  if (user.status === 1) {
    actions.push({ action: 'suspend', label: 'Close', icon: <Block /> });
  }
  return actions;
};

const UserListRow = ({ row, isSelected, onRowClick, onUserEdit, onUserDelete, onUserView, getContactUsQueryParams }) => {
  const { orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions } = getContactUsQueryParams;

  const classes = useStyles();
  const dispatch = useDispatch();

  const onUserMenuClick = menu => {
    if (menu.action === 'view') {
      onUserView(row);
    } else if (menu.action === 'edit') {
      onUserEdit(row);
    } else if (menu.action === 'email') {
      dispatch(sentMailToUser());
    } else if (menu.action === 'suspend') {
      onUserDelete(row);
    } else if (menu.action === 'activate') {
      dispatch(updateContactUsQueryStatus({ _id: row._id, status: 1 }, refreshContactList));
    } else if (menu.action === 'delete') {
      onUserDelete(row);
    }
  };

  function refreshContactList() {
    dispatch(getContactUsQuery(orderBy, order, filterOptions, debouncedSearchTerm, page, rowsPerPage));
  }

  const labelId = `enhanced-table-checkbox-${row._id}`;
  const isItemSelected = isSelected(row._id);
  const userActions = getUserActions(row);

  return (
    <TableRow
      hover
      // onClick={event => onRowClick(event, row.id)}
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row._id}
      selected={isItemSelected}>
      <TableCell padding="checkbox"></TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        {/* <Box display="flex" alignItems="center"> */}
          {/* <div> */}
            <Typography className={classes.titleRoot2} component="div" variant="h4">
              {row.username}
            </Typography>
          {/* </div> */}
        {/* </Box> */}
      </TableCell>

      <TableCell component="th" id={labelId} scope="row" padding="none">
        <Typography className={classes.titleRoot2} component="div" variant="h4">
        {row.email}
        </Typography>
      </TableCell>
      
      <TableCell>
        {/* {' '} */}
        <Typography className={classes.titleRoot} component="div" variant="p" s>
          {row.query}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className={classes.titleRoot3} component="div" variant="p" s>
          {moment(row?.created_at * 1000).format('DD/MM/YYYY hh:mm A')}
        </Typography>
      </TableCell>
      <TableCell>
      <Typography className={classes.titleRoot} component="div" variant="p" s>
      {row?.status === 1 ? `Open` : 'Closed'}
        </Typography>
        </TableCell>

      <TableCell align="center" onClick={event => event.stopPropagation()}>
        <CmtDropdownMenu items={userActions} onItemClick={onUserMenuClick} TriggerComponent={<Edit />} />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(UserListRow);
