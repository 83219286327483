import {
  
  GET_SAMPLEFILE
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  
  files:null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_SAMPLEFILE:{
      return {
        ...state,
        files: action.payload,
      };
    }    
    default:
      return state;
  }
};
