import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Block, CheckCircleOutline, Delete, Edit,  Visibility } from '@material-ui/icons';
import CmtDropdownMenu from '../../../../@coremat/CmtDropdownMenu';
import {  Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { sentMailToUser } from '../../../../redux/actions/Users';
import {
  getServiceRequest,
  updateServiceRequestStatus,
  setCurrentServiceRequset,
  setEdittServiceRequset,
} from '../../../../redux/actions/ServiceRequest';
import { Activate, Deactivate } from '../../../../@jumbo/constants/stringConstant';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import moment from 'moment';
import {  statusChange } from '@jumbo/utils/commonHelper';
import { statusArray } from '../../../../@jumbo/constants/CustomizeSetting';

const useStyles = makeStyles(theme => ({
  titleRoot: {
    // marginLeft: '15px',
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    fontWeight:"300",
    color: theme.palette.common.dark,
    textOverflow: 'ellipsis',
    maxWidth: '150px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  titleRoot2: {
    marginLeft: '15px',
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    fontWeight:"300",
    color: theme.palette.common.dark,
    textOverflow: 'ellipsis',
    maxWidth: '150px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  tableRow: {
    fontWeight:"300",
  },

}));

const getUserActions = user => {
  const actions = [{ action: 'view', label: 'View', icon: <Visibility /> }];
  // if (user.vendor_details === null) {
  //   actions.push({ action: 'add', label: 'Assign Vendor', icon: <PermIdentityIcon /> });
  // } else if (
  //   user?.service_status === statusArray.service_started ||
  //   user?.service_status === statusArray.approve_work ||
  //   user?.service_status === statusArray.service_request_completed ||
  //   user.vendor_details === null
  // ) {
  // } else {
  //   actions.push({ action: 'edit', label: 'Change Assigned Vendor ', icon: <Edit /> });
  // }
  // if (user.status === 1) {
  //   actions.push({ action: 'suspend', label: Deactivate, icon: <Block /> });
  // } else {
  //   actions.push({
  //     action: 'activate',
  //     label: Activate,
  //     icon: <CheckCircleOutline />,
  //   });
  // }
  // actions.push({ action: 'delete', label: 'Delete', icon: <Delete /> });
  return actions;
};

const UserListRow = ({
  row,
  isSelected,
  onUserDelete,
  onUserView,
  getSERVICETYPEParams,
  onUserAdd,
  indexId,
}) => {
  const { orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptionsStatus } = getSERVICETYPEParams;

  const classes = useStyles();
  const dispatch = useDispatch();
  const onUserMenuClick = menu => {
    if (menu.action === 'view') {
      onUserView(row);
    } else if (menu.action === 'edit') {
      dispatch(setEdittServiceRequset(row));
      onUserAdd(true);
    } else if (menu.action === 'add') {
      dispatch(setCurrentServiceRequset(row));
      onUserAdd(true);
    } else if (menu.action === 'email') {
      dispatch(sentMailToUser());
    } else if (menu.action === 'suspend') {
      dispatch(updateServiceRequestStatus({ _id: row._id, status: 2 }, refreshContactList));
    } else if (menu.action === 'activate') {
      dispatch(updateServiceRequestStatus({ _id: row._id, status: 1 }, refreshContactList));
    } else if (menu.action === 'delete') {
      onUserDelete(row);
    }
  };

  function refreshContactList() {
    dispatch(getServiceRequest(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptionsStatus));
  }
  const labelId = `enhanced-table-checkbox-${row?._id}`;
  const isItemSelected = isSelected(row?._id);
  const userActions = getUserActions(row);

  return (
    <TableRow hover role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={row?._id} selected={isItemSelected} >
      <TableCell padding="checkbox"></TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        <Typography className={classes.titleRoot2} component="div" variant="h4">
          {indexId + 1}
        </Typography>
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {row?.tenant_details?.first_name} {''} {row?.tenant_details?.last_name}
        </Typography>
      </TableCell>
      <TableCell>
      <Typography className={classes.titleRoot} component="div" variant="h4">
      {row?.service_type}
        </Typography>
        
        </TableCell>
      <TableCell>
      <Typography className={classes.titleRoot} component="div" variant="h4">
      {moment(row?.created_at * 1000).format('DD/MM/YYYY')}
        </Typography>
        
        

        </TableCell>
      <TableCell>
      <Typography className={classes.titleRoot} component="div" variant="h4">
      {!row?.assigned_user_details  ? '-' : row?.assigned_user_details.first_name}
        </Typography>
        
        
        
        </TableCell>
      <TableCell>
      <Typography className={classes.titleRoot} component="div" variant="h4">
      {row?.vendor_details === null ? '-' : row?.vendor_details.first_name}
        </Typography>
        
        </TableCell>
      <TableCell>
      <Typography className={classes.titleRoot} component="div" variant="h4">
      {statusChange(!row?.service_status?'-':row?.service_status)}
        </Typography>
        
        </TableCell>
      <TableCell>
      <Typography className={classes.titleRoot} component="div" variant="h4">
      {row?.status === 1 ? `Active` : 'Inactive'}
        </Typography>
        </TableCell>

        <TableCell align="center"  style={{ cursor: 'pointer' }}>
      <Visibility onClick={()=>onUserView(row)}/>
      </TableCell>



      {/* <TableCell align="center" onClick={event => event.stopPropagation()}>
        <CmtDropdownMenu items={userActions} onItemClick={onUserMenuClick} TriggerComponent={<Edit />} />
      </TableCell> */}
    </TableRow>
  );
};

export default React.memo(UserListRow);
