import React from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import BuildIcon from '@material-ui/icons/Build';
import { useSelector } from 'react-redux';
// import CmtList from '../../../../@coremat/CmtList';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import useStyles from './index.style';
import { Block, CheckCircleOutline } from '@material-ui/icons';
import CategoryIcon from '@material-ui/icons/Category';
import ErrorTable from './ErrorTable/index';
import GetAppIcon from '@material-ui/icons/GetApp';
import moment from 'moment';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ImageIcon from '@material-ui/icons/Image';
import EmailIcon from '@material-ui/icons/Email';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import ErrorIcon from '@material-ui/icons/Error';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

const UserDetailView = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  const { details } = useSelector(({ BULKCONTRACTOR }) => BULKCONTRACTOR);
  const details_table = useSelector(({ BULKCONTRACTOR }) => BULKCONTRACTOR?.details_table);
  const { file_link, status, time_completed, time_started, fm_admin_id, file_name, image_file_name, image_file_link,
    rows_with_errors, total_rows_imported, total_rows_processed, total_rows_with_errors } = details;

  const handelFileDownload = () => {
    window.location.href = file_link;
  };

  const handelFileDownloadZip = () => {
    window.location.href = image_file_link;
  };

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <Box className={classes.userInfoRoot}>
        <Box mr={3} display="flex" alignItems="center">
          <Box className={classes.avatarView} mr={{ xs: 4, md: 6 }}></Box>

          <Box mt={-2}>
            <Box display="flex" alignItems="center">
              <Typography className={classes.titleRoot}>Contractor Bulk Import Detail</Typography>
              <Box ml={1}></Box>
            </Box>
          </Box>
        </Box>
        <Box ml="auto" mt={-2} display="flex" alignItems="center">
          <Box ml={1}></Box>
          <Box ml={1}>
            <IconButton onClick={onCloseDialog}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box px={6} py={5}>
      <GridContainer>
        <Grid item xs={6} sm={6}>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <FileCopyIcon />
          <Box ml={5} display="flex">
            <Typography className={classes.titleRoot1}>File Name: </Typography>
            <Typography className={classes.titleRoot2}>{!file_name ? '-' : file_name}</Typography>
          </Box>
        </Box>

        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <FileCopyIcon />
          <Box ml={5} display="flex">
            <Typography className={classes.titleRoot1}>Download File: </Typography>
            {/* <Typography className={classes.titleRoot2}>
              CSV File
            </Typography> */}
            <Typography className={classes.titleRoot2} style={{ cursor: 'pointer' }}>
              <GetAppIcon onClick={handelFileDownload} />
            </Typography>
          </Box>
        </Box>

        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <SupervisorAccountIcon />
          <Box ml={5} display="flex">
            <Typography className={classes.titleRoot1}>Fm Admin Name: </Typography>
            <Typography className={classes.fmAdminFirstName}>{!fm_admin_id ? '-' : fm_admin_id?.first_name}</Typography>
            <Typography className={classes.fmAdminLastName}>{!fm_admin_id ? '-' : fm_admin_id?.last_name}</Typography>
          </Box>
        </Box>

        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <EmailIcon />
          <Box ml={5} display="flex">
          <Typography className={classes.titleRoot1}>Email :</Typography>
          <Typography className={classes.titleRoot2}>{fm_admin_id?.email}</Typography>
          </Box>
        </Box>

        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <ImageIcon />
          <Box ml={5} display="flex">
            <Typography className={classes.titleRoot1}> Download Contractor Certificate: </Typography>
            {/* <Typography className={classes.titleRoot2}>
              Contractor Certificate 
            </Typography> */}
            <Typography className={classes.titleRoot2} style={{ cursor: 'pointer' }}>
              <GetAppIcon onClick={handelFileDownloadZip} />
              {/* {!image_file_name ? '-' : <a href={image_file_link}>Contractor Certificate Zip</a>} */}
            </Typography>
          </Box>
        </Box>
        </Grid>
        <Grid item xs={6} sm={6}>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <AccessTimeIcon />
          <Box ml={5} display="flex">
            <Typography className={classes.titleRoot1}>Time Started: </Typography>
            <Typography className={classes.titleRoot2}>
              {!time_started ? '-' : moment(time_started * 1000).format(' DD/MM/YYYY hh:mm A ')}
            </Typography>
          </Box>
        </Box>

        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <AccessTimeIcon />
          <Box ml={5} display="flex">
            <Typography className={classes.titleRoot1}>Time to Complete: </Typography>

            <Typography className={classes.titleRoot2}>{!time_completed ? '-' : time_completed} s</Typography>
          </Box>
        </Box>

        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <KeyboardArrowRightIcon />
          <Box ml={5} display="flex">
            <Typography className={classes.titleRoot1}>Total Rows Processed: </Typography>

            <Typography className={classes.titleRoot2}>{!total_rows_processed && total_rows_processed != 0? '-' : total_rows_processed} </Typography>
          </Box>
        </Box>

        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <ImportExportIcon />
          <Box ml={5} display="flex">
            <Typography className={classes.titleRoot1}>Total Row Imported: </Typography>

            <Typography className={classes.titleRoot2}>{!total_rows_imported && total_rows_imported != 0? '-' : total_rows_imported}</Typography>
          </Box>
        </Box>

        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <ErrorIcon />
          <Box ml={5} display="flex">
            <Typography className={classes.titleRoot1}>Total Rows With Errors: </Typography>
 
            <Typography className={classes.titleRoot2}>{!total_rows_with_errors && total_rows_with_errors != 0 ? '-' : total_rows_with_errors} </Typography>
          </Box>
        </Box>

        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 5 }}>
          {status === 1 ? <CheckCircleOutline /> : <Block />}
          <Box ml={5} display="flex">
            <Typography className={classes.titleRoot1}>Status (Success/Failure):</Typography>
            <Typography className={classes.titleRoot2}>{status === 1 ? 'Success' : 'Failure'}</Typography>
          </Box>
        </Box>
        </Grid>
        </GridContainer>
      </Box>

      {details_table?.rows_with_errors?.length > 0 ? (
        <Box px={6} py={5}>
          <Box mb={5} color="common.dark" className={classes.titleRoot1}>
            Error Details:
          </Box>
          <div>
            <ErrorTable />
          </div>
        </Box>
      ) : null}
    </Dialog>
  );
};

export default UserDetailView;

UserDetailView.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
