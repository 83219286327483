import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Block, CheckCircleOutline, Delete, Edit, Visibility } from '@material-ui/icons';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import {  Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { sentMailToUser } from 'redux/actions/Users';
import { getClassCategoryList, updateClassCategoryStatus } from 'redux/actions/ClassCategory';
import { Activate, Deactivate } from '@jumbo/constants/stringConstant';
import moment from 'moment';
import GetAppIcon from '@material-ui/icons/GetApp';
const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    fontWeight:"300",
    color: theme.palette.text.primary,
    textOverflow: 'ellipsis',
    width:'150px',
    maxWidth: '150px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  titleRoot2: {
    marginLeft:'15px',
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    fontWeight:"300",
    color: theme.palette.text.primary,
    textOverflow: 'ellipsis',
    maxWidth: '150px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  textSize:{
    fontWeight:300
  }
}));

const UserListRow = ({
  row,
  isSelected,
  
  onUserEdit,
  onUserDelete,
  onUserView,
  getSERVICETYPEParams,
  indexId,
}) => {
  const { orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions,Asset } = getSERVICETYPEParams;
  const { authUser } = useSelector(({ auth }) => auth);
  
  const classes = useStyles();
  const dispatch = useDispatch();
  
  const getUserActions = user => {
    const actions =  [
          { action: 'view', label: 'View', icon: <Visibility /> },
        ];
return actions;
  };
  const onUserMenuClick = menu => {
    if (menu.action === 'view') {
      onUserView(row);
    } else if (menu.action === 'edit') {
      onUserEdit(row);
    } else if (menu.action === 'email') {
      dispatch(sentMailToUser());
    } else if (menu.action === 'suspend') {
      dispatch(updateClassCategoryStatus({ _id: row._id, status: 2 }, refreshContactList));
    } else if (menu.action === 'activate') {
      dispatch(updateClassCategoryStatus({ _id: row._id, status: 1 }, refreshContactList));
    } else if (menu.action === 'delete') {
      onUserDelete(row);
    }
  };

  function refreshContactList() {
    dispatch(getClassCategoryList(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions,Asset));
  }
  const labelId = `enhanced-table-checkbox-${row?._id}`;
  const isItemSelected = isSelected(row?._id);
  const userActions = getUserActions(row);

  const handelFileDownload=()=>{
    window.location.href = row?.file_link;
  }

  return (
    <TableRow hover role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={row?._id} selected={isItemSelected}>
      {/* <TableCell padding="checkbox"></TableCell> */}
      <TableCell component="th" id={labelId} scope="row" padding="none">
        <Typography className={classes.titleRoot2} component="div" variant="h4">
          {indexId + 1}
        </Typography>
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {row?.file_name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {!row?.user_details?"-":row?.user_details?.first_name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {!row?.image_file_name?"-":row?.image_file_name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className={classes.titleRoot} component="div" variant="h4">
        {moment(row?.created_at * 1000).format('DD/MM/YYYY')}
          {/* {!row?.manufacturer_details?.name?"-":row?.manufacturer_details?.name} */}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className={classes.titleRoot} component="div" variant="h4" style={{ cursor: 'pointer' }}>
          {/* {!row?.model?"-":row?.model} */}
          <GetAppIcon onClick={handelFileDownload}/>
        </Typography>
      </TableCell>
      {/* <TableCell>
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {!row.make?"-":row.make}
        </Typography>
      </TableCell> */}
      {/* <TableCell>
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {!row?.life_expectancy?"-":row?.life_expectancy}
        </Typography>
      </TableCell> */}
      <TableCell className={classes.textSize}>
        {row?.status === 1 ? `Success` : 'Failure'}
        </TableCell>

        <TableCell align="center"  style={{ cursor: 'pointer' }}>
      <Visibility onClick={()=>onUserView(row)}/>
      </TableCell>

      {/* <TableCell align="center" onClick={event => event.stopPropagation()}>
        <CmtDropdownMenu items={userActions} onItemClick={onUserMenuClick} TriggerComponent={<Edit />} />
      </TableCell> */}
    </TableRow>
  );
};

export default React.memo(UserListRow);
