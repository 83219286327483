import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '../../../../@jumbo/components/Common/formElements/AppTextInput';
import { useDropzone } from 'react-dropzone';
import Button from '@material-ui/core/Button';
import { emailNotValid, lettersOnlyMessage, requiredMessage } from '../../../../@jumbo/constants/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { capitalizeFLetter, isValidEmail } from '../../../../@jumbo/utils/commonHelper';
import { addNewSubAdmin, getSubAdmin, updateSubadminData } from '../../../../redux/actions/SubAdminManagement';

const useStyles = makeStyles(theme => ({
  root: {
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
    "& .MuiFormLabel-root": {
      color: '#909090'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink':{
      color: theme.palette.text.primary
    },
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,}},
    dialogRoot: {
      position: 'relative',
    },
    dialogTitleRoot: {
      '& .MuiTypography-h6': {
        fontSize: 16,
        color: theme.palette.common.dark,
      },
    },
  },
}));

function PhoneNumberInput({ onChange, value, ...other }) {
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    if (!phoneNumber && value) {
      setTimeout(() => {
        setPhoneNumber(value);
      }, 300);
    }
  }, [phoneNumber, value]);

  const onNumberChange = number => {
    setPhoneNumber(number.formattedValue);
    onChange(number.formattedValue);
  };

  return <NumberFormat {...other} onValueChange={onNumberChange} value={phoneNumber} format="(###) ###-####" />;
}


const AddEditUser = ({ open, onCloseDialog, getSubAdminParams }) => {
  const classes = useStyles();
  const currentUser = useSelector(({ usersReducer }) => usersReducer.currentUser);
  const { orderBy, order, filterOptions, debouncedSearchTerm, page, rowsPerPage } = getSubAdminParams;
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [profile_pic, setProfile_pic] = useState('');
  const [phones, setPhones] = useState([{ phone: '', label: 'home' }]);
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [emailError, setEmailError] = useState('');

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setProfile_pic(URL.createObjectURL(acceptedFiles[0]));
    },
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (currentUser) {
      setFirstName(currentUser?.first_name);
      setLastName(currentUser?.last_name);
      setEmail(currentUser?.email);
    }
  }, [currentUser]);

  const nameRegex = /^[a-zA-Z]+$/;
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const onSubmitClick = () => {
    
    if (!firstName) {
      setFirstNameError(requiredMessage);
    } else if (!firstName.match(nameRegex)) {
      setFirstNameError(lettersOnlyMessage);
    }
    if (!lastName) {
      setLastNameError(requiredMessage);
    } else if (!lastName.match(nameRegex)) {
      setLastNameError(lettersOnlyMessage);
    }
    if (!email) {
      setEmailError(requiredMessage);
    } else if (!isValidEmail(email)) {
      setEmailError(emailNotValid);
    }
    if (
      firstName !== '' &&
      lastName !== '' &&
      firstName.match(nameRegex) &&
      lastName.match(nameRegex) &&
      isValidEmail(email) &&
      email
    ) {
      onUserSave();
    }
  };

  const onUserSave = phoneNumbers => {
    const fname = capitalizeFLetter(firstName);
    const lname = capitalizeFLetter(lastName);
    const userDetail = {
      first_name: fname,
      last_name: lname,
      email,
    };

    if (currentUser) {
      dispatch(
        updateSubadminData(currentUser?._id, userDetail, () => {
          onCloseDialog();
          dispatch(getSubAdmin(orderBy, order, filterOptions, debouncedSearchTerm, page, rowsPerPage));
        }),
      );
    } else {
      dispatch(
        addNewSubAdmin(userDetail, () => {
          onCloseDialog();
          dispatch(getSubAdmin(orderBy, order, filterOptions, debouncedSearchTerm, page, rowsPerPage));
        }),
      );
    }
  };


  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>{currentUser ? 'Edit Sub Admin' : 'Add New Sub Admin'}</DialogTitle>
      <DialogContent dividers>
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
          <GridContainer>
            <Grid item xs={12} sm={6}>
              <AppTextInput
                className={classes.root}
                fullWidth
                variant="outlined"
                label="First name"
                value={firstName}
                onChange={event => {
                  setFirstName(event.target.value);
                  if (event.target.value !== '' && firstName.match(nameRegex)) {
                    setFirstNameError('');
                  }
                }}
                helperText={firstNameError}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AppTextInput
                className={classes.root}
                fullWidth
                variant="outlined"
                label="Last name"
                value={lastName}
                onChange={event => {
                  setLastName(event.target.value);
                  if (event.target.value !== '' && lastName.match(nameRegex)) {
                    setLastNameError('');
                  }
                }}
                helperText={lastNameError}
              />
            </Grid>
          </GridContainer>
        </Box>
        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            className={classes.root}
            fullWidth
            variant="outlined"
            label="Email Address"
            value={email}
            onChange={e => {
              setEmail(e.target.value);
              setEmailError('');
            }}
            disabled={currentUser}
            helperText={emailError}
          />
        </Box>
        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button onClick={onCloseDialog} variant="outlined">
            Cancel
          </Button>
          <Box ml={2}>
            <Button variant="contained" color="primary" style={{ minWidth: '100px' }} onClick={onSubmitClick}>
              {currentUser ? 'UPDATE' : 'ADD'}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditUser;

AddEditUser.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
