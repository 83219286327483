import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
const useStyles = makeStyles({
  table: {
    minWidth: 370,
    '& .MuiTableCell-head': {
      fontWeight: '600',
    },
  },
  tableRow: {
    fontWeight: '300',
  },
  tableRow1: {
    color: 'white',
    backgroundColor: 'red',
  },
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    textOverflow: 'ellipsis',
    maxWidth: '250px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  descriptionField: {
    fontSize: 14,
    fontWeight: '300',
    textOverflow: 'ellipsis',
    // maxWidth: "50px",
    overflow: 'hidden',
    wordBreak: 'break-all',
    // backgroundColor: '#f4f4f7',
    // color: '#3B1A45',
    minWidth: '200px',
  },
  errorTableHead: {
    fontSize: 16,
    fontWeight: '600',
    backgroundColor: '#f4f4f7',
    color: '#3B1A45',
    minWidth: '350px',
    // line-height: 1.5rem;
  },
  errorTableHeadOther: {
    fontSize: 16,
    fontWeight: '600',
    backgroundColor: '#f4f4f7',
    color: '#3B1A45',
    minWidth:"130px",
  },
  contactNumberField: {
    fontSize: 16,
    fontWeight: '600',
    backgroundColor: '#f4f4f7',
    color: '#3B1A45',
    minWidth:"180px",
  },
  descriptionField: {
    fontSize: 14,
    fontWeight: '300',
    maxHeight: '80px',
    textOverflow: 'ellipsis',
    // maxWidth: "50px",
    overflow: 'auto',
    wordBreak: 'break-all',
    // backgroundColor: '#f4f4f7',
    // color: '#3B1A45',
    minWidth: '200px',
  },
  errorDescriptionField: {
    color: 'white',
    backgroundColor: 'red',
    fontSize: 14,
    fontWeight: '300',
    maxHeight: '80px',
    textOverflow: 'ellipsis',
    overflow: 'auto',
    wordBreak: 'break-all',
    minWidth: '200px',
  },
  indexField: {
    fontSize: 14,
    fontWeight: '300',
},
emptyTableHead: {
  fontSize: 16,
  fontWeight: '600',
  backgroundColor: '#f4f4f7',
  color: '#3B1A45',
  // minWidth: '180px',
},
});

export default function ErrorTable() {
  const classes = useStyles();
  const { errorData } = useSelector(({ TENANTBULK }) => TENANTBULK);

  console.log(errorData, 'errorData');
  const [indexValue, setindexValue] = useState();

  // console.log('fine', indexValue);

  useEffect(() => {
    const ErrorValue = [];
    const arr = rows.map((item, index) => {
      const err = errorData?.data?.error_details.forEach(error => {
        if (index == error.column) {
          return ErrorValue.push({ id: item, row: error.row, msg: error.message });
        }
      });

      setindexValue(ErrorValue);
    });
  }, [errorData]);

  const rows = [
    '',
    'email',
    'first_name',
    'last_name',
    'room_number',
    'floor_number',
    'street_number',
    'street_name',
    'suburb',
    'city',
    'state',
    'post_code',
    'location (Latitude,Longitude)',
    'contact_number',
    'lease_expiry_date',
    'client_name',
  ];

  const rowsChangeName = [
    '',
    'Row No.',
    'Email',
    'First Name',
    'Last Name',
    'Room Number',
    'Floor Number',
    'Street Number',
    'Street Name',
    'Suburb',
    'City',
    'State',
    'Post Code',
    'Location (Latitude, Longitude)',
    'Contact Number',
    'Lease Expiry Date',
    'Client Name',
  ];

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table" id="table">
        <TableHead>
          <TableRow>
            {/* {rows.map((item, index) => {
              return (
                <> */}
                  {rowsChangeName?.map((data, index) =>{
                  return (
                  <TableCell className={
                    data == '' ? classes.emptyTableHead
                    : data == 'Contact Number' ? classes.contactNumberField
                    : classes.errorTableHeadOther
                  }>{data}</TableCell>
                  );
                })}
                {/* </>
              );
            })} */}
          </TableRow>
        </TableHead>
        <TableBody>
          {errorData?.data?.rows_with_errors?.map((row, index) => {
            // console.log('row map', indexValue.find(item=>item.id == 'department' && item.row==row.row));
            return (
              <>
                <TableRow className={classes.tableRow}>
                  <TableCell></TableCell>

                  <TableCell component="th" scope="row">
                <Typography
                  className={
                    indexValue?.find(item => item.id == 'row' && item.row == row.row)
                      ? classes.tableRow1
                      : classes.titleRoot
                  }>
                  {!row?.row ? '-' : row?.row}
                </Typography>
              </TableCell>

                  <Tooltip
                    title={
                      indexValue?.find(item => item.id == 'email' && item.row == row.row)
                        ? indexValue?.find(item => item.id == 'email' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue?.find(item => item.id == 'email' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {row?.email == 'FieldEmpty' ? '-' : row?.email}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue?.find(item => item.id == 'first_name' && item.row == row.row)
                        ? indexValue?.find(item => item.id == 'first_name' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell component="th" scope="row">
                      <Typography
                        id="asset"
                        className={
                          indexValue?.find(item => item.id == 'first_name' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {row?.first_name == 'FieldEmpty' ? '-' : row?.first_name}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue?.find(item => item.id == 'last_name' && item.row == row.row)
                        ? indexValue?.find(item => item.id == 'last_name' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell scope="row">
                      <Typography
                        className={
                          indexValue?.find(item => item.id == 'last_name' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {row?.last_name == 'FieldEmpty' ? '-' : row?.last_name}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue?.find(item => item.id == 'room_number' && item.row == row.row)
                        ? indexValue?.find(item => item.id == 'room_number' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell scope="row">
                      <Typography
                        className={
                          indexValue?.find(item => item.id == 'room_number' && item.row == row.row)
                            ? classes.errorDescriptionField
                            : classes.descriptionField
                        }>
                        {row?.room_number == 'FieldEmpty' ? '-' : row?.room_number}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue?.find(item => item.id == 'floor_number' && item.row == row.row)
                        ? indexValue?.find(item => item.id == 'floor_number' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell scope="row">
                      <Typography
                        className={
                          indexValue?.find(item => item.id == 'floor_number' && item.row == row.row)
                            ? classes.errorDescriptionField
                            : classes.descriptionField
                        }>
                        {row?.floor_number == 'FieldEmpty' ? '-' : row?.floor_number}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue?.find(item => item.id == 'street_number' && item.row == row.row)
                        ? indexValue?.find(item => item.id == 'street_number' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell scope="row">
                      <Typography
                        className={
                          indexValue?.find(item => item.id == 'street_number' && item.row == row.row)
                            ? classes.errorDescriptionField
                            : classes.descriptionField
                        }>
                        {row?.street_number == 'FieldEmpty' ? '-' : row?.street_number}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue?.find(item => item.id == 'street_name' && item.row == row.row)
                        ? indexValue?.find(item => item.id == 'street_name' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell scope="row">
                      <Typography
                        className={
                          indexValue?.find(item => item.id == 'street_name' && item.row == row.row)
                            ? classes.errorDescriptionField
                            : classes.descriptionField
                        }>
                        {row?.street_name == 'FieldEmpty' ? '-' : row?.street_name}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue?.find(item => item.id == 'suburb' && item.row == row.row)
                        ? indexValue?.find(item => item.id == 'suburb' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell scope="row">
                      <Typography
                        className={
                          indexValue?.find(item => item.id == 'suburb' && item.row == row.row)
                            ? classes.errorDescriptionField
                            : classes.descriptionField
                        }>
                        {row?.suburb == 'FieldEmpty' ? '-' : row?.suburb}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue?.find(item => item.id == 'city' && item.row == row.row)
                        ? indexValue?.find(item => item.id == 'city' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell scope="row">
                      <Typography
                        className={
                          indexValue?.find(item => item.id == 'city' && item.row == row.row)
                            ? classes.errorDescriptionField
                            : classes.descriptionField
                        }>
                        {row?.city == 'FieldEmpty' ? '-' : row?.city}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue?.find(item => item.id == 'state' && item.row == row.row)
                        ? indexValue?.find(item => item.id == 'state' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell scope="row">
                      <Typography
                        className={
                          indexValue?.find(item => item.id == 'state' && item.row == row.row)
                            ? classes.errorDescriptionField
                            : classes.descriptionField
                        }>
                        {row?.state == 'FieldEmpty' ? '-' : row?.state}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue?.find(item => item.id == 'post_code' && item.row == row.row)
                        ? indexValue?.find(item => item.id == 'post_code' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell scope="row">
                      <Typography
                        className={
                          indexValue?.find(item => item.id == 'post_code' && item.row == row.row)
                            ? classes.errorDescriptionField
                            : classes.descriptionField
                        }>
                        {row?.post_code == 'FieldEmpty' ? '-' : row?.post_code}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue?.find(item => item.id == 'location (Latitude,Longitude)' && item.row == row.row)
                        ? indexValue?.find(item => item.id == 'location (Latitude,Longitude)' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue?.find(item => item.id == 'location (Latitude,Longitude)' && item.row == row.row)
                            ? classes.errorDescriptionField
                            : classes.descriptionField
                        }>
                        {row['location (Latitude,Longitude)'] == 'FieldEmpty' ? '-' : row['location (Latitude,Longitude)']}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue?.find(item => item.id == 'contact_number' && item.row == row.row)
                        ? indexValue?.find(item => item.id == 'contact_number' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue?.find(item => item.id == 'contact_number' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {row?.contact_number == 'FieldEmpty' ? '-' : row?.contact_number}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>
                  <Tooltip
                    title={
                      indexValue?.find(item => item.id == 'lease_expiry_date' && item.row == row.row)
                        ? indexValue?.find(item => item.id == 'lease_expiry_date' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue?.find(item => item.id == 'lease_expiry_date' && item.row == row.row)
                            ? classes.errorDescriptionField
                            : classes.descriptionField
                        }>
                        {row?.lease_expiry_date == 'FieldEmpty' ? '-' : row?.lease_expiry_date}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue?.find(item => item.id == 'client_name' && item.row == row.row)
                        ? indexValue?.find(item => item.id == 'client_name' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue?.find(item => item.id == 'client_name' && item.row == row.row)
                            ? classes.errorDescriptionField
                            : classes.descriptionField
                        }>
                        {row?.client_name == 'FieldEmpty' ? '-' : row?.client_name}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>
                </TableRow>
              </>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
