import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Visibility } from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { getClassCategoryList } from 'redux/actions/ClassCategory';
import moment from 'moment';
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    fontWeight: "300",
    color: theme.palette.text.primary,
    textOverflow: 'ellipsis',
    width:'150px',
    maxWidth: '150px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  fileName: {
    marginLeft:'15px',
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    fontWeight: "300",
    color: theme.palette.text.primary,
    textOverflow: 'ellipsis',
    width:'150px',
    maxWidth: '150px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  textSize: {
    fontWeight: 300
  },
  titleRoot2: {
    marginLeft: '20px',
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    fontWeight: '300',
    color: theme.palette.text.primary,
    textOverflow: 'ellipsis',
    maxWidth: '150px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

const UserListRow = ({ row, isSelected, onUserEdit, onUserDelete, onUserView, getSERVICETYPEParams, indexId }) => {
  const { orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions, Vendor } = getSERVICETYPEParams;
  const { authUser } = useSelector(({ auth }) => auth);
  const classes = useStyles();
  const dispatch = useDispatch();

  const getUserActions = user => {
    const actions = [
      { action: 'view', label: 'View', icon: <Visibility /> },
    ];
    return actions;
  };

  function refreshContactList() {
    dispatch(getClassCategoryList(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions, Vendor));
  }
  const labelId = `enhanced-table-checkbox-${row?._id}`;
  const isItemSelected = isSelected(row?._id);
  const userActions = getUserActions(row);

  const handelFileDownload = () => {
    window.location.href = row?.file_link;
  }

  return (
    <TableRow hover role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={row?._id} selected={isItemSelected}>
      {/* <TableCell></TableCell> */}
      <TableCell component="th" id={labelId} scope="row" padding="none">
        <Typography className={classes.titleRoot2} component="div" variant="h4">
          {indexId + 1}
        </Typography>
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        <Typography className={classes.fileName} component="div" variant="h4">
          {row?.file_name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {!row?.user_details ? "-" : row?.user_details?.first_name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {moment(row?.created_at * 1000).format('DD/MM/YYYY')}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className={classes.titleRoot} component="div" variant="h4" style={{ cursor: 'pointer' }}>
          <GetAppIcon onClick={handelFileDownload} />
        </Typography>
      </TableCell>
      <TableCell className={classes.textSize}>
        {row?.status === 1 ? `Success` : 'Failure'}
      </TableCell>
      <TableCell align="center" style={{ cursor: 'pointer' }}>
        <Visibility onClick={() => onUserView(row)} />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(UserListRow);
