import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '../../../../@jumbo/components/Common/formElements/AppTextInput';
import Button from '@material-ui/core/Button';
import { emailNotValid, lettersOnlyMessage, requiredMessage } from '../../../../@jumbo/constants/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { capitalizeFLetter, isValidEmail } from '../../../../@jumbo/utils/commonHelper';
import { updateUser } from '../../../../redux/actions/Users';
import { addNewFMProvider, getFMProvider, updateFMProviderData } from '../../../../redux/actions/FMProviderManagement';
import { stringOnly } from '../../../../@jumbo/constants/ValidationRegex';

const useStyles = makeStyles(theme => ({
  root: {
    "& .Mui-error": {
      color: 'red'
    },
    "& .MuiFormHelperText-root": {
      color: 'red'
    },
    "& .MuiFormLabel-root": {
      color: '#909090'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink':{
      color: theme.palette.text.primary
    }
    
  },
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
  
  
}));

const splitName = user => {
  if (user) {
    const [fName, mName, lName] = user.name.split(' ');
    return [fName, lName ? mName + ' ' + lName : mName];
  }

  return ['', ''];
};

const AddEditUser = ({ open, onCloseDialog, getFMProviderParams }) => {
  const classes = useStyles();
  const currentUser = useSelector(({ FMPROVIDER }) => FMPROVIDER.currentUser);
  const { orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions } = getFMProviderParams;
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');

  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');

  const [emailError, setEmailError] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    if (currentUser) {
      // const [fName, lName] = splitName(currentUser);
      setFirstName(currentUser?.first_name);
      setLastName(currentUser?.last_name);

      setEmail(currentUser?.email);
    }
  }, [currentUser]);

  const onSubmitClick = () => {
     if (!firstName) {
      setFirstNameError(requiredMessage);
    }
    else if (!firstName.match(stringOnly)) {
      setFirstNameError(lettersOnlyMessage);
    } 
    if (!lastName) {
      setLastNameError(requiredMessage);
    }
    else if (!lastName.match(stringOnly)) {
      setLastNameError(lettersOnlyMessage);
    } 
    if (!email) {
      setEmailError(requiredMessage);
    } else if (!isValidEmail(email)) {
      setEmailError(emailNotValid);
    } 
    if(firstName && firstName.match(stringOnly) && lastName && lastName.match(stringOnly) && email &&  isValidEmail(email)){
   
      onUserSave();
    }
  };

  const onUserSave = () => {
    const fname = capitalizeFLetter(firstName)
    const lname = capitalizeFLetter(lastName)
    const userDetail = {
      first_name: fname,
      last_name: lname,
      email,
    };

    if (currentUser) {
      dispatch(
        updateFMProviderData(currentUser?._id, userDetail , () => {
          onCloseDialog();
          dispatch(getFMProvider(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
        }),
      );
    } else {
      dispatch(
        addNewFMProvider(userDetail, () => {
          onCloseDialog();
          dispatch(getFMProvider(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
        }),
      );
    }
  };

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>
        {currentUser ? 'Edit FM Provider' : 'Add New FM Provider'}
      </DialogTitle>
      <DialogContent dividers>
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
          <GridContainer>
            <Grid item xs={12} sm={6}>
              <AppTextInput
              className={classes.root}
                fullWidth
                variant="outlined"
                label="First name"
                value={firstName}
                onChange={e => {
                  setFirstName(e.target.value);
                  setFirstNameError('');
                }}
                helperText={firstNameError}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AppTextInput
              className={classes.root}
                fullWidth
                variant="outlined"
                label="Last name"
                value={lastName}
                onChange={e => {
                  setLastName(e.target.value);
                  setLastNameError('');
                }}
                helperText={lastNameError}
              />
            </Grid>
          </GridContainer>
        </Box>
        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
          className={classes.root}
            fullWidth
            variant="outlined"
            label="Email Address"
            value={email}
            onChange={e => {
              setEmail(e.target.value);
              setEmailError('');
            }}
            disabled={currentUser}
            helperText={emailError}
          />
        </Box>

        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button variant='outlined' onClick={onCloseDialog}>Cancel</Button>
          <Box ml={2}>
            <Button variant="contained" style={{minWidth:'100px'}} color="primary" onClick={onSubmitClick}>
            {currentUser?'UPDATE':'ADD'}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditUser;

AddEditUser.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
