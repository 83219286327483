import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';
import {
  GET_MANUFACTURLIST,
  SET_MANUFACTURE_UPDATE,
  GET_MANUFACTUREEDETAILS,
  SET_MANUFACTURE_ERROR,
} from '../../@jumbo/constants/ActionTypes';
import {
  addManufacture,
  listManufacture,
  manufactuerStatusUpdate,
  manufactureupdate,
  manufacturedetails,
  deleteMAnufacture,
  manufacturefile,
  addManufactureManually,
} from '../../@jumbo/constants/ApiConstatnt';

// get Item list
export const getManufactureList = (sortBy, sort, searchTerm, page, limit, filterOptions, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(listManufacture, {
        params: { sortBy: `${sortBy}:${sort}`, search: searchTerm, page: page + 1, limit: limit, status: filterOptions[0] },
      })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_MANUFACTURLIST, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError());
          dispatch({ type: GET_MANUFACTURLIST, payload: [] });
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Item list not found.') {
          dispatch({ type: GET_MANUFACTURLIST, payload: [] });
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

// set current user for view tab
export const setManufactureList = user => {
  return dispatch => {
    dispatch({ type: SET_MANUFACTURE_UPDATE, payload: user });
  };
};

// add new FM provider
export const addNewManufacturer = (user, callbackFun, listCall) => {
  return dispatch => {
    // dispatch(fetchStart());
    dispatch(fetchSuccess('Manufacturer bulk import process has been start.'));
    axios
      .post(addManufacture, user)
      .then(data => {
        if (data) {
          
          // if (data?.data?.data?.error.length < 1){
          if (data?.data?.data?.red_snakbar) {
            setTimeout(() => {
              dispatch(fetchError(data?.data?.message));
              // dispatch(fetchSuccess('New Manufacturer added successfully.'));
            }, 2000);
          } else {
            setTimeout(() => {
              dispatch(fetchSuccess(data?.data?.message));
              // dispatch(fetchSuccess('New Manufacturer added successfully.'));
            }, 2000);
          }

          if (callbackFun) {
            callbackFun.setErrroDialog();
          }
          if (listCall) listCall(data.data);
          dispatch({ type: SET_MANUFACTURE_ERROR, payload: data?.data });
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
        callbackFun.onCloseDialog();
        callbackFun.setAddButton()
      });
  };
};

// add manually
export const addManuallyManufacturer = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(addManufactureManually, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('New Manufacturer added successfully.'));
          }, 2000);
          // if(AddToWarehouse){
          //   dispatch({ type: GET_WAREHOUSELIST, payload: data.data.data });
          // }
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

export const updateAssetTypeStatus = (data, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(manufactuerStatusUpdate, data)
      .then(response => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Manufacturer status updated successfully.'));
          }, 3000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

export const getManufacturerDetails = (userId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(manufacturedetails + userId)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_MANUFACTUREEDETAILS, payload: data.data.data });

          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Item List not found.') {
          dispatch(fetchError(error?.response?.data?.message));
          dispatch({ type: GET_MANUFACTUREEDETAILS, payload: [] });
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

export const setClassCategoryDetails = user => {
  return dispatch => {
    dispatch({ type: SET_MANUFACTURE_UPDATE, payload: user });
  };
};

export const deleteManufacturer = (userId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete(deleteMAnufacture + userId)
      .then(data => {
        if (data.status === 200) {
          setTimeout(() => {
            dispatch(fetchSuccess('Manufacturer deleted successfully.'));
          }, 2000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError());
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

export const sentMailToUser = () => {
  return dispatch => {
    dispatch(fetchSuccess('Email has been sent to user successfully'));
  };
};

export const updateManufacturerList = (id, user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(manufactureupdate + id, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Manufacturer updated successfully.'));
          }, 3000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

// export const getManufacturerFile = (userId, callbackFun) => {
//   return dispatch => {
//     dispatch(fetchStart());
//     axios
//       .get(manufacturefile+userId ,{ params: { type:userId},})
//       .then(data => {
//         if (data.status === 200) {
//           dispatch(fetchSuccess());
//           dispatch({ type: GET_MANUFACTUREEFILE, payload: data.data.data });

//           if (callbackFun) callbackFun();
//         } else {
//           dispatch(fetchError('There was something issue in responding server.'));
//         }
//       })
//       .catch(error => {
//         if (error?.response?.data?.message === 'Item List not found.') {
//           dispatch(fetchError(error?.response?.data?.message));
//           dispatch({ type: GET_MANUFACTUREEFILE, payload: [] });
//         } else {
//           dispatch(fetchError(error?.response?.data?.message));
//         }
//       });
//   };
// };
