import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useSelector } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles({
  table: {
    minWidth: 370,
    '& .MuiTableCell-head': {
      fontWeight: '600',
    },
  },
  tableRow: {
    fontWeight: '300',
  },
  tableRow1: {
    color: 'white',
    backgroundColor: 'red',
    fontSize: 14,
    // letterSpacing: 0.25,
    textOverflow: 'ellipsis',
    maxWidth: '250px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontWeight: '300',
  },
  titleRoot: {
    maxWidth: '250px',
    fontSize: 14,
    letterSpacing: 0.25,
    textOverflow: 'ellipsis',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  descriptionField: {
    fontSize: 14,
    fontWeight: '300',
    maxHeight: "80px",
    textOverflow: 'ellipsis',
    // maxWidth: "50px",
    overflow: 'auto',
    wordBreak: 'break-all',
    // backgroundColor: '#f4f4f7',
    // color: '#3B1A45',
    minWidth: '200px',
  },
    errorDescriptionField: {
    color: 'white',
    backgroundColor: 'red',
    fontSize: 14,
    fontWeight: '300',
    maxHeight: "80px",
    textOverflow: 'ellipsis',
    overflow: 'auto',
    wordBreak: 'break-all',
    minWidth: '200px',
  },
  indexTableHead: {
    fontSize: 16,
    fontWeight: '600',
    backgroundColor: '#f4f4f7',
    color: '#3B1A45',
    minWidth: '115px',
  },
  emptyTableHead: {
    fontSize: 16,
    fontWeight: '600',
    backgroundColor: '#f4f4f7',
    color: '#3B1A45',
    // minWidth: '180px',
  },
  errorTableHeadOther: {
    fontSize: 16,
    fontWeight: '600',
    backgroundColor: '#f4f4f7',
    color: '#3B1A45',
    minWidth: '180px',
  },
  errorTableHead: {
    fontSize: 16,
    fontWeight: '600',
    backgroundColor: '#f4f4f7',
    color: '#3B1A45',
    minWidth: '350px',
    // line-height: 1.5rem;
  },
  indexField: {
    marginBottom: 2,
    fontSize: 14,
},
  // errorTableHeadOther: {
  //   fontSize: 16,
  //   fontWeight: '600',
  //   backgroundColor: '#f4f4f7',
  //   color: '#3B1A45',
  // },
});

export default function ErrorTable() {
  const classes = useStyles();
  const { errorData } = useSelector(({ BULKVENDOR }) => BULKVENDOR);
  const [indexValue, setindexValue] = useState([]);
  const TableHeadName = [
    '',
    'email',
    'first_name',
    'last_name',
    'service_type_name',
    'inventory_site (street_number, street_name, city, state, post_code)',
    'inventory_site (Latitude,Longitude)',
    'contact_number',
    'ABN_details',
    'client_name',
    'note1',
    'note2',
    'note3',
    'note4',
    'note5',
  ];

  const TableHeadChangeName = [
    '',
    'Row No.',
    'Email',
    'First Name',
    'Last Name',
    'Service Type Name',
    'Inventory Site (Street Number, Street Name, City, State, Post Code)',
    'Inventory Site (Latitude, Longitude)',
    'Contact Number',
    'ABN Details',
    'Client Name',
    'Note 1',
    'Note 2',
    'Note 3',
    'Note 4',
    'Note 5',
    '',
  ];
  
  useEffect(() => {
    const ErrorValue = [];
    const arr = TableHeadName.map((item, index) => {
      const err = errorData?.data?.error_details.forEach(error => {
        if (index == error.column) {
          return ErrorValue.push({ id: item, row: error.row, msg: error.message });
        }
      });
      setindexValue(ErrorValue);
    });
  }, [errorData]);

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {/* {TableHeadChangeName?.map((data, index) => {
              return (
                <> */}
                {TableHeadChangeName?.map((data, index) =>{
                  return (
                  <TableCell
                    className={
                      data == 'Inventory Site (Street Number, Street Name, City, State, Post Code)' ? classes.errorTableHead
                      : data == 'Index No.' ? classes.indexTableHead
                      : data == '' ? classes.emptyTableHead
                      : classes.errorTableHeadOther
                    }>
                    {data}
                  </TableCell>  
                  );
            })}
            {/* </>
              );
            
            })} */}
          </TableRow>
        </TableHead>
        <TableBody>
          {errorData?.data?.rows_with_errors?.map((row, index) => {
            return (
              <>
                <TableRow className={classes.tableRow}>
                  <TableCell></TableCell>

                  <TableCell component="th" scope="row">
                <Typography
                  className={
                    indexValue?.find(item => item.id == 'row' && item.row == row.row)
                      ? classes.tableRow1
                      : classes.titleRoot
                  }>
                  {!row?.row ? '-' : row?.row}
                </Typography>
              </TableCell>

                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'email' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'email' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'email' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {row?.email == 'FieldEmpty' ? '-' : row?.email}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'first_name' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'first_name' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'first_name' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {row?.first_name == 'FieldEmpty' ? '-' : row?.first_name}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'last_name' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'last_name' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'last_name' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {row?.last_name == 'FieldEmpty' ? '-' : row?.last_name}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'service_type_name' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'service_type_name' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'service_type_name' && item.row == row.row)
                            ? classes.errorDescriptionField
                            : classes.descriptionField
                        }>
                        {row?.service_type_name == 'FieldEmpty' ? '-' : row?.service_type_name}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue.find(
                        item =>
                          item.id == 'inventory_site (street_number, street_name, city, state, post_code)' &&
                          item.row == row.row,
                      )
                        ? indexValue.find(
                            item =>
                              item.id == 'inventory_site (street_number, street_name, city, state, post_code)' &&
                              item.row == row.row,
                          ).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(
                            item =>
                              item.id == 'inventory_site (street_number, street_name, city, state, post_code)' &&
                              item.row == row.row,
                          )
                            ? classes.errorDescriptionField
                            : classes.descriptionField
                        }>
                        {row?.['inventory_site (street_number, street_name, city, state, post_code)'] == 'FieldEmpty'
                          ? '-'
                          : row?.['inventory_site (street_number, street_name, city, state, post_code)']}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'inventory_site (Latitude,Longitude)' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'inventory_site (Latitude,Longitude)' && item.row == row.row)
                            .msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'inventory_site (Latitude,Longitude)' && item.row == row.row)
                            ? classes.errorDescriptionField
                            : classes.descriptionField
                        }>
                        {row?.['inventory_site (Latitude,Longitude)'] == 'FieldEmpty'
                          ? '-'
                          : row?.['inventory_site (Latitude,Longitude)']}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'contact_number' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'contact_number' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'contact_number' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {row?.contact_number == 'FieldEmpty' ? '-' : row?.contact_number}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'ABN_details' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'ABN_details' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'ABN_details' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {row?.ABN_details == 'FieldEmpty' ? '-' : row?.ABN_details}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'client_name' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'client_name' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'client_name' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {row?.client_name == 'FieldEmpty' ? '-' : row?.client_name}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'note1' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'note1' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'note1' && item.row == row.row)
                            ? classes.errorDescriptionField
                            : classes.descriptionField
                        }>
                        {row?.note1 == 'FieldEmpty' ? '-' : row?.note1}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'note2' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'note2' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'note2' && item.row == row.row)
                            ? classes.errorDescriptionField
                            : classes.descriptionField
                        }>
                        {row?.note2 == 'FieldEmpty' ? '-' : row?.note2}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'note3' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'note3' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'note3' && item.row == row.row)
                            ? classes.errorDescriptionField
                            : classes.descriptionField
                        }>
                        {row?.note3 == 'FieldEmpty' ? '-' : row?.note3}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'note4' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'note4' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'note4' && item.row == row.row)
                            ? classes.errorDescriptionField
                            : classes.descriptionField
                        }>
                        {row?.note4 == 'FieldEmpty' ? '-' : row?.note4}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'note5' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'note5' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'note5' && item.row == row.row)
                            ? classes.errorDescriptionField
                            : classes.descriptionField
                        }>
                        {row?.note5 == 'FieldEmpty' ? '-' : row?.note5}
                      </Typography>
                    </TableCell>
                  </Tooltip>
                </TableRow>
              </>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
