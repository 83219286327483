import React from 'react';
import Box from '@material-ui/core/Box';
import { useSelector } from 'react-redux';
import useStyles from '../index.style';
import { CheckCircleOutline } from '@material-ui/icons';
import GridContainer from '../../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import RequestQuation from '../RequestQuation';
import Divider from '@material-ui/core/Divider';
import { statusChange } from '@jumbo/utils/commonHelper';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import QuatationTable from '../QuatationTable';
import ApproveWork from '../ApproveWork';
import { statusArray } from '../../../../../@jumbo/constants/CustomizeSetting';
import FaceIcon from '@material-ui/icons/Face';
import EventIcon from '@material-ui/icons/Event';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import Typography from '@material-ui/core/Typography';

const AssignVender = ({ handelOpenAssignVendor }) => {
  const classes = useStyles();
  const { details } = useSelector(({ SERVICEREQUEST }) => SERVICEREQUEST);
  // const handelOpenAssign = () => {
  //   handelOpenAssignVendor();
  // };

  return (
    <>
      <Box px={6} py={5}>
        <Box mb={5} color="common.dark" className={classes.titleRoot1}>
          Assign Vendor :
          {/* <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
            <Box ml={5}>
              {details?.service_status === statusArray.service_started ||
              details?.service_status === statusArray.approve_work ||
              details?.service_status === statusArray.service_request_completed ? null : (
                <Button onClick={handelOpenAssign} variant="outlined" color="primary">
                  {details?.vendor_details === null ? 'Assign' : 'Change Assigned Vendor'}
                </Button>
              )}
            </Box>
            <Box ml={5}>
              {details?.vendor_details === null ? (
                details?.quotations_requested_date === null ? (
                  <RequestQuation />
                ) : (
                  <QuatationTable handelOpenAssign={handelOpenAssign} />
                )
              ) : (
                ''
              )}
            </Box>
            <Box ml={5}>
              <Box alignItems="center" mb={{ xs: 4, sm: 7 }} display="flex" ml={5} width="300px" mt={7}>
                <CheckCircleOutline /> {/* <p style={{ marginLeft: '2%' }} > */}
                {/* <Typography className={classes.titleRoot1}>Status : </Typography>
                <Typography className={classes.titleRoot2}>{statusChange(details?.service_status)}</Typography>
                {/* </p> */}
              {/* </Box>
            </Box>
            <Box ml={5}>{details?.service_status === statusArray.service_request_completed ? <ApproveWork /> : ''}</Box>
          </Box> */} 
          <Box px={6} py={5}>
            <GridContainer>
              <Grid item xs={6} sm={6}>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
                  <FaceIcon />
                  <Box ml={5}  display="flex">
                    <Typography className={classes.titleRoot1}>Assigned Vendor: </Typography>
                    <Typography className={classes.titleRoot2}>
                      {!details?.vendor_details?"-":`${details?.vendor_details?.first_name} ${details?.vendor_details?.last_name}`}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
                  <FaceIcon />
                  <Box ml={5}  display="flex">
                  <Typography className={classes.titleRoot1}>Assigned Contractor:</Typography>
                    <Typography className={classes.titleRoot2}>
                    {!details?.contractor_details?"-":`${details?.contractor_details?.first_name} ${details?.contractor_details?.last_name}`}
                    </Typography>
                     
                  </Box>
                </Box>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
                  <EventIcon />
                  <Box ml={5}  display="flex">
                  <Typography className={classes.titleRoot1}>Service Request Date : </Typography>
                    <Typography className={classes.titleRoot2}>
                    {!details?.created_at?"-":moment(details?.created_at * 1000).format('DD/MM/YYYY')}
                    </Typography>
                    
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 5 }}>
                  <EventIcon />
                  <Box ml={5}  display="flex">
                  <Typography className={classes.titleRoot1}>Due Date : </Typography>
                    <Typography className={classes.titleRoot2}>

                    {!details?.vendor_details?"-":moment(details?.due_date * 1000).format('DD/MM/YYYY')}
                    </Typography>
                     
                  </Box>
                </Box>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 5 }}>
                  <EventIcon />
                  <Box ml={5}  display="flex">
                  <Typography className={classes.titleRoot1}>Vender Assignment Date : </Typography>
                    <Typography className={classes.titleRoot2}>
                    {!details?.vendor_assigned_date?"-":moment(details?.vendor_assigned_date * 1000).format('DD/MM/YYYY')}
                    </Typography>
                     
                  </Box>
                </Box>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 5 }}>
                  <CheckCircleOutline />
                  <Box ml={5}  display="flex">
                  <Typography className={classes.titleRoot1}>Status : </Typography>
                    <Typography className={classes.titleRoot2}>
                    {statusChange(details?.service_status)}
                    </Typography>
                     
                  </Box>
                </Box>
              </Grid>
            </GridContainer>
          </Box>
        </Box>
      </Box>

      <Divider variant="middle" />
      <Box px={6} py={5}>
        <Box mb={5}  color="common.dark" className={classes.titleRoot1}>
          Service Details :
        </Box>

        <GridContainer>
          <Grid item xs={6} sm={6}>
            <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
              <EventIcon />
              <Box ml={5}  display="flex">
              <Typography className={classes.titleRoot1}>Service Start Date-Time : </Typography>
                    <Typography className={classes.titleRoot2}>
                    {}
                    </Typography>
                 
              </Box>
            </Box>
            <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
              <EventIcon />
              <Box ml={5}  display="flex">
              <Typography className={classes.titleRoot1}>Service End Date-Time:</Typography>
                    <Typography className={classes.titleRoot2}>
                    {}
                    </Typography>
                 
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
              <LocationCityIcon />
              <Box ml={5}  display="flex">
              <Typography className={classes.titleRoot1}>Service Start Location: </Typography>
                    <Typography className={classes.titleRoot2}>
                    {}
                    </Typography>
                 
              </Box>
            </Box>
            <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 5 }}>
              <LocationCityIcon />
              <Box ml={5}  display="flex">
              <Typography className={classes.titleRoot1}>Service End Location: </Typography>
                    <Typography className={classes.titleRoot2}>
                    {}
                    </Typography>
                 
              </Box>
            </Box>
          </Grid>
        </GridContainer>
      </Box>
    </>
  );
};

export default AssignVender;
