import { fetchError, fetchStart, fetchSuccess, } from './Common';
// import axios from 'axios';
import { history } from 'redux/store';
import axios from '../../services/auth/jwt/config';
import {
  // ADD_FMPROVIDER,
  DELETE_BULK_FMPROVIDER,
  DELETE_FMPROVIDER,
  EDIT_FMPROVIDER,
  FETCH_DATA_SUCCESS,
  GET_FMPROVIDER,
  SET_FMPROVIDER_DETAILS,
} from '../../@jumbo/constants/ActionTypes';
import { addFMProvider, editFMProvider, listFMProvider, updateFMProvider,deleteFMProvider } from '../../@jumbo/constants/ApiConstatnt';
import { setAuthUser } from './Auth';

// get FM provider list
export const getFMProvider = (sortBy, sort, searchTerm, page, limit, filterOptions, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());

    axios
      .get(listFMProvider, {
        params: { sortBy: `${sortBy}:${sort}`, q: searchTerm, page: page + 1, limit: limit, status: filterOptions[0] },
      })
      .then(data => {
        
        // if(searchTerm && !data?.data?.data?.providers?.length && data?.data?.data?.page>1) {dispatch(getFMProvider(sortBy, sort, searchTerm, 0, limit, filterOptions, callbackFun))}
        if (data.status === 200) {
          // dispatch(fetchSuccess());
          dispatch({type:FETCH_DATA_SUCCESS,})
          dispatch({ type: GET_FMPROVIDER, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        console.log('error',error?.response?.data)
        if (error?.response?.data?.message === 'FM Provider not found.') {
          if (callbackFun) callbackFun();
          dispatch(fetchError());
          dispatch({ type: GET_FMPROVIDER, payload: [] });
        }else if(error?.response?.status===401 ){
          dispatch(setAuthUser(null));
          history.push('/signin')
        } 
        else {
          console.log('error',error?.response)
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

// set current user for view tab
export const setCurrentFMProvider = user => {
  return dispatch => {
    dispatch({ type: SET_FMPROVIDER_DETAILS, payload: user });
  };
};

// add new FM provider 
export const addNewFMProvider = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(addFMProvider, user)
      .then(data => {
        if (data) {

          dispatch(fetchSuccess('New FM provider added successfully.'));
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if(error?.response?.status===401 ){
          dispatch(setAuthUser(null));
          history.push('/signin')
        } else{
  
        
          dispatch(fetchError(error?.response?.data?.message));}
      });
  };
};
export const updateFMProviderData = (id,user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(editFMProvider+id, user)
      .then(data => {
        if (data) {

          dispatch(fetchSuccess('FM provider updated successfully.'));
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if(error?.response?.status===401 ){
          dispatch(setAuthUser(null));
          history.push('/signin')
        } else{
  
        
          dispatch(fetchError(error?.response?.data?.message));}
      });
  };
};

export const updateFMProviderStatus = (data, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(updateFMProvider, data)
      .then(response => {
        if (data) {
          // setTimeout(() => {
          //   dispatch(fetchSuccess('FM provider status updated successfully.'));
          // }, 2000);
          dispatch(fetchSuccess('FM provider status updated successfully.'));
          // dispatch({ type: EDIT_USER, payload: response.data });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if(error?.response?.status===401 ){
        dispatch(setAuthUser(null));
        history.push('/signin')
      } else{

      
        dispatch(fetchError(error?.response?.data?.message));}
      });
  };
};

// all function below are not in use currntly
export const deleteBulkUsers = (userIds, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/users/bulk-delete', { userIds })
      .then(response => {
        if (response.status === 200) {
          dispatch(fetchSuccess('Selected users were deleted successfully.'));
          dispatch({ type: DELETE_BULK_FMPROVIDER, payload: userIds });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const deleteUser = (userId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete(deleteFMProvider+userId)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess('FM provider deleted successfully.'));
          // dispatch({ type: DELETE_FMPROVIDER, payload: userId });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const sentMailToUser = () => {
  return dispatch => {
    dispatch(fetchSuccess('Email has been sent to user successfully'));
  };
};

export const updateUser = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/users', user)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess('Selected user was updated successfully.'));
          dispatch({ type: EDIT_FMPROVIDER, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};
