import React from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import CmtAvatar from '../../../../@coremat/CmtAvatar';
import Typography from '@material-ui/core/Typography';

import { useSelector } from 'react-redux';

import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';

import useStyles from './index.style';
import { Block, CheckCircleOutline } from '@material-ui/icons';

import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import BuildIcon from '@material-ui/icons/Build';
const UserDetailView = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  const { currentUser } = useSelector(({ SERVICEACTIVITIES }) => SERVICEACTIVITIES);
  const { name, email, status, service_type_details ,profile_pic} = currentUser;

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <Box className={classes.userInfoRoot}>
        <Box mr={3} display="flex" alignItems="center">
        <Box
            className={classes.avatarView}
            mr={{ xs: 4, md: 6 }}
            style={{
              fontSize: 14,
              letterSpacing: 0.25,
              textOverflow: 'ellipsis',
              maxWidth: '350px',
              display: 'block',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}>
            <CmtAvatar size={70} src={profile_pic} alt={name} />
          </Box>
          <Box mt={-2}>
            <Box display="flex" alignItems="center">
              <Typography
                className={classes.titleRoot}
                style={{
                  fontSize: 14,
                  letterSpacing: 0.25,

                  textOverflow: 'ellipsis',
                  maxWidth: '350px',
                  display: 'block',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}>
                {name?.toUpperCase()}
              </Typography>
              <Box ml={1}></Box>
            </Box>
          </Box>
        </Box>
        <Box ml="auto" mt={-2} display="flex" alignItems="center">
          <Box ml={1}></Box>
          <Box ml={1}>
            <IconButton onClick={onCloseDialog}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box px={6} py={5}>
        <Box mb={5} component="p" color="common.dark" className={classes.titleRoot1}>
          Service Activity Detail
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <LocalActivityIcon />
          <Box
            ml={5}
            component="p"
            // style={{
            //   fontSize: 14,
            //   letterSpacing: 0.25,
            //   textOverflow: 'ellipsis',
            //   maxWidth: '350px',
            //   display: 'block',
            //   overflow: 'hidden',
            //   whiteSpace: 'nowrap',
            // }}
            display="flex"
            >
              <Typography className={classes.titleRoot1}>Name : </Typography>
            <Typography className={classes.titleRoot2}>{name}</Typography>
            
          </Box>{' '}
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <BuildIcon />
          <Box
            ml={5}
            color="primary"
            component="p"
            className="pointer"
            // style={{
            //   fontSize: 14,
            //   letterSpacing: 0.25,
            //   textOverflow: 'ellipsis',
            //   maxWidth: '350px',
            //   display: 'block',
            //   overflow: 'hidden',
            //   whiteSpace: 'nowrap',
            // }}
            display="flex"
            >
              <Typography className={classes.titleRoot1}>Service Type :</Typography>
            <Typography className={classes.titleRoot2}>{service_type_details?.name}</Typography>

             
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 5 }}>
          {status === 1 ? <CheckCircleOutline /> : <Block />}
          <Box ml={5} display="flex">
          <Typography className={classes.titleRoot1}>Status :</Typography>
            <Typography className={classes.titleRoot2}>{status === 1 ? 'Active' : 'Inactive'}</Typography>
             </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default UserDetailView;

UserDetailView.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
