import React, { useEffect, useState } from 'react';
import { Paper, Table, TableCell, TableContainer, TableRow } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import UserListRow from './UserListRow';
import UserTableHead from './UserTableHead';
import UserTableToolbar from './UserTableToolbar';
import { useDispatch, useSelector } from 'react-redux';
import AddEditUser from './AddEditUser';
import ConfirmDialog from '@jumbo/components/Common/ConfirmDialog';
import { useDebounce } from '@jumbo/utils/commonHelper';
import useStyles from './index.style';
import UserDetailView from './UserDetailView';
import NoRecordFound from './NoRecordFound';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { getLocationList, setClassCategoryList, getLocationDetails, deleteClassCategory } from 'redux/actions/BulkLocation';
import { getSystemCategorieList } from 'redux/actions/SystemCategorie';
import { getSampleFile } from 'redux/actions/SampaleFileDownload';
import { getFMProvider } from 'redux/actions/FMProviderManagement';
import ImportExportSelection from './ImportExportSelection';
import ErrorTable from './ErrorTable';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import Box from '@material-ui/core/Box';
import ErrorIcon from '@material-ui/icons/Error';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Divider from '@material-ui/core/Divider';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { truncateWithEllipsis } from '@amcharts/amcharts4/.internal/core/utils/Utils';


// const breadcrumbs = [
//   { label: 'Home', link: '/' },

//   { label: 'Location Bulk Import', isActive: true },
// ];

const UsersModule = ({socket}) => {
  const classes = useStyles();
  const { users,errorData } = useSelector(({ BULKLOCATION }) => BULKLOCATION);
  // const { errorData } = useSelector(({ BULKLOCATION }) => BULKLOCATION);
  const [orderBy, setOrderBy] = React.useState('created_at');
  const [order, setOrder] = React.useState('desc');
  const [classCategory, setClassCategory] = React.useState(2);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [openWareHouseDialog, setOpenWareHouseDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState({ name: '' });
  const [usersFetched, setUsersFetched] = useState(false);
  const [isFilterApplied, setFilterApplied] = useState(false);
  const [filterOptions, setFilterOptions] = React.useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchedData, setSearchedData] = useState('debouncedSearchPagevalidation');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [importExportSelectDialogue, setImportExportSelectDialogue] = useState(false);
  const [errroDialog, setErrroDialog] = React.useState(false);
  const [addButton, setAddButton] = React.useState(false);
  const [onlyRowData, setOnlyRowData] = useState(1)
  const [nullData, setNullData] = useState(0)
  const [liveData, setLiveData]=useState(null)
  const Location = 1;
  const dispatch = useDispatch();
  // let nullData;
  // console.log("dialog",errroDialog)

  useEffect(() => {
    socket.on('locationBulkImportLiveUpdate', (data) => {
      console.log('socket message',data)
      setLiveData(data)
    });
    return () => {
      socket.off('locationBulkImportLiveUpdate');
    };
  }, [])

  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm !== searchedData) {
      dispatch(
        getLocationList(orderBy, order, debouncedSearchTerm, 0, rowsPerPage, filterOptions, Location, () => {
          setFilterApplied(!!debouncedSearchTerm);
          setUsersFetched(true);
        }),
      );
      setSearchedData(debouncedSearchTerm);
      setPage(0);
    } else {
      dispatch(
        getLocationList(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions, Location, () => {
          setFilterApplied(!!debouncedSearchTerm);
          setUsersFetched(true);
        }),
      );
    }
    dispatch(getSampleFile(2));
    dispatch(getFMProvider('name', 'asc', '', 0, -1, [1]));
  }, [dispatch, filterOptions, debouncedSearchTerm, orderBy, order, page, rowsPerPage, Location]);

  const handleCloseUserDialog = () => {
    setOpenUserDialog(false);
    dispatch(setClassCategoryList(null));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelected = users.map(n => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleRowClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleUserView = user => {
    dispatch(getLocationDetails(user?._id, 0, viewDialog));
    dispatch(getLocationDetails(user?._id, onlyRowData));
    // dispatch(getLocationDetails(user?._id, viewDialog));
    // dispatch(getLocationDetails(user?._id, onlyRowData));
  };

  const viewDialog = () => {
    setOpenViewDialog(true);
  };

  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
    // dispatch(setClassCategoryList(null));
  };

  const handleUserEdit = user => {
    dispatch(setClassCategoryList(user));
    setOpenUserDialog(true);
  };

  const handleUserDelete = user => {
    setSelectedUser(user);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);

    dispatch(
      deleteClassCategory(selectedUser._id, () => {
        dispatch(getLocationList(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
      }),
    );
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };
  const handleCloseImportExportSelectDialogue = () => {
    setImportExportSelectDialogue(false);
    // dispatch(setClassCategoryList(null));
  };

  const isSelected = id => selected.indexOf(id) !== -1;

  const handelClose = () => {
    setErrroDialog(false);
    setAddButton(false)
    // onCloseDialog();
    handleCloseUserDialog()
  };

  return (
    <PageContainer >
      {/* <h1>data{liveData}</h1> */}
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <UserTableToolbar
            selected={selected}
            setSelected={setSelected}
            onUserAdd={setOpenUserDialog}
            filterOptions={filterOptions}
            setFilterOptions={setFilterOptions}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            setPage={setPage}
            setSearchedData={setSearchedData}
            importExportSelect={setImportExportSelectDialogue}
            liveData={liveData}
          />
          <TableContainer className={classes.container}>
            <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" aria-label="sticky enhanced table">
              <UserTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={users?.total ?? 1}
              />
              <TableBody>
                {!!users?.data?.length ? (
                  users?.data?.slice(0 * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                    <UserListRow
                      indexId={page * rowsPerPage + index}
                      key={index}
                      row={row}
                      onRowClick={handleRowClick}
                      onUserEdit={handleUserEdit}
                      onUserDelete={handleUserDelete}
                      onUserView={handleUserView}
                      isSelected={isSelected}
                      getSERVICETYPEParams={{
                        orderBy,
                        order,
                        debouncedSearchTerm,
                        page,
                        rowsPerPage,
                        filterOptions,
                        Location,
                      }}
                    />
                  ))
                ) : (
                  <TableRow style={{ height: 53 * 6 }}>
                    {/* <TableCell colSpan={7} rowSpan={10}>
                      {isFilterApplied ? (
                        <NoRecordFound>There are no records found with your filter.</NoRecordFound>
                      ) : (
                        <NoRecordFound>{'There are no records found.'}</NoRecordFound>
                      )}
                    </TableCell> */}
                    <TableCell colSpan={7} rowSpan={10}>
                      {isFilterApplied ? (
                        <NoRecordFound>There are no records found.</NoRecordFound>
                      ) : (
                        <NoRecordFound>{usersFetched ? 'There are no records found.' : 'Loading data...'}</NoRecordFound>
                      )}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={users?.total ?? 1}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </Paper>

        {openUserDialog && (
          <AddEditUser
            open={openUserDialog}
            onCloseDialog={handleCloseUserDialog}
            setOpenWareHouseDialog={setOpenWareHouseDialog}
            setErrroDialog={setErrroDialog}
            errroDialog={errroDialog}
            handelClose={handelClose}
            addButton={addButton}
            setAddButton={setAddButton}
            liveData={liveData}
            getSystemCtegorieParams={{
              orderBy,
              order,
              debouncedSearchTerm,
              page,
              rowsPerPage,
              filterOptions,
              Location,
            }}
          />
        )}

{importExportSelectDialogue && (
          <ImportExportSelection
            open={importExportSelectDialogue}
            onCloseDialog={handleCloseImportExportSelectDialogue}
          />
        )}
        {/* {openWareHouseDialog && <AddToWarehouse open={openWareHouseDialog}  />} */}
        {openViewDialog && <UserDetailView open={openViewDialog} onCloseDialog={handleCloseViewDialog} />}

        {errroDialog?
          <Dialog
          className={classes.paper}
          open={errroDialog}
          onClose={handelClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-description">Error Details</DialogTitle>
          <Divider />
          <DialogContent>
            <Box className={classes.userInfoRoot}>
              <Box px={6} py={5}>

              <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
                  <KeyboardArrowRightIcon />
                  <Box ml={5} display="flex">
                    <Typography className={classes.titleRoot1}>Total Rows Processed : </Typography>
                    <Typography className={classes.titleRoot2}>{errorData?.data?.total_rows_processed}</Typography>
                  </Box>
                </Box>
                
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
                  <ImportExportIcon />
                  <Box ml={5} display="flex">
                    <Typography className={classes.titleRoot1}>Total Rows Imported : </Typography>
                    <Typography className={classes.titleRoot2}>{errorData?.data?.total_rows_imported}</Typography>
                  </Box>
                </Box>

                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 5 }}>
                  <ErrorIcon />
                  <Box ml={5} display="flex">
                    <Typography className={classes.titleRoot1}>Total Rows With Errors:</Typography>
                    <Typography className={classes.titleRoot2}>{errorData?.data?.total_rows_with_errors}</Typography>
                  </Box>
                </Box>
              </Box>
              </Box>
              {
                errorData?.data?.rows_with_errors.length > 0 ?<ErrorTable />:null
              }
              
          </DialogContent>
          <DialogActions>
            <Button onClick={handelClose} autoFocus variant="outlined">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>:null
        }

        <ConfirmDialog
          open={openConfirmDialog}
          title={`Delete Class Category?`}
          content={'You will not be able to recover records.'}
          btnLabels={{
            cancel: 'Cancel',
            confirm: 'Delete',
          }}
          onClose={handleCancelDelete}
          onConfirm={handleConfirmDelete}
        />
      </div>
    </PageContainer>
  );
};

export default UsersModule;
