import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import GridContainer from '../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { fetchVirsionData, pushVirsionData } from '../../../redux/actions/Setting';
import CmtCard from '../../../@coremat/CmtCard';
import CmtCardHeader from '../../../@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '../../../@coremat/CmtCard/CmtCardContent';
import { alpha, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { requiredMessage } from '../../../@jumbo/constants/ErrorMessages';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import AppTextInput from '../../../@jumbo/components/Common/formElements/AppTextInput';
import Typography from '@material-ui/core/Typography';
import PerfectScrollbar from 'react-perfect-scrollbar';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import moment from 'moment';
//import TableItem from './TableItem';
import Table from '@material-ui/core/Table';

const variant = 'default';
const useStyles = makeStyles(theme => ({
  root: {
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
    "& .MuiFormLabel-root": {
      color: '#909090'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink':{
      color: theme.palette.text.primary
    },
    cardContentRoot: {
      padding: '0 !important',
    },

    scrollbarRoot: {
      height: 340,
    },
    badgeRoot: {
      color: theme.palette.common.white,
      borderRadius: 30,
      fontSize: 12,
      padding: '2px 10px',
      display: 'inline-block',
    },
    authThumb: {
      backgroundColor: alpha(theme.palette.primary.main, 0.12),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 20,
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '50%',
        order: 2,
      },
    },
    authContent: {
      padding: 30,
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: props => (props.variant === 'default' ? '50%' : '100%'),
        order: 1,
      },
      [theme.breakpoints.up('xl')]: {
        padding: 50,
      },
    },
    titleVersion: {
      // marginBottom: 14,
      letterSpacing: 0.15,
      marginRight: 24,
      color: theme.palette.text.primary,
    },
    headerTitle: {
      fontSize: 20,
      fontStyle: 'bold',
    },
    textFieldRoot: {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: alpha(theme.palette.common.dark, 0.12),
      },
    },
    formcontrolLabelRoot: {
      '& .MuiFormControlLabel-label': {
        [theme.breakpoints.down('xs')]: {
          fontSize: 12,
        },
      },
    },
    noRecord: {
      textAlign: 'center',
      height: '250px',
      fontSize: 16,
      color: theme.palette.text.disabled,
      fontWeight: theme.typography.fontWeightBold,
      [theme.breakpoints.up('lg')]: {
        fontSize: 20,
      },
      border: 'none',
    },
    tableCellRoot: {
      textAlign: 'center',
      
    },
  },
  tableHead: {
    '& .MuiTableCell-head': {
      fontWeight: 600,
    },
  },
  tableRowRoot:{
    
    '& .MuiTableCell-root':{
      fontWeight: 300,
    }
  }
}));

const breadcrumbs = [
  { label: 'Home', link: '/' },
  { label: 'Settings', isActive: true },
];
const Settings = () => {
  const classes = useStyles({ variant });
  const [serviceTypeValue, setServiceTypeValue] = useState('');
  const { VirsionList } = useSelector(({ SETTING }) => SETTING);
  const [deviceType, setDeviceType] = useState('1');
  const [forceUpdate, setForceUpdate] = useState('1');
  const [applicationType, setApplicationType] = useState('1');
  const [serviceTypeValueError, setServiceTypeValueError] = useState('');
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchVirsionData());
  }, []);

  const handleSubmit = () => {
    var rgx = /^\d+\.\d+\.\d+$/;
    if (!serviceTypeValue) {
      setServiceTypeValueError(requiredMessage);
    } else if (!serviceTypeValue.match(rgx)) {
      setServiceTypeValueError('Enter virsion number in 0.0.0 formate.');
    } else {
      const data = {
        version_number: serviceTypeValue,
        device_type: deviceType,
        is_force_update: forceUpdate,
        user_type: applicationType,
      };
      dispatch(pushVirsionData(data));
      setTimeout(() => {
        setServiceTypeValue('');
      }, 2000);
    }
  };
  console.log('Version List :: ', VirsionList);
  return (
    <>
      <PageContainer heading="Setting" breadcrumbs={breadcrumbs}>
        <GridContainer>
          <Grid item xs={12} lg={12} sm={12} className={classes.profileSidebar}>
            <CmtCard alignItems="center">
              <Box mt={5} ml={10}>
                <Typography className={classes.headerTitle}>Device Update</Typography>
              </Box>
              <Box mt={10} ml={15} display="flex" alignItems="center">
                <Typography className={classes.titleVersion} mr={5}>
                  Version Number :
                </Typography>
                <AppTextInput
                  className={classes.root}
                  maxLength="5"
                  fullWidth={false}
                  variant="outlined"
                  label="Ex: 0.0.1"
                  value={serviceTypeValue}
                  onChange={e => {
                    setServiceTypeValue(e.target.value);
                    setServiceTypeValueError('');
                  }}
                  helperText={serviceTypeValueError}
                />
              </Box>

              <Box mt={5} ml={15} display="flex" alignItems="center">
                <Typography className={classes.titleVersion}>Device Type :</Typography>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    onChange={e => {
                      setDeviceType(e.target.value);
                    }}
                    name="Device Type"
                    value={deviceType}>
                    <FormControlLabel value="1" control={<Radio color="primary" />} label="Android" />
                    <FormControlLabel value="2" control={<Radio color="primary" />} label="IOS" />
                  </RadioGroup>
                </FormControl>
              </Box>

              <Box mt={5} ml={15} display="flex" alignItems="center">
                <Typography className={classes.titleVersion}>Update Type :</Typography>

                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    onChange={e => {
                      setForceUpdate(e.target.value);
                    }}
                    name="Force Update"
                    value={forceUpdate}>
                    <FormControlLabel value="1" control={<Radio color="primary" />} label="Forced" />
                    <FormControlLabel value="0" control={<Radio color="primary" />} label="Optional" />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box mt={5} ml={15} display="flex" alignItems="center">
                <Typography className={classes.titleVersion}>Application Type :</Typography>

                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    onChange={e => {
                      setApplicationType(e.target.value);
                    }}
                    name="Force Update"
                    value={applicationType}>
                    <FormControlLabel value="1" control={<Radio color="primary" />} label="Tenant" />
                    <FormControlLabel value="2" control={<Radio color="primary" />} label="Vendor" />
                  </RadioGroup>
                </FormControl>
              </Box>

              <Box ml={15} mb={10} display="flex" alignItems="center" justifyContent="space-between" mt={5}>
                <Button variant="contained" onClick={handleSubmit} color="primary">
                  SAVE
                </Button>
              </Box>
            </CmtCard>
          </Grid>
          <Grid item xs={12} lg={12} sm={12} className={classes.profileSidebar}>
            <CmtCard>
              <Box mt={5} ml={10} mb={5}>
                <Typography className={classes.headerTitle}>Version List</Typography>
              </Box>
              <CmtCardContent className={classes.cardContentRoot}>
                <PerfectScrollbar className={classes.scrollbarRoot}>
                  <Box>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow className={classes.tableHead}>
                          <TableCell className={classes.tableCellRoot}>Device Type</TableCell>
                          <TableCell className={classes.tableCellRoot}>Version Number</TableCell>
                          <TableCell className={classes.tableCellRoot}>Application Type</TableCell>
                          <TableCell className={classes.tableCellRoot}>Date & Time</TableCell>
                          <TableCell className={classes.tableCellRoot}>Update Type</TableCell>
                          {/* <TableCell className={classes.tableCellRoot}>S</TableCell>
                            <TableCell className={classes.tableCellRoot} /> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {VirsionList?.versions?.length ? (
                          VirsionList?.versions?.map((row, index) => (
                            <TableRow className={classes.tableRowRoot}>
                              <TableCell className={classes.tableCellRoot}>
                                {row?.device_type === 1 ? 'ANDROID' : 'IOS'}
                              </TableCell>
                              <TableCell className={classes.tableCellRoot}>{row?.version_number}</TableCell>
                              <TableCell className={classes.tableCellRoot}>
                                {row?.user_type === 1 ? 'Tenant' : 'Vendor'}
                              </TableCell>
                              <TableCell className={classes.tableCellRoot}>
                                {moment(row?.created_at * 1000).format('DD MMM YYYY hh:mm A')}
                              </TableCell>
                              <TableCell className={classes.tableCellRoot}>
                                {row?.is_force_update == 1 ? 'Forced Update' : 'Optional Update'}
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={7} rowSpan={10} className={classes.noRecord}>
                              There are no records found.
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </Box>
                </PerfectScrollbar>
              </CmtCardContent>
            </CmtCard>
          </Grid>
        </GridContainer>
      </PageContainer>
    </>
  );
};

export default Settings;
