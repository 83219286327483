import {
  GET_LOCATIONLIST,
  SET_CLASSCATEGORY_DETAILS,
  GET_LOCATIONDETAILS,
  GET_LOCATIONDETAILS_TABLE,
  GET_ERRORLISTLOCATION
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  users: [],
  currentUser: null,
  details:null,
  details_table:null,
  ManufacturerList:null,
  wareHouse:null,
  errorData:null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LOCATIONLIST: {
      return {
        ...state,
        users: action.payload,
      };
    }

    case GET_ERRORLISTLOCATION: {
      return {
        ...state,
        errorData: action.payload,
      };
    }

    case GET_LOCATIONDETAILS: {
      return {
        ...state,
        details: action.payload,
      };
    }

    case GET_LOCATIONDETAILS_TABLE: {
      return {
        ...state,
        details_table: action.payload,
      };
    }

    case SET_CLASSCATEGORY_DETAILS: {
      return {
        ...state,
        currentUser: action.payload,
      };
    }
    
    default:
      return state;
  }
};
