import axios from '../../services/auth/jwt/config';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import { FETCH_DATA_SUCCESS, GET_VIRSION } from '../../@jumbo/constants/ActionTypes';
import { pushVirsionList, virsionList} from '../../@jumbo/constants/ApiConstatnt';
import {history} from 'redux/store'
import { setAuthUser } from 'redux/actions/Auth';
export const fetchVirsionData = () => {
  return dispatch => {
    dispatch(fetchStart());
    axios.get(virsionList, {params:{sortBy:'created_at:desc'}}).then(({ data }) => {
      dispatch({type:FETCH_DATA_SUCCESS,})
      dispatch({
        type: GET_VIRSION,
        payload: data.data,
      });
    }).catch(error => {
      if (error?.response?.status === 401) {
        dispatch(setAuthUser(null));
        history.push('/signin');
      } else {
        dispatch(fetchError(error?.response?.data?.message));
      }
    });
  };
};
export const pushVirsionData = (data) => {
  return dispatch => {
    dispatch(fetchStart());
    axios.post(pushVirsionList,data).then(({ data }) => {
      dispatch(fetchSuccess('New version added successfully.'));
      dispatch(fetchVirsionData());
    })  .catch(error => {
      
      if (error?.response?.status === 401) {
        dispatch(setAuthUser(null));
        history.push('/signin');
      } else {
        dispatch(fetchError(error?.response?.data?.message));
      }
      }
    );
  };
};
