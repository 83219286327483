export const Activate = 'Activate'
export const Deactivate ='Deactivate'
export const Active = 'Active'
export const Inactive='Inactive'

// user role string
export const userRole ={
    fmAdmin:'FM Admin',
subFMAdmin:'FM Sub-Admin',
accountUser:'Account User',
callCenterUser:'Call Center User'
}