import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AppSelectBox from '../../../../@jumbo/components/Common/formElements/AppSelectBox';
import { requiredMessage } from '../../../../@jumbo/constants/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import {
  addNewServiceRequestData,
  getServiceRequest,
  getServiceRequsetDetails,
  setEdittServiceRequset,
} from '../../../../redux/actions/ServiceRequest';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Priority } from '../../../../@jumbo/constants/CustomizeSetting';
import moment from 'moment';
import { setAuthUser } from 'redux/actions/Auth';
import axios from 'services/auth/jwt/config';
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import { history } from 'redux/store';
import UserSelectBox from './UserSelectBox';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },

  helperText: {
    marginLeft: '5px',
    '& .MuiFormHelperText-root': {
      color: 'red',
      marginLeft: '10px',
    },
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 200,
  },
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
}));

const AddEditUser = ({ open, onCloseDialog, getSERVICETYPEParams }) => {
  const classes = useStyles();
  const { currentUser, addUser, checkQuotationList } = useSelector(({ SERVICEREQUEST }) => SERVICEREQUEST);
  const SERVICEREQUEST = useSelector(({ SERVICEREQUEST }) => SERVICEREQUEST.users);
  const { users } = useSelector(({ VENDORMANAGEMENT }) => VENDORMANAGEMENT);
  const { orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptionsStatus } = getSERVICETYPEParams;
  const [vendor, setVender] = useState('');
  const [contractor, setContractor] = useState('');
  const [contractorData, setContractorData] = useState('');
  const [priority, setPriority] = useState('');
  const [dueDate, setDueDate] = useState(new Date());
  const [venderError, setVenderError] = useState('');
  const [contractorError, setContractorError] = useState('');
  const [priorityError, setPriorityError] = useState('');
  const [dueDateError, setDueDateError] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (addUser) {
      setPriority(addUser?.priority);
      setDueDate(moment(addUser?.due_date * 1000).format());
      setVender(addUser?.vendor_id);
      setContractor(addUser?.contractor_id);
    }
    if (checkQuotationList) {
      setVender(checkQuotationList?.vendor_id?._id);
      setPriority('');
      setContractor('');
    }
  }, [addUser, checkQuotationList]);

  const onSubmitClick = () => {
    if (!vendor) {
      setVenderError(requiredMessage);
    }
    if (!priority) {
      setPriorityError(requiredMessage);
    }
    if (!dueDate) {
      setDueDateError(requiredMessage);
    }

    if (moment(dueDate).format('x') < moment().format('x')) {
      setDueDateError("Please select valid date, you can't select today's date or past date.");
    }
    if (vendor && priority && dueDate && moment(dueDate).format('x') >= moment().format('x')) {
      onUserSave();
    }
  };

  const handleDateChange = date => {
    setDueDate(date);
    setDueDateError('');
  };

  async function fetchData() {
    let response = await axios('fm-provider/contractor/list', {
      params: { vendor_id: vendor, approved_status: 2, sortBy: 'first_name:asc', search: '', page: 1, limit: -1, status: 1 },
    }).catch(error => {
      if (error?.response?.status === 401) {
        dispatch(setAuthUser(null));
        history.push('/signin');
      } else {
        dispatch(fetchError(error?.response?.data?.message));
      }
    });
    let user = await response?.data;
    setContractorData(response?.data?.data);

    dispatch(fetchSuccess());
  }

  useEffect(() => {
    dispatch(fetchStart());
    fetchData();
  }, [vendor]);

  const onUserSave = () => {
    const userDetail = {
      priority: priority,
      due_date: Number(moment(dueDate).format('X')),
    };
  
    if (addUser) {
      dispatch(
        addNewServiceRequestData(
          addUser?._id,
          vendor,
          contractor,
          userDetail,
          () => {
            onCloseDialog();
            dispatch(getServiceRequsetDetails(addUser?._id));
            dispatch(getServiceRequest(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptionsStatus));
          },
          true,
        ),
      );
    } else if (checkQuotationList) {
      dispatch(
        addNewServiceRequestData(checkQuotationList?.requestId, vendor, contractor, userDetail, () => {
          onCloseDialog();
          dispatch(getServiceRequsetDetails(checkQuotationList?._id));
          dispatch(getServiceRequest(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptionsStatus));
        }),
      );
    } else {
      dispatch(
        addNewServiceRequestData(currentUser?._id, vendor, contractor, userDetail, () => {
          onCloseDialog();
          dispatch(getServiceRequsetDetails(currentUser?._id));
          dispatch(getServiceRequest(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptionsStatus));
        }),
      );
    }
  };

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>
        {checkQuotationList ? 'Assign Vendor' : addUser ? 'Change Assign vendor' : 'Assign Vendor'}
      </DialogTitle>
      <DialogContent dividers>
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
          <GridContainer>
            <Grid item xs={12} sm={12}>
              <UserSelectBox
                fullWidth
                data={users?.data}
                label="Vendor"
                valueKey="_id"
                variant="outlined"
                labelKey="first_name"
                value={vendor}
                disabled={checkQuotationList?.vendor_id?._id ? true : false}
                onChange={e => {
                  setVender(e.target.value);
                  setContractor('');
                  setVenderError('');
                }}
                helperText={venderError}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <UserSelectBox
                fullWidth
                data={contractorData?.data}
                label="Contractor"
                valueKey="_id"
                variant="outlined"
                labelKey="first_name"
                value={contractor}
                onChange={e => {
                  setContractor(e.target.value);
                  setContractorError('');
                }}
                helperText={contractorError}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <AppSelectBox
                fullWidth
                data={Priority}
                label="Priority"
                valueKey="priority"
                variant="outlined"
                labelKey="priority"
                value={priority}
                onChange={e => {
                  setPriority(e.target.value);
                  setPriorityError('');
                }}
                helperText={priorityError}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="Due Date"
                format="DD/MM/YYYY"
                value={dueDate}
                minDate={new Date()}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                className={classes.helperText}
                helperText={dueDateError}
              />
            </Grid>
          </GridContainer>
        </Box>

        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button onClick={onCloseDialog} variant="outlined">
            Cancel
          </Button>
          <Box ml={2}>
            <Button variant="contained" color="primary" style={{ minWidth: '100px' }} onClick={onSubmitClick}>
              Assign
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditUser;

AddEditUser.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
 