import React, { useEffect } from 'react';
import GridContainer from '../../../@jumbo/components/GridContainer';
import { Grid } from '@material-ui/core';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import StatisticsClassicCard from '../../../@jumbo/components/Common/StatisticsClassicCard';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDashboardData } from '../../../redux/actions/Dashboard';
import { StatisticsCard } from '../../../@jumbo/components/Common';
import { makeStyles } from '@material-ui/core';
import CardGraph from './CardGraph';
import VisitsGraph from './VisitsGraph';
import CitiesGraph from './CitiesGraph';
import QueriesGraph from './QueriesGraph';

const useStyles = makeStyles(theme => ({
  cardRoot: {
    color: theme.palette.common.white,
    // fontSize: 22,
    fontWeight: 400,
    '& .MuiTypography-subtitle1':{
      fontSize: 22,
    }
  },
  titleRoot: {
    fontSize: 24,
    fontWeight: 400,
    textTransform: 'uppercase',
    letterSpacing: 0.4,
  },
}));
const Home = () => {
  const { dashboardData } = useSelector(({ dashboard }) => dashboard);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchDashboardData());
  }, []);
  

  const history = useHistory();

  const classes = useStyles();
  const fmProviders = () => {
    history.push('/fmprovidermanagement');
  };
  return (
    <PageContainer heading="Dashboard">
      <GridContainer>
        <Grid item xs={12} sm={6} md={3}>
          <span onClick={fmProviders} style={{ cursor: 'pointer' }}>
            {/* <StatisticsClassicCard
              backgroundColor={['#fff -1.96%', '#fff 108.17%']}
              gradientDirection="180deg"
              color={'#ff8000'}
              title={dashboardData?.totalFMProvider}
              subTitle={'Total FM Providers'}
              //growth={1.5}
            /> */}
            <StatisticsCard
              className={classes.cardRoot}
              backgroundColor="#7D38DF"
              title={dashboardData?.totalFMProvider}
              titleProps={{
                variant: 'inherit',
                component: 'h4',
                className: classes.titleRoot,
              }}
              description="Total FM Providers">
              <CardGraph />
            </StatisticsCard>
          </span>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          {/* <StatisticsClassicCard
            backgroundColor={['#fff -1.96%', '#fff 108.17%']}
            gradientDirection="180deg"
            color={'#3B1A45'}
            title={dashboardData?.totalServiceRequestsFulfilled}
            subTitle={'Total Service Requests Fulfilled'}
            //growth={1.5}
            //loss={2}
          /> */}
          <span onClick={fmProviders} style={{ cursor: 'pointer' }}>
            <StatisticsCard
              className={classes.cardRoot}
              backgroundColor="#3B9FE2"
              title={dashboardData?.totalServiceRequestsFulfilled === 0 ? '0' : dashboardData?.totalServiceRequestsFulfilled}
              titleProps={{
                variant: 'inherit',
                component: 'h4',
                className: classes.titleRoot,
              }}
              description="Total Service Requests Fulfilled">
              <CitiesGraph />
            </StatisticsCard>
          </span>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          {/* <StatisticsClassicCard
            backgroundColor={['#fff -1.96%', '#fff 108.17%']}
            gradientDirection="180deg"
            color={'#ff8000'}
            title={dashboardData?.totalPropertiesManaged}
            subTitle={'Total Properties Managed'}
            //growth={1.5}
          /> */}
          <StatisticsCard
            className={classes.cardRoot}
            backgroundColor="#BE4ED0"
            title={dashboardData?.totalPropertiesManaged === 0 ? '0' : dashboardData?.totalPropertiesManaged}
            titleProps={{
              variant: 'inherit',
              component: 'h4',
              className: classes.titleRoot,
            }}
            description="Total Job Locations Managed">
            <VisitsGraph />
          </StatisticsCard>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          {/* <StatisticsClassicCard
            backgroundColor={['#fff -1.96%', '#fff 108.17%']}
            gradientDirection="180deg"
            color={'#3B1A45'}
            title={dashboardData?.totalAmountOfServicesPerformed}
            subTitle={'Total Amount of Services Performed'}
            //growth={1.5}
          /> */}
          <StatisticsCard
            className={classes.cardRoot}
            backgroundColor="#E86C63"
            title={dashboardData?.totalAmountOfServicesPerformed === 0 ? '0' : dashboardData?.totalAmountOfServicesPerformed}
            titleProps={{
              variant: 'inherit',
              component: 'h4',
              className: classes.titleRoot,
            }}
            description="Total Amount of Services Performed">
            <QueriesGraph />
          </StatisticsCard>
        </Grid>
      </GridContainer>
    </PageContainer>
  );
};

export default Home;
