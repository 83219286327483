import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
const useStyles = makeStyles({
  table: {
    minWidth: 650,
    '& .MuiTableCell-head': {
      fontWeight: '600',
    },
  },
  tableRow: {
    fontWeight: '300',
  },
  tableRow1: {
    color: 'white',
    backgroundColor: 'red',
  },
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    textOverflow: 'ellipsis',
    maxWidth: '250px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  descriptionField: {
    fontSize: 14,
    fontWeight: '300',
    maxHeight: "80px",
    textOverflow: 'ellipsis',
    // maxWidth: "50px",
    overflow: 'auto',
    wordBreak: 'break-all',
    // backgroundColor: '#f4f4f7',
    // color: '#3B1A45',
    minWidth: '200px',
  },
  errorDescriptionField: {
    color: 'white',
    backgroundColor: 'red',
    fontSize: 14,
    fontWeight: '300',
    maxHeight: "80px",
    textOverflow: 'ellipsis',
    overflow: 'auto',
    wordBreak: 'break-all',
    minWidth: '200px',
  },
  errorTableHeadOther: {
    fontSize: 16,
    fontWeight: '600',
    backgroundColor: '#f4f4f7',
    color: '#3B1A45',
  },
});

export default function ErrorTable() {
  const classes = useStyles();
  const { details } = useSelector(({ BULKLOCATION }) => BULKLOCATION);
  const { details_table } = useSelector(({ BULKLOCATION }) => BULKLOCATION);
  const { error_details, rows_with_errors } = details_table;
  const [indexValue, setindexValue] = useState();

  useEffect(() => {
    const ErrorValue = [];
    const arr = rows?.map((item, index) => {
      const err = error_details?.forEach(error => {
        if (index == error.column) {
          return ErrorValue.push({ id: item, row: error.row, msg: error.message });
        }
      });
      // console.log('map', ErrorValue);
      setindexValue(ErrorValue);
    });
  }, []);

  const rows = [
    '',
    'type',
    'name',
    'room_number',
    'floor_number',
    'street_number',
    'street_name',
    'suburb',
    'city',
    'state',
    'post_code',
    'Latitude,Longitude',
    'site_type',
    'service_types_name',
    'client_name',
  ];

  const rowsChangeName = [
    '',
    'Row No.',
    'Type',
    'Name',
    'Room Number',
    'Floor Number',
    'Street Number',
    'Street Name',
    'Suburb',
    'City',
    'State',
    'Post Code',
    'Latitude, Longitude',
    'Site Type',
    'Service Types Name',
    'Client Name',
    '',
  ];

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table" id="table">
        <TableHead>
          <TableRow>
            {/* {rows?.map((item, index) => {
              return (
                <> */}
                                {rowsChangeName?.map((data, index) =>{
                  return (
                  <TableCell className={classes.errorTableHeadOther}>{data}</TableCell>
                  );
                })}
            {/* //     </>
            //   );
            // })} */}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows_with_errors?.map((row, index) => {
            return (
              <>
                <TableRow className={classes.tableRow}>
                  <TableCell></TableCell>
                  <TableCell component="th" scope="row" onClick={({ target }) => console.log(target.textContent)}>
                      <Typography
                        className={
                          indexValue?.find(item => item.id == 'row' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {row?.row == 'FieldEmpty' ? '-' : row?.row}{' '}
                      </Typography>
                    </TableCell>
                  <Tooltip
                    title={
                      indexValue?.find(item => item.id == 'type' && item.row == row.row)
                        ? indexValue?.find(item => item.id == 'type' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell component="th" scope="row" onClick={({ target }) => console.log(target.textContent)}>
                      <Typography
                        className={
                          indexValue?.find(item => item.id == 'type' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {row?.type == 'FieldEmpty' ? '-' : row?.type}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue?.find(item => item.id == 'name' && item.row == row.row)
                        ? indexValue?.find(item => item.id == 'name' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue?.find(item => item.id == 'name' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {row?.name == 'FieldEmpty' ? '-' : row?.name}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue?.find(item => item.id == 'room_number' && item.row == row.row)
                        ? indexValue?.find(item => item.id == 'room_number' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue?.find(item => item.id == 'room_number' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {row?.room_number == 'FieldEmpty' ? '-' : row?.room_number}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue?.find(item => item.id == 'floor_number' && item.row == row.row)
                        ? indexValue?.find(item => item.id == 'floor_number' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue?.find(item => item.id == 'floor_number' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {row?.floor_number == 'FieldEmpty' ? '-' : row?.floor_number}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue?.find(item => item.id == 'street_number' && item.row == row.row)
                        ? indexValue?.find(item => item.id == 'street_number' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue?.find(item => item.id == 'street_number' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {row?.street_number == 'FieldEmpty' ? '-' : row?.street_number}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue?.find(item => item.id == 'street_name' && item.row == row.row)
                        ? indexValue?.find(item => item.id == 'street_name' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue?.find(item => item.id == 'street_name' && item.row == row.row)
                            ? classes.errorDescriptionField
                            : classes.descriptionField
                        }>
                        {row?.street_name == 'FieldEmpty' ? '-' : row?.street_name}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue?.find(item => item.id == 'suburb' && item.row == row.row)
                        ? indexValue?.find(item => item.id == 'suburb' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue?.find(item => item.id == 'suburb' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {row?.suburb == 'FieldEmpty' ? '-' : row?.suburb}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue?.find(item => item.id == 'city' && item.row == row.row)
                        ? indexValue?.find(item => item.id == 'city' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue?.find(item => item.id == 'city' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {row?.city == 'FieldEmpty' ? '-' : row?.city}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue?.find(item => item.id == 'state' && item.row == row.row)
                        ? indexValue?.find(item => item.id == 'state' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue?.find(item => item.id == 'state' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {row?.state == 'FieldEmpty' ? '-' : row?.state}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue?.find(item => item.id == 'post_code' && item.row == row.row)
                        ? indexValue?.find(item => item.id == 'post_code' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue?.find(item => item.id == 'post_code' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {row?.post_code == 'FieldEmpty' ? '-' : row?.post_code}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue?.find(item => item.id == 'Latitude,Longitude' && item.row == row.row)
                        ? indexValue?.find(item => item.id == 'Latitude,Longitude' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue?.find(item => item.id == 'Latitude,Longitude' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {row['Latitude,Longitude'] == 'FieldEmpty' ? '-' : row['Latitude,Longitude']}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue?.find(item => item.id == 'site_type' && item.row == row.row)
                        ? indexValue?.find(item => item.id == 'site_type' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue?.find(item => item.id == 'site_type' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {row?.site_type == 'FieldEmpty' ? '-' : row.site_type}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue?.find(item => item.id == 'service_types_name' && item.row == row.row)
                        ? indexValue?.find(item => item.id == 'service_types_name' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue?.find(item => item.id == 'service_types_name' && item.row == row.row)
                            ? classes.errorDescriptionField
                            : classes.descriptionField
                        }>
                        {row?.service_types_name == 'FieldEmpty' ? '-' : row?.service_types_name}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>
                  <Tooltip
                    title={
                      indexValue?.find(item => item.id == 'client_name' && item.row == row.row)
                        ? indexValue?.find(item => item.id == 'client_name' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue?.find(item => item.id == 'client_name' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {row?.client_name == 'FieldEmpty' ? '-' : row?.client_name}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>
                </TableRow>
              </>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
