import React, { useEffect, useState } from 'react';
import { Grid, Box, Button, Typography } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import GridContainer from '@jumbo/components/GridContainer';
import AppTextInput from '../../../@jumbo/components/Common/formElements/AppTextInput';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CmtCard from '@coremat/CmtCard';
import AppSelectBox from '@jumbo/components/Common/formElements/AppSelectBox';
import {
  primaryColorArray,
  secondaryAndTextColorArray,
  DaysArray,
  HoursArray,
} from '../../../@jumbo/constants/CustomizeSetting';
import { useDispatch, useSelector } from 'react-redux';
import CmtCardContent from '../../../@coremat/CmtCard/CmtCardContent';
import { alphaNumaricWithSpace, emptySpace } from '@jumbo/constants/ValidationRegex';
import { requiredMessage, alphaNumaricMessage } from '@jumbo/constants/ErrorMessages';
import { listPriority } from '@jumbo/constants/ApiConstatnt';
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import axios from '../../../services/auth/jwt/config';
import { setAuthUser } from 'redux/actions/Auth';
import { history } from 'redux/store';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { getFMProvider } from '../../../redux/actions/FMProviderManagement';
import { addNewPriority } from '../../../redux/actions/Priority';
import Paper from '@material-ui/core/Paper';


const breadcrumbs = [
  { label: 'Home', link: '/' },
  { label: 'Priorities', isActive: true },
];
const useStyles = makeStyles(theme => ({
  root: {
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
  },
  Wrap: {
    padding: '10px',
  },
  titleRoot: {
    fontSize: '18px',
    fontWeight: 600,
    marginTop: '14',

    color: theme.palette.text.primary,
  },
  imageButton: {
    fontSize: '10px',
    marginLeft: '5px',
    color: theme.palette.text.primary,
    textOverflow: 'ellipsis',
    maxWidth: '200px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  logoButton: {
    marginLeft: '10px',
  },
  gridMargin: {
    marginTop: '20px',
  },
  gridMarginTop: {
    marginTop: '5px',
  },
}));

const Index = () => {
  const classes = useStyles();
  
  const { users } = useSelector(({ FMPROVIDER }) => FMPROVIDER);
  const [currencyType, setCurrencyType] = useState('0');
  const [p1Days, setP1Days] = useState(0);
  const [p1ResponseTime, setP1ResponseTime] = useState(0);
  const [p1Hours, setP1Hours] = useState(0);
  const [p1RectificationTime, setP1RectificationTime] = useState(0);
  const [p2Days, setP2Days] = useState(0);
  const [p2ResponseTime, setP2ResponseTime] = useState(0);
  const [p2RectificationTime, setP2RectificationTime] = useState(0);
  const [p2Hours, setP2Hours] = useState(0);
  const [p3Days, setP3Days] = useState(0);
  const [p3ResponseTime, setP3ResponseTime] = useState(0);
  const [p3RectificationTime, setP3RectificationTime] = useState(0);
  const [p3Hours, setP3Hours] = useState(0);
  const [p4Days, setP4Days] = useState(0);
  const [p4Hours, setP4Hours] = useState(0);
  const [p4ResponseTime, setP4ResponseTime] = useState(0);
  const [p4RectificationTime, setP4RectificationTime] = useState(0);
  const [p5Days, setP5Days] = useState(0);
  const [p5Hours, setP5Hours] = useState(0);
  const [p5ResponseTime, setP5ResponseTime] = useState(0);
  const [p5RectificationTime, setP5RectificationTime] = useState(0);
  const [fmSelected, setFmSelected] = useState('');
  const [fmSelectedError, setFmSelectedError] = useState('');
  const dispatch = useDispatch();
  

  async function fetchData(type,id) {
    
   let url = id?listPriority+`?fm_admin_id=${id}&type=${type}`:listPriority
    let response = await axios(url).catch(error => {
      if (error?.response?.status === 401) {
        dispatch(setAuthUser(null));
        history.push('/signin');
      } else {
        dispatch(fetchError(error?.response?.data?.message));
      }
    });
    let user = await response?.data;
    setP1Days(response?.data?.data?.priority[0]?.day ?? 0);
    setP2Days(response?.data?.data?.priority[1]?.day ?? 0);
    setP3Days(response?.data?.data?.priority[2]?.day ?? 0);
    setP4Days(response?.data?.data?.priority[3]?.day ?? 0);
    setP5Days(response?.data?.data?.priority[4]?.day ?? 0);
    setP1Hours(response?.data?.data?.priority[0]?.hour ?? 0);
    setP2Hours(response?.data?.data?.priority[1]?.hour ?? 0);
    setP3Hours(response?.data?.data?.priority[2]?.hour ?? 0);
    setP4Hours(response?.data?.data?.priority[3]?.hour ?? 0);
    setP5Hours(response?.data?.data?.priority[4]?.hour ?? 0);
    dispatch(fetchSuccess());
  }
  useEffect(() => {
    dispatch(fetchStart());
    fetchData(0);
    dispatch(getFMProvider('name', 'asc', '', 0, 1000, [1]));
  }, []);

  const handleUpdate = () => {
    const priorityData = {
      priority: [
        { level: 1, day: p1Days, hour: p1Hours },
        { level: 2, day: p2Days, hour: p2Hours },
        { level: 3, day: p3Days, hour: p3Hours },
        { level: 4, day: p4Days, hour: p4Hours },
        { level: 5, day: p5Days, hour: p5Hours },
      ],
      type: Number(currencyType),
      fm_admin_id: fmSelected,
    };

    if(currencyType === '1' && !fmSelected) {
      setFmSelectedError(requiredMessage) 
    }
    if(currencyType === '0' || (currencyType === '1' && fmSelected)) {
      dispatch(addNewPriority(priorityData));
    }
  };
  return (
    <PageContainer heading="Priorities" breadcrumbs={breadcrumbs}>
      <CmtCard className={classes.Wrap}>
        <CmtCardContent>
          <GridContainer className={classes.gridMargin}>
            <Grid item xs={6} sm={6} md={6}>
              <Grid item mt={5}>
                <Typography className={classes.titleRoot} component="h4">
                  Time to Complete Service :
                </Typography>
              </Grid>
              <GridContainer className={classes.gridMarginTop}>
                <Grid item>
                  <Typography className={classes.label}>P1 :</Typography>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <AppSelectBox
                    fullWidth
                    data={DaysArray}
                    label="Days"
                    valueKey="value"
                    variant="outlined"
                    labelKey="value"
                    value={p1Days}
                    onChange={e => {
                      setP1Days(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={4} sm={4}>
                  <AppSelectBox
                    fullWidth
                    data={HoursArray}
                    label="Hours"
                    valueKey="value"
                    variant="outlined"
                    labelKey="value"
                    value={p1Hours}
                    onChange={e => {
                      setP1Hours(e.target.value);
                    }}
                  />
                  
                </Grid>
                
              </GridContainer>
              {/* <Grid container spacing={5}>
{/*         
        <Grid item xs={3} sm={4}>
        <AppSelectBox
                    fullWidth
                    data={DaysArray}
                    label="Response Time"
                    valueKey="value"
                    variant="outlined"
                    labelKey="value"
                    value={p1Days}
                    onChange={e => {
                      setP1Days(e.target.value);
                    }}
                  />
        </Grid>
        <Grid item xs={3} sm={6}>
        <AppSelectBox
                    fullWidth
                    data={DaysArray}
                    label="Response Time"
                    valueKey="value"
                    variant="outlined"
                    labelKey="value"
                    value={p1Days}
                    onChange={e => {
                      setP1Days(e.target.value);
                    }}
                  />
        </Grid>
      </Grid> */} 
              {/* <GridContainer className={classes.gridMarginTop}>
                <Grid item>
                  <Typography className={classes.label}></Typography>
                </Grid>
                
<Grid item xs={4} sm={4}  style={{marginLeft:"28px"}}>
                  <AppSelectBox
                    fullWidth
                    data={DaysArray}
                    label="Response Time"
                    valueKey="value"
                    variant="outlined"
                    labelKey="value"
                    value={p1ResponseTime}
                    onChange={e => {
                      setP1ResponseTime(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={5} sm={5}>
                  <AppSelectBox
                    fullWidth
                    data={HoursArray}
                    label="Rectification Time"
                    valueKey="value"
                    variant="outlined"
                    labelKey="value"
                    value={p1RectificationTime}
                    onChange={e => {
                      setP1RectificationTime(e.target.value);
                    }}
                  />
                  
                </Grid>
              </GridContainer> */}
              
              <GridContainer>
                <Grid item>
                  <Typography className={classes.label}>P2 :</Typography>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <AppSelectBox
                    fullWidth
                    data={DaysArray}
                    label="Days"
                    valueKey="value"
                    variant="outlined"
                    labelKey="value"
                    value={p2Days}
                    onChange={e => {
                      setP2Days(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={4} sm={4}>
                  <AppSelectBox
                    fullWidth
                    data={HoursArray}
                    label="Hours"
                    valueKey="value"
                    variant="outlined"
                    labelKey="value"
                    value={p2Hours}
                    onChange={e => {
                      setP2Hours(e.target.value);
                    }}
                  />
                </Grid>
              </GridContainer>

              {/* <GridContainer className={classes.gridMarginTop}>
                <Grid item>
                  <Typography className={classes.label}></Typography>
                </Grid>
                
<Grid item xs={4} sm={4}  style={{marginLeft:"28px"}}>
                  <AppSelectBox
                    fullWidth
                    data={DaysArray}
                    label="Response Time"
                    valueKey="value"
                    variant="outlined"
                    labelKey="value"
                    value={p2ResponseTime}
                    onChange={e => {
                      setP2ResponseTime(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={5} sm={5}>
                  <AppSelectBox
                    fullWidth
                    data={HoursArray}
                    label="Rectification Time"
                    valueKey="value"
                    variant="outlined"
                    labelKey="value"
                    value={p2RectificationTime}
                    onChange={e => {
                      setP2RectificationTime(e.target.value);
                    }}
                  />
                  
                </Grid>
              </GridContainer> */}
              <GridContainer>
                <Grid item>
                  <Typography className={classes.label}>P3 :</Typography>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <AppSelectBox
                    fullWidth
                    data={DaysArray}
                    label="Days"
                    valueKey="value"
                    variant="outlined"
                    labelKey="value"
                    value={p3Days}
                    onChange={e => {
                      setP3Days(Number(e.target.value));
                    }}
                  />
                </Grid>
                <Grid item xs={4} sm={4}>
                  <AppSelectBox
                    fullWidth
                    data={HoursArray}
                    label="Hours"
                    valueKey="value"
                    variant="outlined"
                    labelKey="value"
                    value={p3Hours}
                    onChange={e => {
                      setP3Hours(Number(e.target.value));
                    }}
                  />
                </Grid>
              </GridContainer>
              {/* <GridContainer className={classes.gridMarginTop}>
                <Grid item>
                  <Typography className={classes.label}></Typography>
                </Grid>
                
<Grid item xs={4} sm={4}  style={{marginLeft:"28px"}}>
                  <AppSelectBox
                    fullWidth
                    data={DaysArray}
                    label="Response Time"
                    valueKey="value"
                    variant="outlined"
                    labelKey="value"
                    value={p3ResponseTime}
                    onChange={e => {
                      setP3ResponseTime(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={5} sm={5}>
                  <AppSelectBox
                    fullWidth
                    data={HoursArray}
                    label="Rectification Time"
                    valueKey="value"
                    variant="outlined"
                    labelKey="value"
                    value={p3RectificationTime}
                    onChange={e => {
                      setP3RectificationTime(e.target.value);
                    }}
                  />
                  
                </Grid>
              </GridContainer> */}
              <GridContainer>
                <Grid item>
                  <Typography className={classes.label}>P4 :</Typography>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <AppSelectBox
                    fullWidth
                    data={DaysArray}
                    label="Days"
                    valueKey="value"
                    variant="outlined"
                    labelKey="value"
                    value={p4Days}
                    onChange={e => {
                      setP4Days(Number(e.target.value));
                    }}
                  />
                </Grid>
                <Grid item xs={4} sm={4}>
                  <AppSelectBox
                    fullWidth
                    data={HoursArray}
                    label="Hours"
                    valueKey="value"
                    variant="outlined"
                    labelKey="value"
                    value={p4Hours}
                    onChange={e => {
                      setP4Hours(Number(e.target.value));
                    }}
                  />
                </Grid>
              </GridContainer>
              {/* <GridContainer className={classes.gridMarginTop}>
                <Grid item>
                  <Typography className={classes.label}></Typography>
                </Grid>
                
<Grid item xs={4} sm={4}  style={{marginLeft:"28px"}}>
                  <AppSelectBox
                    fullWidth
                    data={DaysArray}
                    label="Response Time"
                    valueKey="value"
                    variant="outlined"
                    labelKey="value"
                    value={p4ResponseTime}
                    onChange={e => {
                      setP4ResponseTime(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={5} sm={5}>
                  <AppSelectBox
                    fullWidth
                    data={HoursArray}
                    label="Rectification Time"
                    valueKey="value"
                    variant="outlined"
                    labelKey="value"
                    value={p4RectificationTime}
                    onChange={e => {
                      setP4RectificationTime(e.target.value);
                    }}
                  />
                  
                </Grid>
              </GridContainer> */}
              <GridContainer>
                <Grid item>
                  <Typography className={classes.label}>P5 :</Typography>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <AppSelectBox
                    fullWidth
                    data={DaysArray}
                    label="Days"
                    valueKey="value"
                    variant="outlined"
                    labelKey="value"
                    value={p5Days}
                    onChange={e => {
                      setP5Days(Number(e.target.value));
                    }}
                  />
                </Grid>
                <Grid item xs={4} sm={4}>
                  <AppSelectBox
                    fullWidth
                    data={HoursArray}
                    label="Hours"
                    valueKey="value"
                    variant="outlined"
                    labelKey="value"
                    value={p5Hours}
                    onChange={e => {
                      setP5Hours(Number(e.target.value));
                    }}
                  />
                </Grid>
                {/* <GridContainer className={classes.gridMarginTop}>
                <Grid item>
                  <Typography className={classes.label}></Typography>
                </Grid>
                
<Grid item xs={4} sm={4}  style={{marginLeft:"28px"}}>
                  <AppSelectBox
                    fullWidth
                    data={DaysArray}
                    label="Response Time"
                    valueKey="value"
                    variant="outlined"
                    labelKey="value"
                    value={p5ResponseTime}
                    onChange={e => {
                      setP5ResponseTime(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={5} sm={5}>
                  <AppSelectBox
                    fullWidth
                    data={HoursArray}
                    label="Rectification Time"
                    valueKey="value"
                    variant="outlined"
                    labelKey="value"
                    value={p5RectificationTime}
                    onChange={e => {
                      setP5RectificationTime(e.target.value);
                    }}
                  />
                  
                </Grid>
              </GridContainer> */}
                <Grid item xs={12} sm={12}>
                  <Button
                    variant="contained"
                    style={{ minWidth: '100px' }}
                    color="primary"
                    onClick={handleUpdate}>
                    SAVE
                  </Button>
                </Grid>
              </GridContainer>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <Grid item xs={6} sm={6} md={6}>
                <Typography className={classes.titleRoot}>FM Provider :</Typography>
                <FormControl component="fieldset">
                  <RadioGroup
                    onChange={e => {
                      setCurrencyType(e.target.value);
                    }}
                    name="Currency"
                    value={currencyType}>
                    <FormControlLabel
                      value="0"
                      control={<Radio color="primary" />}
                      label="All"
                      onChange={() => {setFmSelected(null)
                        fetchData(0)}}
                    />
                    <FormControlLabel value="1" control={<Radio color="primary" />} label="Select FM Provider" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <br />
              {currencyType === '1' ? (
                <Grid item xs={8} sm={8}>
                  <AppSelectBox
                    fullWidth
                    data={users?.data}
                    label="FM Provider"
                    valueKey="_id"
                    variant="outlined"
                    labelKey="first_name"
                    value={fmSelected}
                    onChange={e => {
                      setFmSelected(e.target.value);
                      setFmSelectedError('')
                      fetchData(1,e.target.value)
                    }}
                    helperText={fmSelectedError}
                  />
                </Grid>
              ) : (
                ''
              )}
            </Grid>
          </GridContainer>
        </CmtCardContent>
      </CmtCard>
    </PageContainer>
  );
};

export default Index;
