import React, { useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import AboutUsEditor from './AboutUSEditor/AboutUsEditor';
import ComponentsDemo from '../../../@jumbo/components/PageComponents/layouts/ComponentsDemo';
import { useDispatch, useSelector } from 'react-redux';
import {getContent} from '../../../redux/actions/ContentManager'
import { AboutUsSlug} from '../../../@jumbo/constants/ApiConstatnt'
const useStyles = makeStyles(theme => ({
  section: {
    '&:not(:last-child)': {
      marginBottom: theme.typography.pxToRem(32),
    },
  },
  sectionHeading: {
    marginBottom: theme.typography.pxToRem(16),
  },
}));


const AboutUs = [
  {
    label: 'About Us',
    link: 'ck-editor',
    component: <AboutUsEditor />,
    filename: './CkEditorExample.txt',
  },
];
const Menu = [ 
  {
    label: 'About Us',
    link: 'aboutus',
  },
  {
    label: 'Terms & Conditions',
    link: 'termsandconditions',
  },
  {
    label: 'Privacy Policy',
    link: 'privacypolicy',
  },
  {
    label: 'FAQ',
    link: 'faq',
  },
]

const breadcrumbs = [
  { label: 'Home', link: '/' },
  { label: 'About Us', isActive: true },
];

export default function CkEditor() {
  const classes = useStyles();
  // const { aboutUsContent } = useSelector(({ CMS }) => CMS);
  const dispatch = useDispatch()
  // useEffect(() => {
    
  //   dispatch(getContent(AboutUsSlug))
  // }, [])
  
  return (
    <ComponentsDemo pageTitle="Content Manager" menus={Menu} breadcrumbs={breadcrumbs}>
      {AboutUs.map((menu, index) => (
        <Box key={index} id={menu.link} className={classes.section}>
          <Typography component="h3" variant="inherit" className={classes.sectionHeading}>
            {menu.label}
          </Typography>
          {/* <CodeViewerCard code={requireRaw(menu.filename).default} language="jsx"> */}
           {menu.component}
          {/* </CodeViewerCard> */}
        </Box>
      ))}
    </ComponentsDemo>
  );
}
