import { AboutUsSlug } from '@jumbo/constants/ApiConstatnt';
import React, { useEffect, useState } from 'react';
import CKEditor from 'react-ckeditor-component';
import { useDispatch, useSelector } from 'react-redux';
import { setAuthUser } from 'redux/actions/Auth';
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions/Common';
import { addContent } from 'redux/actions/ContentManager';
import { history } from 'redux/store';
import { listCMS } from '../../../../@jumbo/constants/ApiConstatnt';
import { useDebounce } from '../../../../@jumbo/utils/commonHelper';
import { getContent } from '../../../../redux/actions/ContentManager';
import axios from '../../../../services/auth/jwt/config'

const CkEditorExample = () => {
  // const [product, setproduct] = useState([]);
  // const {  aboutUsContent } = useSelector(({ CMS }) => CMS);
  const [content, setContent] = useState( '' );
  const [flag,setFlag]=useState(0)
  const debouncedContent = useDebounce(content, 700);
  const dispatch = useDispatch()

  async function fetchData() {
    let response = await axios(
      listCMS+AboutUsSlug
    ).catch(error => {
      if (error?.response?.status === 401) {
        dispatch(setAuthUser(null));
        history.push('/signin');
      } else {
        dispatch(fetchError(error?.response?.data?.message));
      }
    });
    let user = await response?.data;
    setContent(response?.data?.data?.content);
    dispatch(fetchSuccess());
    
  }

  useEffect(() => {
    dispatch(fetchStart());
    fetchData();
  },[]); 
// useEffect(() => {
//   if(content==='Loading' || content===undefined){
//     setContent( aboutUsContent?.content ?  aboutUsContent?.content : undefined);
//   }
// }, [ aboutUsContent])
useEffect(() => {
  if(flag>1){
    dispatch(addContent(AboutUsSlug,{
      title: AboutUsSlug,
      content:debouncedContent
    }))
  }
  setFlag(flag+1)
}, [debouncedContent])

  const onChange = evt => {
    const newContent = evt.editor.getData();
    setContent(newContent);
    
    
  };

  const onBlur = evt => {
   
    const newContent = evt.editor.getData();
    setContent(newContent);
  };

  const afterPaste = evt => {
    
    const newContent = evt.editor.getData();
    setContent(newContent);
  };

  return (
    <React.Fragment>
      <CKEditor
       config={ {height:'60vh'}}
        activeClass="p10"
        content={content}
        events={{
          blur: onBlur,
          afterPaste: afterPaste,
          change: onChange,
        }}
      />
    </React.Fragment>
  );
};

export default CkEditorExample;
