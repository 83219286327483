import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { set } from 'nprogress';
import ErrorRadios from 'routes/Components/MuiComponents/RadioButton/demos/ErrorRadios';
const useStyles = makeStyles({
  table: {
    minWidth: 650,
    '& .MuiTableCell-head': {
      fontWeight: '600',
    },
  },
  tableRow: {
    fontWeight: '300',

    // backgroundColor: 'red',
  },
  tableRow1: {
    color: 'white',
    backgroundColor: 'red',
  },
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    textOverflow: 'ellipsis',
    maxWidth: '250px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  descriptionField: {
    fontSize: 14,
    fontWeight: '300',
    maxHeight: "80px",
    textOverflow: 'ellipsis',
    // maxWidth: "50px",
    overflow: 'auto',
    wordBreak: 'break-all',
    // backgroundColor: '#f4f4f7',
    // color: '#3B1A45',
    minWidth: '200px',
  },
  errorDescriptionField: {
    color: 'white',
    backgroundColor: 'red',
    fontSize: 14,
    fontWeight: '300',
    maxHeight: "80px",
    textOverflow: 'ellipsis',
    overflow: 'auto',
    wordBreak: 'break-all',
    minWidth: '200px',
  },
  errorTableHeadOther: {
    fontSize: 16,
    fontWeight: '600',
    backgroundColor: '#f4f4f7',
    color: '#3B1A45',
    // minWidth: '200px',
  },
});

export default function ErrorTable() {
  const classes = useStyles();
  const { details } = useSelector(({ BULKASSET }) => BULKASSET);
  const { details_table } = useSelector(({ BULKASSET }) => BULKASSET);
  const { error_details, rows_with_errors } = details_table;
  const [indexValue, setindexValue] = useState([]);

  const rows = [
    '',
    'asset_name',
    'floor',
    'room_number',
    'department',
    'brand',
    'model',
    'description',
    'installation_date',
    'strategic_asset',
    'client_critical_asset',
    'replacement_cost',
    'assets_type_name',
    'tenant_email',
    'client_asset_reference',
    'street_number',
    'street_name',
    'suburb',
    'city',
    'state',
    'post_code',
    'location (Latitude,Longitude)',
    'service_type_name',
    'system_category_name',
    'class_category_name',
    'type_category_name',
    'subtype_category_name',
    'part_name',
    'serial_number',
    'item_installation_date',
    'expiration_date',
    'warranty',
    'warranty_taken_on',
    'warranty_expiration_date',
    'warranty_reference_number',
    'warranty_details',
    'life_expectancy_date',
    'life_expectancy_months',
    'client_name',
  ];

  const rowsChangeName = [
    '',
    'Row No.',
    'Asset Name',
    'Floor',
    'Room Number',
    'Department',
    'Brand',
    'Model',
    'Description',
    'Installation Date',
    'Strategic Asset',
    'Client Critical Asset',
    'Replacement Cost',
    'Assets Type Name',
    'Tenant Email',
    'Client Asset Reference',
    'Street Number',
    'Street Name',
    'Suburb',
    'City',
    'State',
    'Post Code',
    'Location (Latitude, Longitude)',
    'Service Type Name',
    'System Category Name',
    'Class Category Name',
    'Type Category Name',
    'Subtype Category Name',
    'Part Name',
    'Serial Number',
    'Item Installation Date',
    'Expiration Date',
    'Warranty',
    'Warranty Taken On',
    'Warranty Expiration Date',
    'Warranty Reference Number',
    'Warranty Details',
    'Life Expectancy Date',
    'Life Expectancy Months',
    'Client Name',
  ];

  useEffect(() => {
    const ErrorValue = [];
    const arr = rows.map((item, index) => {
      const err = error_details.forEach(error => {
        if (index == error.column) {
          return ErrorValue.push({ id: item, row: error.row, msg: error.message });
        }
      });

      setindexValue(ErrorValue);
    });
  }, []);

  console.log("rows_with_errors",rows_with_errors)

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table" id="table">
        <TableHead>
          <TableRow>
            {/* {rows.map((item, index) => {
              return (
                <> */}
                                  {rowsChangeName?.map((data, index) =>{
                  return (
                  <TableCell className={classes.errorTableHeadOther}>{data}</TableCell>
                  );
                })}
            {/* //     </>
            //   );
            // })} */}
            {/* <TableCell>asset_name</TableCell>
            <TableCell>floor</TableCell>
            <TableCell>department</TableCell>
            <TableCell>brand</TableCell>
            <TableCell>model </TableCell>
            <TableCell>description </TableCell>
            <TableCell>installation_date</TableCell>
            <TableCell>strategic_asset</TableCell>
            <TableCell>client_critical_asset </TableCell>
            <TableCell>replacement_cost </TableCell>
            <TableCell>assets_type_name </TableCell>
            <TableCell>tenant_email </TableCell>
            <TableCell>client_asset_reference </TableCell>
            <TableCell>location (Latitude,Longitude) </TableCell>
            <TableCell>service_type_name </TableCell>
            <TableCell>system_category_name </TableCell>
            <TableCell>class_category_name </TableCell>
            <TableCell>type_category_name </TableCell>
            <TableCell>subtype_category_name </TableCell>
            <TableCell>part_name </TableCell>
            <TableCell>serial_number </TableCell>
            <TableCell>item_installation_date</TableCell>
            <TableCell>expiration_date </TableCell>
            <TableCell>warranty </TableCell>
            <TableCell>warranty_taken_on </TableCell>
            <TableCell>warranty_expiration_date </TableCell>
            <TableCell>warranty_reference_number </TableCell>
            <TableCell>warranty_details </TableCell>
            <TableCell>life_expectancy_date </TableCell>
            <TableCell>life_expectancy_months</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows_with_errors?.map((row, index) => {
            // console.log('row map', indexValue.find(item=>item.id == 'department' && item.row==row.row));
            return (
              <>
                <TableRow className={classes.tableRow}>
                  <TableCell></TableCell>
                  <TableCell component="th" scope="row" onClick={({ target }) => console.log(target.textContent)}>
                      <Typography
                        className={
                          indexValue?.find(item => item.id == 'row' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {row?.row == 'FieldEmpty' ? '-' : row?.row}{' '}
                      </Typography>
                    </TableCell>
                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'asset_name' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'asset_name' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell component="th" scope="row">
                      <Typography
                        id="asset"
                        className={
                          indexValue.find(item => item.id == 'asset_name' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {!row?.asset_name ? '-' : row?.asset_name}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>
                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'floor' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'floor' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell scope="row">
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'floor' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {!row?.floor ? '-' : row?.floor}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>
                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'room_number' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'room_number' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell scope="row">
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'room_number' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {!row?.room_number ? '-' : row?.room_number}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>
                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'department' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'department' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'department' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {!row?.department ? '-' : row?.department}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>
                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'brand' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'brand' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'brand' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {!row?.brand ? '-' : row?.brand}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>
                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'model' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'model' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'model' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {!row?.model ? '-' : row?.model}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'description' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'description' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'description' && item.row == row.row)
                            ? classes.errorDescriptionField
                            : classes.descriptionField
                        }>
                        {!row?.description ? '-' : row?.description}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'installation_date' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'installation_date' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'installation_date' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {!row['installation_date (DD-MM-YYYY)']
                          ? '-'
                          : row['installation_date (DD-MM-YYYY)'].replaceAll('-', '/')}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'strategic_asset' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'strategic_asset' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'strategic_asset' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {!row?.strategic_asset ? '-' : row.strategic_asset}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'client_critical_asset' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'client_critical_asset' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'client_critical_asset' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {!row?.client_critical_asset ? '-' : row?.client_critical_asset}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'replacement_cost' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'replacement_cost' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'replacement_cost' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {!row?.replacement_cost ? '-' : row?.replacement_cost}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'assets_type_name' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'assets_type_name' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'assets_type_name' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {!row?.assets_type_name ? '-' : row?.assets_type_name}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'tenant_email' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'tenant_email' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'tenant_email' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {!row?.tenant_email ? '-' : row?.tenant_email}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'client_asset_reference' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'client_asset_reference' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'client_asset_reference' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {!row?.client_asset_reference ? '-' : row?.client_asset_reference}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'street_number' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'street_number' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell scope="row">
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'street_number' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {!row?.street_number ? '-' : row?.street_number}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'street_name' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'street_name' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell scope="row">
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'street_name' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {!row?.street_name ? '-' : row?.street_name}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>
                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'suburb' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'suburb' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell scope="row">
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'suburb' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {!row?.suburb ? '-' : row?.suburb}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>
                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'city' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'city' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell scope="row">
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'city' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {!row?.city ? '-' : row?.city}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>
                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'state' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'state' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell scope="row">
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'state' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {!row?.state ? '-' : row?.state}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>
                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'post_code' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'post_code' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell scope="row">
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'post_code' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {!row?.post_code ? '-' : row?.post_code}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'location (Latitude,Longitude)' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'location (Latitude,Longitude)' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'location (Latitude,Longitude)' && item.row == row.row)
                            ? classes.errorDescriptionField
                            : classes.descriptionField
                        }>
                        {!row['location (Latitude,Longitude)'] ? '-' : row['location (Latitude,Longitude)']}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'service_type_name' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'service_type_name' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'service_type_name' && item.row == row.row)
                            ? classes.errorDescriptionField
                            : classes.descriptionField
                        }>
                        {!row?.service_type_name ? '-' : row?.service_type_name}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'system_category_name' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'system_category_name' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'system_category_name' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {!row?.system_category_name ? '-' : row?.system_category_name}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'class_category_name' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'class_category_name' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'class_category_name' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {!row?.class_category_name ? '-' : row?.class_category_name}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'type_category_name' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'type_category_name' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'type_category_name' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {!row?.type_category_name ? '-' : row?.type_category_name}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'subtype_category_name' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'subtype_category_name' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'subtype_category_name' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {!row?.subtype_category_name ? '-' : row?.subtype_category_name}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'part_name' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'part_name' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'part_name' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {!row?.part_name ? '-' : row?.part_name}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip title={indexValue.find(item=>item.id == 'serial_number' && item.row==row.row)?indexValue.find(item=>item.id == 'serial_number' && item.row==row.row).msg:''} arrow>
                  <TableCell >
                    <Typography className={indexValue.find(item=>item.id == 'serial_number' && item.row==row.row) ? classes.tableRow1: classes.titleRoot} >
                      {!row?.serial_number ? '-' : row?.serial_number}
                    </Typography>
                  </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'item_installation_date' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'item_installation_date' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'item_installation_date' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {!row['item_installation_date (DD-MM-YYYY)']
                          ? '-'
                          : row['item_installation_date (DD-MM-YYYY)'].replaceAll('-', '/')}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'expiration_date' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'expiration_date' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'expiration_date' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {!row['expiration_date (DD-MM-YYYY)']
                          ? '-'
                          : row['expiration_date (DD-MM-YYYY)'].replaceAll('-', '/')}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'warranty' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'warranty' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'warranty' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {!row?.warranty ? '-' : row?.warranty}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'warranty_taken_on' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'warranty_taken_on' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'warranty_taken_on' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {!row?.warranty_taken_on ? '-' : row?.warranty_taken_on}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'warranty_expiration_date' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'warranty_expiration_date' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'warranty_expiration_date' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {!row['warranty_expiration_date (DD-MM-YYYY)']
                          ? '-'
                          : row['warranty_expiration_date (DD-MM-YYYY)'].replaceAll('-', '/')}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'warranty_reference_number' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'warranty_reference_number' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'warranty_reference_number' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {!row?.warranty_reference_number ? '-' : row?.warranty_reference_number}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'warranty_details' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'warranty_details' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'warranty_details' && item.row == row.row)
                            ? classes.errorDescriptionField
                            : classes.descriptionField
                        }>
                        {!row?.warranty_details ? '-' : row?.warranty_details}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'life_expectancy_date' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'life_expectancy_date' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'life_expectancy_date' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {!row['life_expectancy_date (DD-MM-YYYY)']
                          ? '-'
                          : row['life_expectancy_date (DD-MM-YYYY)'].replaceAll('-', '/')}{' '}
                      </Typography>
                      {}{' '}
                    </TableCell>
                  </Tooltip>

                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'life_expectancy_months' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'life_expectancy_months' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell>
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'life_expectancy_months' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {!row?.life_expectancy_months ? '-' : row?.life_expectancy_months}
                      </Typography>
                    </TableCell>
                  </Tooltip>
                  <Tooltip
                    title={
                      indexValue.find(item => item.id == 'client_name' && item.row == row.row)
                        ? indexValue.find(item => item.id == 'client_name' && item.row == row.row).msg
                        : ''
                    }
                    arrow>
                    <TableCell scope="row">
                      <Typography
                        className={
                          indexValue.find(item => item.id == 'client_name' && item.row == row.row)
                            ? classes.tableRow1
                            : classes.titleRoot
                        }>
                        {!row?.client_name ? '-' : row?.client_name}{' '}
                      </Typography>
                    </TableCell>
                  </Tooltip>
                </TableRow>
              </>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
