import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '../../../../@jumbo/components/Common/formElements/AppTextInput';
import Button from '@material-ui/core/Button';
import { requiredMessage } from '../../../../@jumbo/constants/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { updateUser } from '../../../../redux/actions/Users';
import AppSelectBox from '../../../../@jumbo/components/Common/formElements/AppSelectBox';
import { addNewServiceActivities, getServiceActivities, updateServiceActivitieData } from '../../../../redux/actions/ServiceActivities';
import { emptySpace } from '@jumbo/constants/ValidationRegex';
import { capitalizeFLetter } from '../../../../@jumbo/utils/commonHelper';
import Select from 'react-select';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
    '& .MuiDialog-paper': {
      // margin: '32px',
      // position: 'relative',
      overflow: 'inherit',
      // backgroundColor: '#FFFFFF',
  },
    '& .MuiDialogContent-root': {
      // flex: '1 1 auto',
      // padding: '8px 24px',
      overflow: 'inherit',
      // webkitOverflowScrolling: 'touch',
  },
  },
  root: {
    "& .Mui-error": {
      color: 'red'
    },
    "& .MuiFormHelperText-root": {
      color: 'red'
    },
    "& .MuiFormLabel-root": {
      color: '#909090'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink':{
      color: theme.palette.text.primary
    },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
},
assignInventorySiteView: {
  zIndex: 99,
  minHeight: '40px',
  minWidth: '200px',
  fontSize: '14px',
  '& .chip':{
    background:`${theme.palette.primary.main} !important`
  },
  '& .highlightOption':{
    background:`${theme.palette.primary.main} !important`
  },
  '& .multiSelectContainer li':{
      '&:hover': {
        background:`${theme.palette.primary.main} !important`,
     },   
  },
  '& li':{
    fontSize:'14px',
    '&:hover': {
      background:`${theme.palette.primary.main} !important`,
   },
  },
'& input':{
  fontSize:'16px',
  paddingLeft:'5px',
  minHeight: '28px',
},
'& .optionContainer':{
  border: '1px solid #ccc',
  borderRadius: '4px',
  display: 'block',
  margin: 0,
  maxHeight: '125px',
  overflowY: 'auto',
  padding: 0,
},
},
errText: {
  color: 'red',
  marginLeft: '14px',
  marginRight: '14px',
  fontSize: '0.75rem',
  marginTop: '3px',
  textAlign: 'left',
  fontFamily: 'Normal',
  fontWeight: 400,
  lineHeight: 1.66,
},
}));

const AddEditUser = ({ open, onCloseDialog, getServiceActivitiesParams }) => {
  const classes = useStyles();
  const { users } = useSelector(({ SERVICETYPE }) => SERVICETYPE);
  console.log('serviceType', users);
  const currentUser = useSelector(({ SERVICEACTIVITIES }) => SERVICEACTIVITIES.currentUser);
  const { orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions } = getServiceActivitiesParams;
  const [serviceTypeValueError, setServiceTypeValueError] = useState('');
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [status,setStatus]=useState(1)
  const [serviceTypeValue, setServiceTypeValue] = useState('');
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const p_color=window.localStorage.getItem('p_c') ?? '#FC8119'
  const s_color=window.localStorage.getItem('s_c') ?? '#F0511E'
  const t_color=window.localStorage.getItem('t_c') ?? '#3B1A45'
  const dispatch = useDispatch();
  console.log("data",users?.data)
  useEffect(() => {
    if (currentUser) {
      setName(currentUser?.name);
      setServiceTypeValue(currentUser?.service_type_id)
      setStatus(currentUser?.status)
    }
  }, [currentUser]);
  const onSubmitClick = () => {
    if (name.match(emptySpace)) {
      setNameError(requiredMessage);
    } 
    if (!serviceTypeValue[0]) {
      setServiceTypeValueError(requiredMessage);
    } 
    if(!name.match(emptySpace) && serviceTypeValue) {
      onUserSave();
    }
  };

  const onUserSave = () => {
    const iname = capitalizeFLetter(name)
   
    const serviceActivityData = {
      name:iname,
      status: status,
      service_type_id: serviceTypeValue,
    };

    if (currentUser) {
      dispatch(
        updateServiceActivitieData( currentUser?._id,serviceActivityData , () => {
          onCloseDialog();
          dispatch(getServiceActivities(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
        }),
      );
    } else {
      dispatch(
        addNewServiceActivities(serviceActivityData, () => {
          onCloseDialog();
          dispatch(getServiceActivities(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
        }),
      );
    }
  };

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot }>
      <DialogTitle className={classes.dialogTitleRoot}>
        {currentUser ? 'Edit Service Activity' : 'Add New Service Activity'}
      </DialogTitle>
      <DialogContent dividers>
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}  style={{maxWidth:'300px'}}>
          <GridContainer>
            <Grid item xs={12} sm={12}>
              <AppTextInput
              className={classes.root}
                fullWidth
                variant="outlined"
                label="Name"
                value={name}
                onChange={e => {
                  setName(e.target.value);
                  setNameError('');
                }}
                helperText={nameError}
              />
            </Grid>
            <Grid item xs={12} sm={12} className={classes.root}>
                    <Select
                      // className="style"
                      className={classes.assignInventorySiteView}
                      style={{backgroundColor: 'white'}}
                      placeholder="Service Type"
                      isDisabled={currentUser?.is_vcg_creation ? true : false}
                      value={users?.data?.map(item=>{
                        return{ value:item?._id, label:item?.name}
                      }).filter(obj => obj?.value === serviceTypeValue)}
                      onChange={e=>{setServiceTypeValue(e?.value ?? ''); setServiceTypeValueError('')}}
                      isClearable={isClearable}
                      isSearchable={isSearchable}
                      options={users?.data?.map(item=>{
                        return{ value:item?._id, label:item?.name}
                      })}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                        ...theme.colors,
                          text: t_color,
                          // primary25: s_color,
                          primary: p_color,
                        },
                      })}
                    />
                    <p className={classes.errText} style={{ color: 'red' }}>{serviceTypeValueError}</p>
              {/* <AppSelectBox
                className={classes.root}
                data={users?.data}
                label="Service Type"
                valueKey="_id"
                variant="outlined"
                labelKey="name"
                value={serviceTypeValue}
                onChange={e => {
                  setServiceTypeValue(e.target.value);

                  setServiceTypeValueError('');
                }}
                helperText={serviceTypeValueError}
              /> */}
            </Grid>
          </GridContainer>
        </Box>

        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button variant="outlined" onClick={onCloseDialog}>Cancel</Button>
          <Box ml={2}>
            <Button variant="contained" style={{minWidth:'100px'}} color="primary" onClick={onSubmitClick}>
            {currentUser? 'UPDATE':'ADD'}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditUser;

AddEditUser.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
