import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';
import {
  GET_ASSETTYPELIST,
  SET_ASSETTYPE_UPDATE,
  GET_ASSETTYPEDETAILS,
  SET_ASSETTYPE_ERROR,
} from '../../@jumbo/constants/ActionTypes';
import {
  addAssetType,
  listAssetType,
  assettypeStatusUpdate,
  assettypeupdate,
  assettypedetails,
  deleteAssetTypeId,
  addAssetTypeManually,
} from '../../@jumbo/constants/ApiConstatnt';

// get Item list
export const getAssetTypeList = (sortBy, sort, searchTerm, page, limit, filterOptions, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(listAssetType, {
        params: { sortBy: `${sortBy}:${sort}`, search: searchTerm, page: page + 1, limit: limit, status: filterOptions[0] },
      })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_ASSETTYPELIST, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError());
          dispatch({ type: GET_ASSETTYPELIST, payload: [] });
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Item list not found.') {
          dispatch({ type: GET_ASSETTYPELIST, payload: [] });
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

// set current user for view tab
export const setAssetTypeList = user => {
  return dispatch => {
    dispatch({ type: SET_ASSETTYPE_UPDATE, payload: user });
  };
};

// add new FM provider
export const addNewAssetType = (user, callbackFun, listCall) => {
  return dispatch => {
    // dispatch(fetchStart());
    dispatch(fetchSuccess('Asset bulk import process has been start.'));
    axios
      .post(addAssetType, user)
      .then(data => {
        if (data) {
          if (data?.data?.data?.red_snakbar) {
            setTimeout(() => {
              dispatch(fetchError(data?.data?.message));
              // dispatch(fetchSuccess('New Asset type added successfully.'));
            }, 2000);
          } else {
            setTimeout(() => {
              dispatch(fetchSuccess(data?.data?.message));
            }, 2000);
          }

          if (callbackFun) {
            callbackFun.setErrroDialog();
          }
          if (listCall) listCall(data.data);

          dispatch({ type: SET_ASSETTYPE_ERROR, payload: data?.data });
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
        callbackFun.onCloseDialog();
        callbackFun.setAddButton()
      });
  };
};

// add mnaually asset
export const addManuallyAssetType = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(addAssetTypeManually, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('New asset type added successfully.'));
          }, 2000);
          // if(AddToWarehouse){
          //   dispatch({ type: GET_WAREHOUSELIST, payload: data.data.data });
          // }
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

export const updateAssetTypeStatus = (data, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(assettypeStatusUpdate, data)
      .then(response => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Asset type status updated successfully.'));
          }, 3000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

export const getAssetTypeDetails = (userId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(assettypedetails + userId)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_ASSETTYPEDETAILS, payload: data.data.data });

          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Item List not found.') {
          dispatch(fetchError(error?.response?.data?.message));
          dispatch({ type: GET_ASSETTYPEDETAILS, payload: [] });
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

export const setClassCategoryDetails = user => {
  return dispatch => {
    dispatch({ type: SET_ASSETTYPE_UPDATE, payload: user });
  };
};

export const deleteAssetType = (userId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete(deleteAssetTypeId + userId)
      .then(data => {
        if (data.status === 200) {
          setTimeout(() => {
            dispatch(fetchSuccess('Asset type deleted successfully.'));
          }, 2000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError());
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

export const sentMailToUser = () => {
  return dispatch => {
    dispatch(fetchSuccess('Email has been sent to user successfully'));
  };
};

export const updateAssetTypeList = (id, user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(assettypeupdate + id, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Asset type updated successfully.'));
          }, 3000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};
