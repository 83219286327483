import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '../../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '../../../../../@jumbo/components/Common/formElements/AppTextInput';
import Button from '@material-ui/core/Button';
import { requiredMessage, lettersOnlyMessage } from '../../../../../@jumbo/constants/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import {
  addNewSubTypeCategory,
  getSubTypeCategoryList,
  updateSubTypeCategoryList,
  addManuallySubTypeCategory,
} from '../../../../../redux/actions/SubTypeCategory';
import { emptySpace, numberOnly, numberOnly2, stringOnly, onlyStringWithSpace } from '../../../../../@jumbo/constants/ValidationRegex';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import AppSelectBox from '../../../../../@jumbo/components/Common/formElements/AppSelectBox';
import { getClassCategoryList } from 'redux/actions/ClassCategory';
import { getTypeCategoryList } from 'redux/actions/TyepCategory';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import DialogActions from '@material-ui/core/DialogActions';
import ErrorTable from '../ErrorTable';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ErrorIcon from '@material-ui/icons/Error';
import { GET_CLASSCATEGORYLIST, GET_TYPECATEGORYLIST } from '@jumbo/constants/ActionTypes';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },

  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.text.primary,
    },
  },
  serviceTypeList: {
    fontSize: 14,
    letterSpacing: 0.25,
    textOverflow: 'ellipsis',
    wordWrap: 'break-word',
  },
  typeList: {
    width: 535,
    fontSize: 14,
    height: 300,
    backgroundColor: theme.palette.background.default,
    overflow: 'auto',
  },
  imageButton: {
    fontSize: '10px',
    marginLeft: '5px',
    color: theme.palette.text.primary,
    textOverflow: 'ellipsis',
    maxWidth: '200px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  imageRoot: {
    marginTop: '5%',
  },
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
    '& .MuiFormLabel-root': {
      color: '#909090',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      color: theme.palette.text.primary,
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
  errText: {
    color: 'red',
    marginLeft: '16px',
    marginRight: '14px',
    fontSize: '0.75rem',
    marginTop: '3px',
    textAlign: 'left',
    fontFamily: 'Normal',
    fontWeight: 400,
    lineHeight: 1.66,
  },
  appSelectBox: {
    color: '#c7c3bf',
    '& .MuiFormLabel-root': {
      color: '#c7c3bf',
    },
  },
  titleRoot2: {
    fontWeight: '300',
    marginLeft: '5px',
    fontSize: 14,
    textOverflow: 'ellipsis',
    maxWidth: '350px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  paper: {
    position: 'relative',
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '1000px',
      // width: '100%',
      minWidth: '500px',
    },
  },
}));

const AddEditUser = ({ open, onCloseDialog, getSystemCtegorieParams, setOpenWareHouseDialog,setErrroDialog,addButton,setAddButton }) => {
  const classes = useStyles();
  const { currentUser, error } = useSelector(({ SUBTYPECATEGORY }) => SUBTYPECATEGORY);

  const { users } = useSelector(({ SYSTEMCATEGORIE }) => SYSTEMCATEGORIE);

  const usersSystemCategory = useSelector(({ CLASSCATEGORY }) => CLASSCATEGORY.users);
  const usersTypeCategory = useSelector(({ TYPECATEGORY }) => TYPECATEGORY.users);
  const {
    orderBy,
    order,
    debouncedSearchTerm,
    page,
    rowsPerPage,
    filterOptions,
    SubTypeCategory,
    id,
  } = getSystemCtegorieParams;
  const [classFile, setClassFile] = useState('');
  const [systemCategorie, setSystemCategorie] = useState('');
  const [classCategoryId, setClassCategoryId] = useState('');
  const [typeCategoryId, setTypeCategoryId] = useState('');
  const [subtypeCategoryId, setSubTypeCategoryId] = useState('');
  const [csvError, setCsvError] = useState('');
  const [systemCategorieError, setSystemCategorieError] = useState('');
  const [classCategoryError, setClassCategoryError] = useState('');
  const [typeCategoryError, setTypeCategoryError] = useState('');
  const [subtypeCategoryError, setSubTypeCategoryError] = useState('');
  const dispatch = useDispatch();
  const [value, setValue] = React.useState('1');
  const [addManually, setAddManually] = React.useState(true);
  // const [errroDialog, setErrroDialog] = React.useState(false);
  const handleChange = event => {
    setValue(event.target.value);
    setSystemCategorieError('');
    setClassCategoryError('');
    setTypeCategoryError('');
    setSubTypeCategoryError('');
    setCsvError('');
  };
  useEffect(() => {
    dispatch({ type: GET_CLASSCATEGORYLIST, payload: [] });
    dispatch({ type: GET_TYPECATEGORYLIST, payload: [] });
  }, []);

  useEffect(() => {
    if (value == 1) setAddManually(false);
    if (value == 2) setAddManually(true);
  }, [value]);
  useEffect(() => {
    if (currentUser) {
      setSystemCategorie(currentUser?.system_category_details?.name);
      setClassCategoryId(currentUser?.class_category_details?.name);
      setTypeCategoryId(currentUser?.type_category_details?.name);
      setSubTypeCategoryId(currentUser?.name);
    }
  }, [currentUser]);

  const handelSystemCategory = e => {
    setSystemCategorie(prev => e.target.value);
    setSystemCategorieError('');
    dispatch(getClassCategoryList('name', 'asc', '', '', -1, '', '', e.target.value));
  };

  const handelClassCategory = e => {
    setClassCategoryId(prev => e.target.value);
    setClassCategoryError('');
    dispatch(getTypeCategoryList('name', 'asc', '', '', -1, '', '', e.target.value));
  };
  const onSubmitClick = () => {
    if (!classCategoryId) {
      setClassCategoryError(requiredMessage);
    }
    if (!systemCategorie) {
      setSystemCategorieError(requiredMessage);
    }
    if (!typeCategoryId) {
      setTypeCategoryError(requiredMessage);
    }
    if (!subtypeCategoryId.match(onlyStringWithSpace)) {
      setSubTypeCategoryError(lettersOnlyMessage);
    }
    if (!subtypeCategoryId || subtypeCategoryId.match(emptySpace)) {
      setSubTypeCategoryError(requiredMessage);
    }
    if (
      classFile.type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
      classFile.type != 'text/csv'
    ) {
      setCsvError('Please upload excel or csv file');
    }
    // if( classFile.type !='text/csv'){
    //   setCsvError("Please choose Excel file.");
    // }
    if (!classFile) {
      setCsvError(requiredMessage);
    }
    if (
      (classFile &&
        classCategoryId &&
        systemCategorie &&
        typeCategoryId &&
        classFile.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
      (classFile && classCategoryId && systemCategorie && typeCategoryId && classFile.type == 'text/csv')
    ) {
      setAddButton(true)
      // alert("innnn")
      onUserSave();
      onCloseDialog();
    }
    if (systemCategorie && classCategoryId && typeCategoryId && !subtypeCategoryId.match(emptySpace) && subtypeCategoryId && subtypeCategoryId.match(onlyStringWithSpace)) {
      onUserSave();
    }
  };

  const onUserSave = () => {
    if (currentUser) {
      // alert("edit")
      const formData = {
        category_name: subtypeCategoryId,
      };
      dispatch(
        updateSubTypeCategoryList(currentUser?._id, formData, () => {
          onCloseDialog();
          dispatch(
            getSubTypeCategoryList(
              orderBy,
              order,
              debouncedSearchTerm,
              page,
              rowsPerPage,
              filterOptions,
              SubTypeCategory,
              id,
            ),
          );
        }),
      );
    }
    if (addManually) {
      // alert("manually")
      const manuallyData = {
        parent_id: typeCategoryId,
        name: subtypeCategoryId,
        // category_name: typeCategoryId,
      };
      dispatch(
        addManuallySubTypeCategory(manuallyData, () => {
          onCloseDialog();
          dispatch(
            getSubTypeCategoryList(
              orderBy,
              order,
              debouncedSearchTerm,
              page,
              rowsPerPage,
              filterOptions,
              SubTypeCategory,
              id,
            ),
          );
        }),
      );
    }
    //  else

    if (classFile) {
      // alert("bulk")
      const formData = new FormData();
      formData.append('categoryImportFile', classFile);
      formData.append('type', 4);
      formData.append('_id', typeCategoryId);

      dispatch(
        addNewSubTypeCategory(
          formData,
          {
            setErrroDialog: () => setErrroDialog(true),
            onCloseDialog: () => onCloseDialog(),
            setAddButton: () =>setAddButton(false)
          },
          () => {
            
            dispatch(
              getSubTypeCategoryList(
                orderBy,
                order,
                debouncedSearchTerm,
                page,
                rowsPerPage,
                filterOptions,
                SubTypeCategory,
                id,
              ),
            )
          },
        ),
      );
    }
  };
  const handelClose = () => {
    setErrroDialog(false);
    onCloseDialog();
  };
  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>
        {currentUser ? 'Edit SubType Category' : 'Import SubType Category'}
      </DialogTitle>
      <Box>
        {/* <Dialog
          className={classes.paper}
          open={errroDialog}
          onClose={handelClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-description">Error Details</DialogTitle>
          <Divider />
          <DialogContent>
            <Box className={classes.userInfoRoot}>
              <Box px={6} py={5}>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }} ml={{ xs: -6 }}>
                  <ImportExportIcon />
                  <Box ml={3} display="flex">
                    <Typography className={classes.titleRoot1}>Total Rows Imported : </Typography>
                    <Typography className={classes.titleRoot2}>{error?.data?.total_rows_imported}</Typography>
                  </Box>
                </Box>

                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }} ml={{ xs: -6 }}>
                  <KeyboardArrowRightIcon />
                  <Box ml={3} display="flex">
                    <Typography className={classes.titleRoot1}>Total Rows Processed : </Typography>
                    <Typography className={classes.titleRoot2}>{error?.data?.total_rows_processed}</Typography>
                  </Box>
                </Box>

                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 5 }} ml={{ xs: -6 }}>
                  <ErrorIcon />
                  <Box ml={3} display="flex">
                    <Typography className={classes.titleRoot1}>Total Rows With Errors:</Typography>
                    <Typography className={classes.titleRoot2}>{error?.data?.total_rows_with_errors}</Typography>
                  </Box>
                </Box>
              </Box>
              {error?.data?.error.length > 0 ? (
                <ErrorTable />
              ) : (
                <Box display="flex" alignItems="center" mb={{ xs: 2, sm: 1 }} mt={{ xs: 2 }}>
                  <Typography className={classes.titleRoot2}>{error?.message}</Typography>
                </Box>
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handelClose} autoFocus variant="outlined">
              Cancel
            </Button>
          </DialogActions>
        </Dialog> */}
      </Box>
      <DialogContent dividers>
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
          <GridContainer>
            {currentUser ? (
              <>
                <Grid item xs={12} sm={12}>
                  <AppTextInput
                    className={currentUser ? '' : classes.root}
                    fullWidth
                    disabled={true}
                    variant="outlined"
                    label="System Category Name"
                    value={systemCategorie}
                    helperText={systemCategorieError}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <AppTextInput
                    className={currentUser ? '' : classes.root}
                    fullWidth
                    disabled={true}
                    variant="outlined"
                    label="Class Category Name"
                    value={classCategoryId}
                    helperText={classCategoryError}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <AppTextInput
                    className={currentUser ? '' : classes.root}
                    fullWidth
                    disabled={true}
                    variant="outlined"
                    label="Type Category Name"
                    value={typeCategoryId}
                    helperText={typeCategoryError}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <AppTextInput
                    className={classes.root}
                    fullWidth
                    variant="outlined"
                    label="Sub Type Category Name"
                    value={subtypeCategoryId}
                    onChange={e => {
                      setSubTypeCategoryId(e.target.value);
                      setSubTypeCategoryError('');
                      setAddManually(false);
                    }}
                    helperText={subtypeCategoryError}
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} sm={12} className={currentUser ? classes.appSelectBox : classes.root}>
                  <AppSelectBox
                    fullWidth
                    data={users?.data}
                    disabled={currentUser ? true : false}
                    label="System Category Name"
                    valueKey="_id"
                    variant="outlined"
                    labelKey="name"
                    value={systemCategorie}
                    onChange={e => {
                      // setSystemCategorie(prev => e.target.value);
                      // setSystemCategorieError('');
                      // dispatch(getClassCategoryList('name', 'asc', '', '', '', '', '', '',e.target.value));
                      handelSystemCategory(e);
                    }}
                    helperText={currentUser ? null : systemCategorieError}
                  />
                </Grid>

                <Grid item xs={12} sm={12} className={currentUser ? classes.appSelectBox : classes.root}>
                  <AppSelectBox
                    fullWidth
                    data={usersSystemCategory?.data}
                    disabled={currentUser ? true : false}
                    label="Class Category Name"
                    valueKey="_id"
                    variant="outlined"
                    labelKey="name"
                    value={classCategoryId}
                    onChange={e => {
                      // setClassCategoryId(prev => e.target.value);
                      // setClassCategoryError('');
                      handelClassCategory(e);
                    }}
                    helperText={currentUser ? null : classCategoryError}
                  />
                </Grid>

                <Grid item xs={12} sm={12} className={currentUser ? classes.appSelectBox : classes.root}>
                  <AppSelectBox
                    fullWidth
                    data={usersTypeCategory?.data}
                    disabled={currentUser ? true : false}
                    label="Type Category Name"
                    valueKey="_id"
                    variant="outlined"
                    labelKey="name"
                    value={typeCategoryId}
                    onChange={e => {
                      setTypeCategoryId(prev => e.target.value);
                      setTypeCategoryError('');
                    }}
                    helperText={currentUser ? null : typeCategoryError}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl component="fieldset">
                    <RadioGroup aria-label="query" name="query1" value={value} onChange={handleChange}>
                      <FormControlLabel value="1" control={<Radio />} label="Bulk Import" />
                      <FormControlLabel value="2" control={<Radio />} label="Add SubType Category" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {value == 2 ? (
                  <Grid item xs={12} sm={12}>
                    <AppTextInput
                      className={classes.root}
                      fullWidth
                      variant="outlined"
                      label="Sub Type Category Name"
                      value={subtypeCategoryId}
                      // onKeyPress={event => {
                      //   if (['-', '+', '.', ' '].includes(event.key)) {
                      //     event.preventDefault();
                      //   }
                      // }}
                      onChange={e => {
                        setSubTypeCategoryId(e.target.value);
                        setSubTypeCategoryError('');
                      }}
                      helperText={subtypeCategoryError}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={12}>
                    <Button variant="contained" component="label">
                      <CloudUploadIcon />
                      <p className={classes.imageButton}>{classFile?.size ? classFile?.name : 'Choose Excel File'}</p>
                      <input
                        type="file"
                        hidden
                        onChange={event => {
                          setClassFile(event.target.files[0]);
                          setCsvError('');
                        }}
                        accept=".xlsx"
                      />
                    </Button>{' '}
                    <br />
                    <p className={classes.errText} style={{ color: 'red' }}>
                      {csvError}
                    </p>
                  </Grid>
                )}
              </>
            )}
          </GridContainer>
        </Box>
        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button onClick={onCloseDialog} variant="outlined">
            Cancel
          </Button>

          <Box ml={2}>
            <Button variant="contained" color="primary" style={{ minWidth: '100px' }} onClick={onSubmitClick} disabled={addButton&&!currentUser?true:false}>
            {currentUser ? 'Update' : value == '1' ? 'Import' : 'Add'}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditUser;

AddEditUser.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
