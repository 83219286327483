import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  indexHead: {
    marginRight:'15px',
  },
}));

const headCells = [
  { id: '#', numeric: false, disablePadding: true, label: '#' },
  { id: 'a', numeric: false, disablePadding: false, label: 'File Name' },
  { id: 'a', numeric: false, disablePadding: false, label: 'Fm Admin' },
  { id: 'created_at', numeric: false, disablePadding: false, label: 'Uploaded Date' },
  { id: 'a', numeric: false, disablePadding: false, label: 'Download File' },
  { id: 'a', numeric: false, disablePadding: false, label: 'Status' },
];

function UserTableHead({ classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort }) {
  // const classes = useStyles();
  const onSortOrderChange = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell></TableCell> */}
        {headCells?.map(headCell => (
          <TableCell
          className={headCell.id == '#' ? classes.indexHead : null}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              disabled={headCell.id ==='a' ? true : false}
              hideSortIcon={headCell.id ==='a' ? true : false}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={onSortOrderChange(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align="center">Actions</TableCell>
      </TableRow>
    </TableHead>
  );
}

UserTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default React.memo(UserTableHead);
