import React from 'react';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
const useStyles = makeStyles(theme => ({
  root: {
    "& .Mui-error": {
      color: '#3B1A45',
      
    },
    "& .MuiFormHelperText-root": {
      color: '#3B1A45'
    },
    "& .MuiTextField-root" :{
      color: '#3B1A45',
      border:'1px solid black'
    }
  },
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
  
  
}));
const AppTextInput = ({ type, name, id, fullWidth, size, value, onChange, helperText, variant, error, ...rest }) => {
  const classes = useStyles();
  return (
    <TextField
    // className={classes.root}
      {...rest}
      type={type}
      name={name}
      id={id || name}
      size={size}
      fullWidth={fullWidth}
      value={value}
      variant={variant}
      onChange={onChange}
      // error={error || helperText !== ''}
      helperText={helperText}
    />
  );
};

AppTextInput.prototype = {
  type: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  value: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.bool,
};

AppTextInput.defaultProps = {
  type: 'text',
  fullWidth: true,
  size: 'small',
  error: false,
  helperText: '',
};

export default AppTextInput;
