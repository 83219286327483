import React from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import CmtAvatar from '../../../../@coremat/CmtAvatar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import { useSelector } from 'react-redux';
import CmtList from '../../../../@coremat/CmtList';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import useStyles from './index.style';
import { Block, CheckCircleOutline } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import FaceIcon from '@material-ui/icons/Face';

const UserDetailView = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  const { currentUser } = useSelector(({ FMPROVIDER }) => FMPROVIDER);
  const { first_name, last_name, email, status } = currentUser;

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <Box className={classes.userInfoRoot}>
        <Box mr={3} display="flex" alignItems="center">
          <Box className={classes.avatarView} mr={{ xs: 4, md: 6 }}>
            <CmtAvatar size={70} alt={first_name} />
          </Box>

          <Box mt={-2}>
            <Box display="flex" alignItems="center">
              <Typography
                className={classes.titleRoot}
                style={{
                  fontSize: 14,
                  letterSpacing: 0.25,

                  textOverflow: 'ellipsis',
                  maxWidth: '350px',
                  display: 'block',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}>
                {first_name?.toUpperCase()} {last_name?.toUpperCase()}
              </Typography>
              <Box ml={1}></Box>
            </Box>
          </Box>
        </Box>
        <Box ml="auto" mt={-2} display="flex" alignItems="center">
          <Box ml={1}></Box>
          <Box ml={1}>
            <IconButton onClick={onCloseDialog}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box px={6} py={5}>
        <Box mb={5} className={classes.titleroot1} component="p" color="common.dark">
          FM provider Detail
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <FaceIcon />
          <Box>
          <Box display="flex">
            <Typography className={classes.titleRoot1}>First name :</Typography>
            <Typography className={classes.titleRoot2}>{first_name}</Typography>
            </Box>
            <Box display="flex" mt={5}>
            <Typography className={classes.titleRoot1}>Last name :</Typography>
            <Typography className={classes.titleRoot2}>{last_name}</Typography>
          </Box>
          </Box>
        </Box>
        
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <EmailIcon />
          <Typography className={classes.titleRoot1}>Email :</Typography>
          <Typography className={classes.titleRoot2}>{email}</Typography>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 5 }}>
          {status === 1 ? <CheckCircleOutline /> : <Block />}
          <Typography className={classes.titleRoot1}>Status :</Typography>
          <Typography className={classes.titleRoot2}>{status === 1 ? 'Active' : 'Inactive'}</Typography>
        </Box>
      </Box>
    </Dialog>
  );
};

export default UserDetailView;

UserDetailView.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
