import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';
import {
  DELETE_BULK_ITEMSLIST,
  GET_ITEMSLIST,
  SET_ITEMSLIST_DETAILS,
  GET_ITEMSLISTDETAILS,
  GET_MANUFACTURERLIST,
  GET_SYSTEMCATEGORYERROR,
} from '../../@jumbo/constants/ActionTypes';
import {
  addSystemCategorie,
  listSyatemcategories,
  updateSystemCategorie,
  SystemCategorieupdate,
  systemCategoriedetails,
  deleteCategorie,
  ManufacturerList,
  addItemWarehouse,
  addSystemCategorieManually,
} from '../../@jumbo/constants/ApiConstatnt';

// get Item list
export const getSystemCategorieList = (sortBy, sort, searchTerm, page, limit, filterOptions, category, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(listSyatemcategories, {
        params: {
          sortBy: `${sortBy}:${sort}`,
          search: searchTerm,
          page: page + 1,
          limit: limit,
          status: filterOptions[0],
          type: category,
        },
      })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_ITEMSLIST, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError());
          dispatch({ type: GET_ITEMSLIST, payload: [] });
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Item list not found.') {
          dispatch({ type: GET_ITEMSLIST, payload: [] });
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

// set current user for view tab
export const setItemList = user => {
  return dispatch => {
    dispatch({ type: SET_ITEMSLIST_DETAILS, payload: user });
  };
};

// add new FM provider
export const addNewSystemCategorie = (user, callbackFun, listCall) => {
  return dispatch => {
    // dispatch(fetchStart());
    dispatch(fetchSuccess('System category bulk import process has been start.'));
    axios
      .post(addSystemCategorie, user)
      .then(data => {
        if (data) {
          if (data?.data?.data?.red_snakbar) {
            setTimeout(() => {
              // dispatch(fetchSuccess('New System categorie added successfully.'));
              dispatch(fetchError(data?.data?.message));
            }, 2000);
          } else {
            setTimeout(() => {
              // dispatch(fetchSuccess('New System categorie added successfully.'));
              dispatch(fetchSuccess(data?.data?.message));
            }, 2000);
          }
          if (callbackFun) {
            callbackFun.setErrroDialog();
            // callbackFun.onCloseDialog();
          }
          if (listCall) listCall(data.data);
          // }
          dispatch({ type: GET_SYSTEMCATEGORYERROR, payload: data?.data });
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
        callbackFun.onCloseDialog();
        callbackFun.setAddButton()
      });
  };
};

// add new system category manually
export const addManuallySystemCategorie = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(addSystemCategorieManually, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('New system category added successfully.'));
          }, 2000);
          // if(AddToWarehouse){
          //   dispatch({ type: GET_WAREHOUSELIST, payload: data.data.data });
          // }
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

// add to warehouse part
export const addPartToWarehouse = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(addItemWarehouse, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('New Part added successfully.'));
          }, 2000);

          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

export const updateSystemCategorieStatus = (data, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(updateSystemCategorie, data)
      .then(response => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('System category status updated successfully.'));
          }, 3000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

export const getSystemCategorieDetails = (userId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(systemCategoriedetails + userId)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_ITEMSLISTDETAILS, payload: data.data.data });

          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Item List not found.') {
          dispatch(fetchError(error?.response?.data?.message));
          dispatch({ type: GET_ITEMSLISTDETAILS, payload: [] });
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

export const getManufacturerList = callbackFun => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(ManufacturerList)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_MANUFACTURERLIST, payload: data.data.data });

          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Item List not found.') {
          dispatch(fetchError(error?.response?.data?.message));
          dispatch({ type: GET_ITEMSLISTDETAILS, payload: [] });
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

export const setItemListDetails = user => {
  return dispatch => {
    dispatch({ type: SET_ITEMSLIST_DETAILS, payload: user });
  };
};

// all function below are not in use currntly
export const deleteBulkUsers = (userIds, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/users/bulk-delete', { userIds })
      .then(response => {
        if (response.status === 200) {
          dispatch(fetchSuccess('Selected users were deleted successfully.'));
          dispatch({ type: DELETE_BULK_ITEMSLIST, payload: userIds });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const deleteSystemCategorie = (userId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete(deleteCategorie + userId)
      .then(data => {
        if (data.status === 200) {
          setTimeout(() => {
            dispatch(fetchSuccess('System category deleted successfully.'));
          }, 2000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError());
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

export const sentMailToUser = () => {
  return dispatch => {
    dispatch(fetchSuccess('Email has been sent to user successfully'));
  };
};

export const updateSystemCategorieList = (id, user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(SystemCategorieupdate + id, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('System category updated successfully.'));
          }, 3000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};
