import { fetchError, fetchStart, fetchSuccess } from './Common';
// import axios from 'axios';
import { FETCH_DATA_SUCCESS, GET_ABOUTUS, GET_FAQ, GET_PRIVACYPOLICY, GET_TERMSANDCONDITIONS } from "../../@jumbo/constants/ActionTypes";
import axios from '../../services/auth/jwt/config';
import {
  // ADD_CONTACTUSQUERY,
  DELETE_BULK_CONTACTUSQUERY,
  DELETE_CONTACTUSQUERY,
  EDIT_CONTACTUSQUERY,
  GET_CONTACTUSQUERY,
  SET_CONTACTUSQUERY_DETAILS,
  GET_CONTACTUSDETAILS
} from '../../@jumbo/constants/ActionTypes';
import {listContactUsQuery ,detailsContactUsQuery,statusContactUsQuery} from '../../@jumbo/constants/ApiConstatnt'
import { history } from 'redux/store';
import { setAuthUser } from './Auth';

export const getContactUsQuery = (sortBy, sort, filterOptions, searchTerm, page, limit, callbackFun) => {
 
  return dispatch => {
    dispatch({ type: GET_ABOUTUS, payload: null });
    dispatch({ type: GET_PRIVACYPOLICY, payload: null });
    dispatch({ type: GET_TERMSANDCONDITIONS, payload: null });
    dispatch({ type: GET_FAQ, payload: null });
    dispatch(fetchStart());
    // console.log('apiparams', page);
    axios
      .get(listContactUsQuery, {
        params: { sortBy: `${sortBy}:${sort}`, q: searchTerm, page: page + 1, limit: limit, status: filterOptions[0] },
      })
      .then(data => {
        if (data.status === 200 ) {
          // dispatch(fetchSuccess());
          dispatch({type:FETCH_DATA_SUCCESS,})
          dispatch({ type: GET_CONTACTUSQUERY, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        }else if(data.status===203)
        {
          dispatch(fetchError());
          dispatch({ type: GET_CONTACTUSQUERY, payload: [] });
          if (callbackFun) callbackFun();
        }
         else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Get contactUs request successfully.') {
          if (callbackFun) callbackFun();
          dispatch(fetchError());
          dispatch({ type: GET_CONTACTUSQUERY, payload: [] });
        } else if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
       
      });
  };
};

export const getContactUsDetails = (userId) => {
  return dispatch => {
    dispatch(fetchStart());
    // console.log('apiparams', page);
    axios
      .get(detailsContactUsQuery+userId)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_CONTACTUSDETAILS, payload: data.data.data });
          // if (callbackFun) callbackFun(data.data);
        } else {
          dispatch({ type: GET_CONTACTUSDETAILS, payload: [] });
          dispatch(fetchError());
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'contact us query details not found.') {
          // if (callbackFun) callbackFun();
          dispatch(fetchError());
          dispatch({ type: GET_CONTACTUSDETAILS, payload: [] });
        } else if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};


export const setCurrentQuery = user => {
  return dispatch => {
    dispatch({ type: SET_CONTACTUSQUERY_DETAILS, payload: user });
  };
};

// export const addNewContactUsQuery = (user, callbackFun) => {
//   return dispatch => {
//     dispatch(fetchStart());
//     axios
//       .post(addContactUsQuery, user)
//       .then(data => {
//         // console.log('add data', data);
//         if (data) {
//           setTimeout(() => {
//             dispatch(fetchSuccess('New Sub Admin was added successfully.'));
//           }, 2000);

//           // dispatch({ type:  ADD_FMPROVIDER, payload: data.data });
//           if (callbackFun) callbackFun(data.data);
//         } else {
//           dispatch(fetchError('There was something issue in responding server.'));
//         }
//       })
//       .catch(error => {
//         dispatch(fetchError(error?.response?.data?.message));
//       });
//   };
// };

export const sentMailToUser = () => {
  return dispatch => {
    dispatch(fetchSuccess('Email has been sent to user successfully'));
  };
};

export const updateUser = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/users', user)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess('Selected user was updated successfully .'));
          dispatch({ type: EDIT_CONTACTUSQUERY, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const updateContactUsQueryStatus = (data, callbackFun) => {
  // console.log("statuscallid",data)

  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(statusContactUsQuery+data._id)
      .then(response => {
        // console.log("statusresponse",response)
        if (data) {
          // alert("hello")
          setTimeout(() => {
            dispatch(fetchSuccess('Contact Us query status updated successfully.'));
          }, 2000);
          // dispatch({ type: EDIT_USER, payload: response.data });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};


export const deleteBulkUsers = (userIds, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/users/bulk-delete', { userIds })
      .then(response => {
        if (response.status === 200) {
          dispatch(fetchSuccess('Selected users were deleted successfully.'));
          dispatch({ type: DELETE_BULK_CONTACTUSQUERY, payload: userIds });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const deleteUser = (userId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete('/users', { params: { id: userId } })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess('Selected user was deleted successfully.'));
          dispatch({ type: DELETE_CONTACTUSQUERY, payload: userId });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};
