import { lighten, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(4),
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  container: {
    maxHeight: 415,
  },
  table: {
    minWidth: 750,
    '& .MuiTableCell-head':{
      fontWeight:600
          }
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  titleRoot2: {
    fontWeight: '300',
    marginLeft: '5px',
    fontSize: 14,
    textOverflow: 'ellipsis',
    maxWidth: '350px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  paper1: {
    position: 'relative',
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '1000px',
      // width: '100%',
      minWidth: '500px',
    },
  },
  titleRoot1: {
    fontWeight: '600',
    whiteSpace: 'nowrap',
    '&.MuiTypography-body1': {
      fontSize: 14,
    },
  },
  userInfoRoot: {
    borderBottom: `1px solid ${theme.palette.borderColor.main}`,
    // padding: '20px 24px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
    height: "206px",
  },
}));

export default useStyles;
