import { fetchError, fetchStart, fetchSuccess } from './Common';
// import axios from 'axios';
import axios from '../../services/auth/jwt/config';
import {
  ADD_SERVICETYPE,
  DELETE_BULK_SERVICETYPE,
  DELETE_SERVICETYPE,
  EDIT_SERVICETYPE,
  FETCH_DATA_SUCCESS,
  GET_SERVICETYPE,
  SET_SERVICETYPE_DETAILS,
} from '../../@jumbo/constants/ActionTypes';
import {listServiceType , addPriority,updateServiceTypeStatus, updateServiceType,deleteServiceType} from '../../@jumbo/constants/ApiConstatnt'
import { history } from 'redux/store';
import { setAuthUser } from './Auth';

export const getSERVICETYPE = (sortBy, sort, filterOptions, searchTerm, page, limit, callbackFun) => {
  return dispatch => {
 dispatch(fetchStart());
    axios
      .get(listServiceType, {
        params: { sortBy: `${sortBy}:${sort}`, q: searchTerm, page: page + 1, limit: limit, status: filterOptions[0] },
      })
      .then(data => {
        if (data.status === 200) {
          // dispatch(fetchSuccess());
          dispatch({type:FETCH_DATA_SUCCESS,})
          dispatch({ type: GET_SERVICETYPE, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Service type list not found.') {
          if (callbackFun) callbackFun();
          dispatch(fetchError());
          dispatch({ type: GET_SERVICETYPE, payload: [] });
        } else if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        } 
        
      });
  };
};

export const setCurrentSERVICETYPE = user => {
  return dispatch => {
    dispatch({ type: SET_SERVICETYPE_DETAILS, payload: user });
  };
};

export const addNewPriority = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(addPriority, user)
      .then(data => {
        
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Priorities added successfully.'));
          }, 2000);

          // dispatch({ type:  ADD_FMPROVIDER, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

export const sentMailToUser = () => {
  return dispatch => {
    dispatch(fetchSuccess('Email has been sent to user successfully'));
  };
};

export const updateServiceTypeData = (id,user, callbackFun) => {
  return dispatch => {
    
    dispatch(fetchStart());
    axios
      .put(updateServiceType+id, user)
      .then(data => {
        setTimeout(() => {
          dispatch(fetchSuccess('Service Type updated successfully .'));
        }, 2000);
          if (callbackFun) callbackFun();
       
      })
      .catch(error => {
        
        if(error?.response?.status===401 ){
          dispatch(setAuthUser(null));
          history.push('/signin')
        } 
        else {
          console.log('error',error?.response?.status)
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

export const updateSERVICETYPEStatus = (data, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(updateServiceTypeStatus, data)
      .then(response => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Service Type status updated successfully.'));
          }, 2000);
          // dispatch({ type: EDIT_USER, payload: response.data });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};


export const deleteBulkUsers = (userIds, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/users/bulk-delete', { userIds })
      .then(response => {
        if (response.status === 200) {
          dispatch(fetchSuccess('Selected users were deleted successfully.'));
          dispatch({ type: DELETE_BULK_SERVICETYPE, payload: userIds });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const deleteUser = (userId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete(deleteServiceType+userId)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess('Selected service type deleted successfully.'));
          // dispatch({ type: DELETE_SERVICETYPE, payload: userId });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};
