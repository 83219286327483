import {
  GET_TYPECATEGORYLIST,
  SET_TYPECATEGORY_UPDATEDETAILS,
  GET_TYPECATEGORYDETAILS,
  SET_TYPECATEGORY_ERROR
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  users: [],
  currentUser: null,
  details:null,
  ManufacturerList:null,
  wareHouse:null,
  error:null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TYPECATEGORYLIST: {
      return {
        ...state,
        users: action.payload,
      };
    }

    case GET_TYPECATEGORYDETAILS: {
      return {
        ...state,
        details: action.payload,
      };
    }
    

    case SET_TYPECATEGORY_ERROR: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case SET_TYPECATEGORY_UPDATEDETAILS: {
      return {
        ...state,
        currentUser: action.payload,
      };
    }
    
    default:
      return state;
  }
};
