import {
  GET_ASSETLIST,
  SET_CLASSCATEGORY_DETAILS,
  GET_ASSETDETAILS,
  GET_ASSETDETAILS_TABLE,
  GET_ERRORLIST,
  SET_ASSETTYPE_EXPORT
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  users: [],
  currentUser: null,
  details:null,
  details_table:null,
  ManufacturerList:null,
  wareHouse:null,
  errorData:null,
  exportFile:null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ASSETLIST: {
      return {
        ...state,
        users: action.payload,
      };
    }

    case GET_ERRORLIST: {
      return {
        ...state,
        errorData: action.payload,
      };
    }

    case GET_ASSETDETAILS: {
      return {
        ...state,
        details: action.payload,
      };
    }

    case GET_ASSETDETAILS_TABLE: {
      return {
        ...state,
        details_table: action.payload,
      };
    }

    case SET_ASSETTYPE_EXPORT: {
      return {
        ...state,
        exportFile: action.payload,
      };
    }
    case SET_CLASSCATEGORY_DETAILS: {
      return {
        ...state,
        currentUser: action.payload,
      };
    }
    
    default:
      return state;
  }
};
