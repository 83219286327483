import React, { useEffect, useState } from 'react';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import fetch from 'isomorphic-fetch';
import { GoogleMap, Marker, withGoogleMap } from 'react-google-maps';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { getServiceRequest } from '../../../../redux/actions/ServiceRequest';
import { statusArray } from '../../../../@jumbo/constants/CustomizeSetting';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: '10px',
    marginBottom: '10px',
    maxWidth: 345,
  },

  title: {
    fontSize: 15,
    fontWeight: 600,
  },

  address: {
    fontSize: 14,
    fontWeight: 400,
  },
  address1: {
    fontSize: 13,
    fontWeight: 400,
    textOverflow: 'ellipsis',
    maxWidth: '200px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    lineHeight: 1.6,
  },
  pos: {
    marginBottom: 12,
    fontWeight: 600,
    textOverflow: 'ellipsis',
    maxWidth: '150px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  locationHeight: {
    marginTop: '10px',
    maxHeight: '400px',
    overflow: 'auto',
  },
  cardBox: {
    marginLeft: '15px',
    maxWidth: 345,
    height: '370px',
  },
  boxDesign: {
    marginLeft: '8px',
    marginTop: '155px',
  },
}));
const p_color = window.localStorage.getItem('p_c') ?? '#FC8119';
const MarkerClustererExampleGoogleMap = withGoogleMap(props => (
  <GoogleMap defaultZoom={3} defaultCenter={{ lat: 25.0391667, lng: 121.525 }}>
    {props.markers?.map((marker, index) => (
      <Marker
        position={{
          lat: marker?.location_data?.location?.geometry?.location?.lat,
          lng: marker?.location_data?.location?.geometry?.location?.lng,
        }}
        // key={marker?.location_data?.location?.geometry?.location?.lat}
        key={index}
        title={marker.name}
        onClick={e => {
          document.getElementById(marker._id).scrollIntoView();

          document.getElementById(marker._id).style.borderColor = p_color;
          setTimeout(() => {
            document.getElementById(marker._id).style.borderColor = '';
          }, 1000);
        }}
        icon={
          marker?.service_status === statusArray.vendor_not_assigned
            ? process.env.PUBLIC_URL + '/images/service_request_icon/vendernotassigned.png'
            : marker?.service_status === statusArray.vendor_assigned
            ? process.env.PUBLIC_URL + '/images/service_request_icon/venderAssigned.png'
            : marker?.service_status === statusArray.service_request_completed
            ? process.env.PUBLIC_URL + '/images/service_request_icon/serReqCompleted.png'
            : marker?.service_status === statusArray.approve_work
            ? process.env.PUBLIC_URL + '/images/service_request_icon/workApproved.png'
            : process.env.PUBLIC_URL + '/images/service_request_icon/payementSend.png'
        }
      />
    ))}
    {/* </MarkerClusterer> */}
  </GoogleMap>
));

const MarkerClustererExample = ({ onUserView }) => {
  const classes = useStyles();
  const { users } = useSelector(({ SERVICEREQUEST }) => SERVICEREQUEST);
  const [markers, setMarkers] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    fetch(
      `https://gist.githubusercontent.com/farrrr/dfda7dd7fccfec5474d3/raw/758852bbc1979f6c4522ab4e92d1c92cba8fb0dc/data.json`,
    )
      .then(res => res.json())
      .then(data => {
        setMarkers(data.photos);
      });
    dispatch(getServiceRequest('name', 'asc', '', 0, 1000, []));
  }, []);

  return (
    <GridContainer>
      <Grid item xs={3} sm={3} className={classes.locationHeight}>
        <Box>
          {users?.data ? (
            users?.data?.map(item => (
              <Card className={classes.root} variant="outlined" key={item._id} id={item._id}>
                <CardContent>
                  <Box display="flex" justifyContent="space-between">
                    <Typography className={classes.pos} color="textSecondary">
                      {item?.location_data?.name}
                    </Typography>
                    <span>
                      <img
                        src={
                          item?.service_status === statusArray.vendor_not_assigned
                            ? process.env.PUBLIC_URL + '/images/service_request_icon/vendernotassigned-1.png'
                            : item?.service_status === statusArray.vendor_assigned
                            ? process.env.PUBLIC_URL + '/images/service_request_icon/venderAssigned-1.png'
                            : item?.service_status === statusArray.service_request_completed
                            ? process.env.PUBLIC_URL + '/images/service_request_icon/serReqCompleted-1.png'
                            : item?.service_status === statusArray.approve_work
                            ? process.env.PUBLIC_URL + '/images/service_request_icon/workApproved-1.png'
                            : process.env.PUBLIC_URL + '/images/service_request_icon/payementSend-1.png'
                        }
                      />
                    </span>
                  </Box>
                  <Typography variant="body2" className={classes.title} component="p">
                    Service Type :
                  </Typography>
                  <Typography className={classes.address1} component="p">
                    {' '}
                    {item?.service_type}
                  </Typography>

                  <Typography variant="body2" className={classes.title} component="p">
                    Date :<span className={classes.address1}> {moment(item?.created_at * 1000).format('DD/MM/YYYY')}</span>
                  </Typography>
                  <Typography variant="body2" className={classes.title} component="p">
                    Description :
                  </Typography>
                  <Typography className={classes.address1} component="p">
                    {' '}
                    {item?.description}
                  </Typography>

                  <Typography variant="body2" className={classes.title} component="p">
                    Assigned Vendor :{' '}
                  </Typography>
                  <Typography className={classes.address1} component="p">
                    {' '}
                    {item?.vendor_details?.first_name} {item?.vendor_details?.last_name}
                  </Typography>

                  <Button
                    color="primary"
                    style={{ marginTop: '10px' }}
                    variant="contained"
                    size="small"
                    onClick={e => {
                      onUserView(item);
                    }}>
                    View
                  </Button>
                </CardContent>
              </Card>
            ))
          ) : (
            <Card className={classes.cardBox}>
              <CardContent>
                <Box display="flex" justifyContent="space-between" className={classes.boxDesign}>
                  {'There are no records found.'}
                </Box>
              </CardContent>
            </Card>
          )}
        </Box>
      </Grid>
      <Grid item xs={4} sm={8}>
        <MarkerClustererExampleGoogleMap
          loadingElement={<Box height={1} />}
          containerElement={<Box height={{ xs: 300, sm: 400 }} />}
          mapElement={<Box height={1} />}
          markers={users?.data}
        />
      </Grid>
    </GridContainer>
  );
};

export default MarkerClustererExample;
