// import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';
// import Paper from '@material-ui/core/Paper';
// import { useDispatch, useSelector } from 'react-redux';

// const useStyles = makeStyles({
//   table: {
//     minWidth: 370,
//     '& .MuiTableCell-head': {
//       fontWeight: '600',
//     },
//   },
//   tableRow: {
//     fontWeight: '300',
//     borderColor: 'red',
//   },
// });

// export default function ErrorTable() {
//   const classes = useStyles();
//   const { errorData } = useSelector(({ BULKLOCATION }) => BULKLOCATION);

//   return (
//     <TableContainer component={Paper}>
//       <Table className={classes.table} size="small" aria-label="a dense table">
//         <TableHead>
//           <TableRow>
//             <TableCell>type</TableCell>
//             <TableCell>name</TableCell>
//             <TableCell>street address</TableCell>
//             <TableCell>city</TableCell>
//             <TableCell>state</TableCell>
//             <TableCell>post_code</TableCell>
//             <TableCell>Latitude,Longitude</TableCell>
//             <TableCell>site_type</TableCell>
//             <TableCell>service_types_name</TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {!errorData ? (
//             <TableRow>
//               <TableCell component="th" scope="row">
//                 -
//               </TableCell>

//               <TableCell>-</TableCell>
//               <TableCell>-</TableCell>
//               <TableCell>-</TableCell>
//               <TableCell>- </TableCell>
//               <TableCell>- </TableCell>
//               <TableCell>-</TableCell>
//               <TableCell>-</TableCell>
//               <TableCell>- </TableCell>
//               <TableCell>- </TableCell>

//             </TableRow>
//           ) : null}
//           {errorData?.data?.rows_with_errors?.map(row => (
//             <TableRow>
//               <TableCell component="th" scope="row">
//                 {!row?.asset_name ? '-' : row?.asset_name}
//               </TableCell>
//               <TableCell>{!row?.floor ? '-' : row?.floor}</TableCell>
//               <TableCell>{!row?.department ? '-' : row?.department}</TableCell>
//               <TableCell>{!row?.brand ? '-' : row?.brand}</TableCell>
//               <TableCell>{!row?.model ? '-' : row?.model}</TableCell>
//               <TableCell>{!row?.description ? '-' : row?.description} </TableCell>

//             </TableRow>
//           ))}
//         </TableBody>
//       </Table>
//     </TableContainer>
//   );
// }

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    '& .MuiTableCell-head': {
      fontWeight: '600',
    },
  },
  tableRow: {
    fontWeight: '300',
  },
  tableRow1: {
    color: 'white',
    backgroundColor: 'red',
  },
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    textOverflow: 'ellipsis',
    maxWidth: '350px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  titleRoot2: {
    fontWeight: '300',
    marginLeft: '5px',
    fontSize: 14,
    textOverflow: 'ellipsis',
    maxWidth: '350px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  errorTableHead: {
    fontSize: 16,
    fontWeight: '600',
    backgroundColor: '#f4f4f7',
    color: '#3B1A45',
  },
  
  
});

export default function ErrorTable() {
  const classes = useStyles();
  const { error } = useSelector(({ ASSETTYPE }) => ASSETTYPE);
  const [indexValue, setindexValue] = useState();

  const rows = ['Row No', 'Error Message'];

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table" id="table">
        <TableHead>
          <TableRow>
            {rows?.map((item, index) => {
              return (
                <>
                  <TableCell className={classes.errorTableHead}>{item}</TableCell>
                </>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {error?.data?.error?.map((row, index) => {
            return (
              <>
                <TableRow className={classes.tableRow}>
                  <TableCell component="th" scope="row" onClick={({ target }) => console.log(target.textContent)}>
                    <Typography className={classes.titleRoot}>{!row?.index ? '-' : row?.index} </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      className={classes.titleRoot}>
                      {!row?.message ? '-' : row?.message}{' '}
                    </Typography>
                  </TableCell>
                </TableRow>
              </>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
