import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '../../../../@jumbo/components/Common/formElements/AppTextInput';
import CmtAvatar from '../../../../@coremat/CmtAvatar';
import { useDropzone } from 'react-dropzone';
import Button from '@material-ui/core/Button';
import CmtList from '../../../../@coremat/CmtList';
import IconButton from '@material-ui/core/IconButton';
import AppSelectBox from '../../../../@jumbo/components/Common/formElements/AppSelectBox';
import { emailNotValid, lettersOnlyMessage, requiredMessage } from '../../../../@jumbo/constants/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import { capitalizeFLetter, isValidEmail } from '../../../../@jumbo/utils/commonHelper';
import { updateUser } from '../../../../redux/actions/Users';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import clsx from 'clsx';
import { addNewSERVICETYPE, getSERVICETYPE, updateServiceTypeData } from '../../../../redux/actions/ServiceType';
import { stringOnly } from '../../../../@jumbo/constants/ValidationRegex';
// console.log("functions",addNewSERVICETYPE,getSERVICETYPE)

const useStyles = makeStyles(theme => ({
  root: {
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
    "& .MuiFormLabel-root": {
      color: '#909090'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink':{
      color: theme.palette.text.primary
    },
    dialogRoot: {
      position: 'relative',
    },
    dialogTitleRoot: {
      '& .MuiTypography-h6': {
        fontSize: 16,
        color: theme.palette.common.dark,
      },
    },
  },
}));

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

function PhoneNumberInput({ onChange, value, ...other }) {
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    if (!phoneNumber && value) {
      setTimeout(() => {
        setPhoneNumber(value);
      }, 300);
    }
  }, [phoneNumber, value]);

  const onNumberChange = number => {
    setPhoneNumber(number.formattedValue);
    onChange(number.formattedValue);
  };

  return <NumberFormat {...other} onValueChange={onNumberChange} value={phoneNumber} format="(###) ###-####" />;
}

const labels = [
  { title: 'Home', slug: 'home' },
  { title: 'Office', slug: 'office' },
  { title: 'Other', slug: 'other' },
];

const splitName = user => {
  if (user) {
    const [fName, mName, lName] = user.name.split(' ');
    return [fName, lName ? mName + ' ' + lName : mName];
  }

  return ['', ''];
};

const AddEditUser = ({ open, onCloseDialog, getSERVICETYPEParams }) => {
  const classes = useStyles();
  const currentUser = useSelector(({ usersReducer }) => usersReducer.currentUser);
  const { orderBy, order, filterOptions, debouncedSearchTerm, page, rowsPerPage } = getSERVICETYPEParams;
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [profile_pic, setProfile_pic] = useState('');
  const [company, setCompany] = useState('');
  const [designation, setDesignation] = useState('');
  const [phones, setPhones] = useState([{ phone: '', label: 'home' }]);

  const [firstNameError, setFirstNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [statusGet, setStatusGet] = useState('');
  const [status, setStatus] = useState(1);
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setProfile_pic(URL.createObjectURL(acceptedFiles[0]));
    },
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (currentUser) {
      // const [fName, lName] = splitName(currentUser);
      setFirstName(currentUser?.name);
      setStatus(currentUser?.status);
      // setLastName(lName);
      // setProfile_pic(currentUser.profile_pic);
      // setEmail(currentUser.email);
      // setCompany(currentUser.company);
      // setDesignation(currentUser.designation);
      // setPhones(currentUser.phones);
    }
  }, [currentUser]);

  const onPhoneNoAdd = (number, index) => {
    const updatedList = [...phones];
    updatedList[index].phone = number;
    setPhones(updatedList);
    setPhoneError('');
  };

  const onPhoneRowRemove = index => {
    const updatedList = [...phones];
    updatedList.splice(index, 1);
    setPhones(updatedList);
  };

  const onPhoneRowAdd = () => {
    setPhones(phones.concat({ phone: '', label: 'home' }));
  };

  const onLabelChange = (value, index) => {
    const updatedList = [...phones];
    updatedList[index].label = value;
    setPhones(updatedList);
  };
  const nameRegex = /^[a-zA-Z]+$/;
  const letters = /^(?=(?:[^A-Za-z]*[A-Za-z]){2})(?![^\d~`?!^*¨ˆ;@=$%{}\[\]|\\\/<>#“.,]*[\d~`?!^*¨ˆ;@=$%{}\[\]|\\\/<>#“.,])\S+(?: \S+){0,2}$/;
  const onSubmitClick = () => {
    const phoneNumbers = phones.filter(item => item.phone.trim());
    if (!firstName) {
      setFirstNameError(requiredMessage);
    // } else if (!firstName.match(nameRegex)) {
    } else if (!firstName.match(letters)) {
      setFirstNameError(lettersOnlyMessage);
    } else {
      onUserSave(phoneNumbers);
    }
  };

  const onUserSave = () => {
    const iname = capitalizeFLetter(firstName);
    const userDetail = {
      name: iname,
      status: status,
    };

    if (currentUser) {
      dispatch(
        updateServiceTypeData(currentUser?._id, userDetail, () => {
          onCloseDialog();
          dispatch(getSERVICETYPE(orderBy, order, filterOptions, debouncedSearchTerm, page, rowsPerPage));
        }),
      );
    } else {
      dispatch(
        addNewSERVICETYPE(userDetail, () => {
          onCloseDialog();
          dispatch(getSERVICETYPE(orderBy, order, filterOptions, debouncedSearchTerm, page, rowsPerPage));
        }),
      );
    }
  };

  const isPhonesMultiple = phones.length > 1;

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>
        {currentUser ? 'Edit Service Type' : 'Add New Service Type'}
      </DialogTitle>
      <DialogContent dividers>
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
          <GridContainer>
            <Grid item xs={12} sm={12}>
              <AppTextInput
                className={classes.root}
                fullWidth
                variant="outlined"
                label="Name"
                value={firstName}
                onChange={e => {
                  setFirstName(e.target.value.slice(0, 30));
                  setFirstNameError('');
                }}
                helperText={firstNameError}
              />
            </Grid>

          </GridContainer>
        </Box>
        
        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button variant="outlined" onClick={onCloseDialog}>
            Cancel
          </Button>
          <Box ml={2}>
            <Button variant="contained" color="primary" style={{ minWidth: '100px' }} onClick={onSubmitClick}>
              {currentUser ? 'UPDATE' : 'ADD'}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditUser;

AddEditUser.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
