import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';
import {
  GET_TYPECATEGORYLIST,
  SET_TYPECATEGORY_UPDATEDETAILS,
  GET_TYPECATEGORYDETAILS,
  SET_TYPECATEGORY_ERROR,
} from '../../@jumbo/constants/ActionTypes';
import {
  addTypeCategory,
  listTypeCategory,
  typeCategoryStatusUpdate,
  typeCategoryupdate,
  typeCategoriedetails,
  deleteTypeCategorie,
  addTypeCategoryManually,
} from '../../@jumbo/constants/ApiConstatnt';

// get Item list
export const getTypeCategoryList = (sortBy, sort, searchTerm, page, limit, filterOptions, category, id, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(listTypeCategory, {
        params: {
          sortBy: `${sortBy}:${sort}`,
          search: searchTerm,
          page: page + 1,
          limit: limit,
          status: filterOptions[0],
          type: category,
          _id: id,
        },
      })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_TYPECATEGORYLIST, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError());
          dispatch({ type: GET_TYPECATEGORYLIST, payload: [] });
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Item list not found.') {
          dispatch({ type: GET_TYPECATEGORYLIST, payload: [] });
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

// set current user for view tab
export const setTypeCategoryList = user => {
  return dispatch => {
    dispatch({ type: SET_TYPECATEGORY_UPDATEDETAILS, payload: user });
  };
};

// add new FM provider
export const addNewTypeCategory = (user, callbackFun, listCall) => {
  return dispatch => {
    // dispatch(fetchStart());
    dispatch(fetchSuccess('Type category bulk import process has been start.'));
    axios
      .post(addTypeCategory, user)
      .then(data => {
        if (data) {
          if (data?.data?.data?.red_snakbar) {
            setTimeout(() => {
              dispatch(fetchError(data?.data?.message));
              // dispatch(fetchSuccess('New Type Category added successfully.'));
            }, 2000);
          } else {
            setTimeout(() => {
              dispatch(fetchSuccess(data?.data?.message));
              // dispatch(fetchSuccess('New Type Category added successfully.'));
            }, 2000);
          }
          if (callbackFun) {
            callbackFun.setErrroDialog();
          }
          if (listCall) listCall(data.data);
          // }

          dispatch({ type: SET_TYPECATEGORY_ERROR, payload: data?.data });
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
        callbackFun.onCloseDialog();
        callbackFun.setAddButton()
      });
  };
};

// add manually type category
export const addManuallyTypeCategory = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(addTypeCategoryManually, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('New type category added successfully.'));
          }, 2000);
          // if(AddToWarehouse){
          //   dispatch({ type: GET_WAREHOUSELIST, payload: data.data.data });
          // }
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

export const updateTypeCategoryStatus = (data, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(typeCategoryStatusUpdate, data)
      .then(response => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Type category status updated successfully.'));
          }, 3000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

export const getTypeCategoryDetails = (userId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(typeCategoriedetails + userId)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_TYPECATEGORYDETAILS, payload: data.data.data });

          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Item List not found.') {
          dispatch(fetchError(error?.response?.data?.message));
          dispatch({ type: GET_TYPECATEGORYDETAILS, payload: [] });
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

export const setClassCategoryDetails = user => {
  return dispatch => {
    dispatch({ type: SET_TYPECATEGORY_UPDATEDETAILS, payload: user });
  };
};

export const deleteTypeCategory = (userId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete(deleteTypeCategorie + userId)
      .then(data => {
        if (data.status === 200) {
          setTimeout(() => {
            dispatch(fetchSuccess('Type category deleted successfully.'));
          }, 2000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError());
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

export const sentMailToUser = () => {
  return dispatch => {
    dispatch(fetchSuccess('Email has been sent to user successfully'));
  };
};

export const updateTypeCategoryList = (id, user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(typeCategoryupdate + id, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Type category updated successfully.'));
          }, 3000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};
