export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
export const UPDATE_AUTH_USER = 'update_auth_user';
export const UPDATE_LOAD_USER = 'update_load_user';
export const SEND_FORGET_PASSWORD_EMAIL = 'send_forget_password_email';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'signin_google_user_success';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'signin_facebook_user_success';
export const SIGNIN_TWITTER_USER_SUCCESS = 'signin_twitter_user_success';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_SUCCESS';
export const SIGNIN_USER_SUCCESS = 'signin_user_success';
export const SIGNOUT_USER_SUCCESS = 'signout_user_success';

export const SET_DASHBOARD_DATA = 'set_dashboard_data';

export const SET_TASK_CURRENT_USER = 'set_task_current_user';
export const SET_TASKS_DATA = 'set_tasks_data';
export const SET_TASK_LIST_DATA = 'set_task_list_data';
export const ADD_TASK = 'add_task';
export const DELETE_TASK = 'delete_task';
export const UPDATE_TASK = 'update_task';
export const SET_FILTER_DATA = 'set_filter_data';
export const ADD_TASK_LIST = 'add_task_list';
export const UPDATE_TASK_LIST = 'update_task_list';
export const DELETE_TASK_LIST = 'delete_task_list';
export const SET_TASK_DETAIL = 'set_task_detail';
export const SEND_MESSAGE = 'send_message';
export const TOGGLE_SIDEBAR_COLLAPSED = 'toggle_sidebar_collapsed';
export const GET_TASKS_COUNTS = 'get_tasks_counts';

//mail app
export const GET_LABELS_LIST = 'get_labels_list';
export const GET_CONNECTIONS_LIST = 'get_connections_list';
export const GET_MAILS_LIST = 'get_mails_list';
export const UPDATE_MAIL_FOLDER = 'update_mail_folder';
export const UPDATE_MAIL_LABEL = 'upade_mail_label';
export const UPDATE_FAVORITE_STATUS = 'update_favorite_status';
export const UPDATE_READ_STATUS = 'update_read_status';
export const UPDATE_IMPORTANT_STATUS = 'update_important_status';
export const COMPOSE_MAIL = 'compose_mail';
export const SET_FILTER_TYPE = 'set_filter_type';
export const GET_SELECTED_MAIL = 'GET_SELECTED_MAIL';
export const UPDATE_SELECTED_MAIL = 'update_selected_mail';
export const NULLIFY_SELECTED_MAIL = 'nullify_selected_mail';
export const REPLY_TO_MAIL = 'reply_to_mail';
export const GET_MAIL_COUNTS = 'get_mail_count';
export const ADD_LABEL = 'add_label';
export const ADD_CONNECTION = 'add_connection';
export const REMOVE_CONNECTION = 'remove_connection';

export const SET_CHAT_USERS = 'set_chat_users';
export const SET_CONTACT_USERS = 'set_contact_users';
export const SET_CURRENT_USER = 'set_current_user';
export const SET_CONVERSATION_DATA = 'set_conversation_data';
export const SEND_NEW_CHAT_MESSAGE = 'send_new_chat_message';
export const SEND_NEW_MEDIA_MESSAGE = 'send_new_media_message';

//Contact App
export const GET_CONTACTS_LIST = 'get_contacts_list';
export const SET_CURRENT_CONTACT = 'set_current_contact';
export const CREATE_CONTACT = 'create_contact';
export const UPDATE_STARRED_STATUS = 'update_starred_status';
export const DELETE_CONTACT = 'delete_contact';
export const UPDATE_CONTACT_LABEL = 'update_contact_label';
export const UPDATE_CONTACT = 'update_contact';
export const GET_CONTACT_COUNTS = 'get_contact_counts';
export const UPDATE_LABEL_ITEM = 'update_label_item';
export const DELETE_LABEL_ITEM = 'delete_label_item';

export const GET_USER_DETAIL = 'get_user_detail';
export const GET_FEED_POSTS = 'get_feed_posts';
export const CREATE_POST = 'create_post';
export const UPDATE_POST = 'update_post';

// Users Module
export const GET_USERS = 'GET_USERS';
export const ADD_USER = 'ADD_USER';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const EDIT_USER = 'EDIT_USER';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_BULK_USERS = 'DELETE_BULK_USERS';

// FM Provider Module
export const GET_FMPROVIDER = 'GET_FMPROVIDER';
export const ADD_FMPROVIDER = 'ADD_FMPROVIDER';
export const SET_FMPROVIDER_DETAILS = 'SET_FMPROVIDER_DETAILS';
export const EDIT_FMPROVIDER = 'EDIT_FMPROVIDER';
export const DELETE_FMPROVIDER = 'DELETE_FMPROVIDER';
export const DELETE_BULK_FMPROVIDER = 'DELETE_BULK_FMPROVIDER';

// Service type Module
export const GET_SERVICETYPE = 'GET_SERVICETYPE';
export const ADD_SERVICETYPE = 'ADD_SERVICETYPE';
export const SET_SERVICETYPE_DETAILS = 'SET_SERVICETYPE_DETAILS';
export const EDIT_SERVICETYPE = 'EDIT_SERVICETYPE';
export const DELETE_SERVICETYPE = 'DELETE_SERVICETYPE';
export const DELETE_BULK_SERVICETYPE = 'DELETE_BULK_SERVICETYPE';

//Tenant Bluk import Module
export const GET_TENANTBLUKIMPORT = 'GET_TENANTBLUKIMPORT';
export const ADD_TENANTBULKIMPORT = 'ADD_TENANTBULKIMPORT';
// export const GET_TENANT_ERROR_DETAILS = 'GET_TENANT_ERROR_DETAILS';
export const SET_TENANTBULK_IMPORT_DETAILS = 'SET_TENANTBULK_IMPORT_DETAILS';
export const GET_TENANT_SAMPLE_FILES_DETAILS = 'GET_TENANT_SAMPLE_FILES_DETAILS';
export const GET_EXPORT_DATA = 'GET_EXPORT_DATA';
export const GET_TENANTDETAILS_TABLE ='GET_TENANTDETAILS_TABLE';

// Sub Admin Management Module
export const GET_SUBADMIN = 'GET_SUBADMIN';
export const ADD_SUBADMIN = 'ADD_SUBADMIN';
export const SET_SUBADMIN_DETAILS = 'SET_SUBADMIN_DETAILS';
export const EDIT_SUBADMIN = 'EDIT_SUBADMIN';
export const DELETE_SUBADMIN = 'DELETE_SUBADMIN';
export const DELETE_BULK_SUBADMIN = 'DELETE_BULK_SUBADMIN';

// Service Activites
export const GET_SERVICEACTIVITIES = 'GET_SERVICEACTIVITIES';
export const ADD_SERVICEACTIVITIES = 'ADD_SERVICEACTIVITIES';
export const SET_SERVICEACTIVITIES_DETAILS = 'SET_SERVICEACTIVITIES_DETAILS';
export const EDIT_SERVICEACTIVITIES = 'EDIT_SERVICEACTIVITIES';
export const DELETE_SERVICEACTIVITIES = 'DELETE_SERVICEACTIVITIES';
export const DELETE_BULK_SERVICEACTIVITIES = 'DELETE_BULK_SERVICEACTIVITIES';

// Issue Management
export const GET_ISSUEMANAGEMENT = 'GET_ISSUEMANAGEMENT';
export const ADD_ISSUEMANAGEMENT = 'ADD_ISSUEMANAGEMENT';
export const SET_ISSUEMANAGEMENT_DETAILS = 'SET_ISSUEMANAGEMENT_DETAILS';
export const EDIT_ISSUEMANAGEMENT = 'EDIT_ISSUEMANAGEMENT';
export const DELETE_ISSUEMANAGEMENT = 'DELETE_ISSUEMANAGEMENT';
export const DELETE_BULK_ISSUEMANAGEMENT = 'DELETE_BULK_ISSUEMANAGEMENT';

// Content Manager
export const GET_ABOUTUS = 'GET_ABOUTUS';
export const ADD_ABOUTUS = 'ADD_ABOUTUS';
export const GET_PRIVACYPOLICY = 'GET_PRIVACYPOLICY';
export const ADD_PRIVACYPOLICY = 'ADD_PRIVACYPOLICY';
export const GET_TERMSANDCONDITIONS = 'GET_TERMSANDCONDITIONS';
export const ADD_TERMSANDCONDITIONS = 'ADD_TERMSANDCONDITIONS';
export const GET_FAQ = 'GET_FAQ';
export const ADD_FAQ = 'ADD_FAQ';
// Contact Us Query Module
export const GET_CONTACTUSQUERY = 'GET_CONTACTUSQUERY';
export const ADD_CONTACTUSQUERY = 'ADD_CONTACTUSQUERY';
export const SET_CONTACTUSQUERY_DETAILS = 'SET_CONTACTUSQUERY_DETAILS';
export const EDIT_CONTACTUSQUERY = 'EDIT_CONTACTUSQUERY';
export const DELETE_CONTACTUSQUERY = 'DELETE_CONTACTUSQUERY';
export const DELETE_BULK_CONTACTUSQUERY = 'DELETE_BULK_CONTACTUSQUERY';
export const GET_CONTACTUSDETAILS = 'GET_CONTACTUSDETAILS';

// setting
export const GET_VIRSION ='GET_VIRSION';

// Srevice Request 
export const GET_SERVICEREQUEST ='GET_SERVICEREQUEST';
export const ADD_SERVICEREQUEST = 'ADD_SERVICEREQUEST';
export const SET_SERVICEREQUEST_DETAILS = 'SET_SERVICEREQUEST_DETAILS';
export const EDIT_SERVICEREQUEST = 'EDIT_SERVICEREQUEST';
export const DELETE_SERVICEREQUEST = 'DELETE_SERVICEREQUEST';
export const DELETE_BULK_SERVICEREQUEST = 'DELETE_BULK_SERVICEREQUEST';
export const GET_SERVICEREQUESTDETAILS='GET_SERVICEREQUESTDETAILS';
export const GET_SERVICEREQUESTADD='GET_SERVICEREQUESTADD';
export const GET_SERVICEREQUESTQUOTATIONLIST='GET_SERVICEREQUESTQUOTATIONLIST';
export const GET_SERVICEREQUESTQUOTATIOCHECKNLIST='GET_SERVICEREQUESTQUOTATIOCHECKNLIST';

// Vendor Management
export const GET_VENDORMANAGEMENT = 'GET_VENDORMANAGEMENT';
export const ADD_VENDORMANAGEMENT = 'ADD_VENDORMANAGEMENT';
export const SET_VENDORMANAGEMENT_DETAILS = 'SET_VENDORMANAGEMENT_DETAILS';
export const EDIT_VENDORMANAGEMENT = 'EDIT_VENDORMANAGEMENT';

// Items List
export const GET_ITEMSLIST ='GET_ITEMSLIST';
export const ADD_ITEMSLIST = 'ADD_ITEMSLIST';
export const SET_ITEMSLIST_DETAILS = 'SET_ITEMSLIST_DETAILS';
export const EDIT_ITEMSLIST = 'EDIT_ITEMSLIST';
export const DELETE_ITEMSLIST = 'DELETE_ITEMSLIST';
export const DELETE_BULK_ITEMSLIST = 'DELETE_BULK_ITEMSLIST';
export const GET_ITEMSLISTDETAILS='GET_ITEMSLISTDETAILS';
export const GET_MANUFACTURERLIST='GET_MANUFACTURERLIST';
export const GET_WAREHOUSELIST='GET_WAREHOUSELIST';

//class category
export const GET_CLASSCATEGORYLIST ='GET_CLASSCATEGORYLIST';
export const GET_CLASSCATEGORYDETAILS='GET_CLASSCATEGORYDETAILS';
export const SET_CLASSCATEGORY_DETAILS = 'SET_CLASSCATEGORY_DETAILS';
export const SET_CLASSCATEGORY_ERROR = 'SET_CLASSCATEGORY_ERROR';

// type category
export const GET_TYPECATEGORYLIST ='GET_TYPECATEGORYLIST';
export const GET_TYPECATEGORYDETAILS='GET_TYPECATEGORYDETAILS';
export const SET_TYPECATEGORY_UPDATEDETAILS = 'SET_TYPECATEGORY_UPDATEDETAILS';
export const SET_TYPECATEGORY_ERROR = 'SET_TYPECATEGORY_ERROR';

// subtype category 
export const GET_SUBTYPECATEGORYLIST ='GET_SUBTYPECATEGORYLIST';
export const GET_SUBTYPECATEGORYDETAILS='GET_SUBTYPECATEGORYDETAILS';
export const SET_SUBTYPECATEGORY_UPDATEDETAILS = 'SET_SUBTYPECATEGORY_UPDATEDETAILS';
export const SET_SUBTYPECATEGORY_ERROR = 'SET_SUBTYPECATEGORY_ERROR';

//asset type
export const GET_ASSETTYPELIST ='GET_ASSETTYPELIST';
export const GET_ASSETTYPEDETAILS='GET_ASSETTYPEDETAILS';
export const SET_ASSETTYPE_UPDATE = 'SET_ASSETTYPE_UPDATE';
export const SET_ASSETTYPE_ERROR = 'SET_ASSETTYPE_ERROR';
export const SET_ASSETTYPE_EXPORT = 'SET_ASSETTYPE_EXPORT';

// manufacturer 
export const GET_MANUFACTURLIST ='GET_MANUFACTURLIST';
export const GET_MANUFACTUREEDETAILS='GET_MANUFACTUREEDETAILS';
export const SET_MANUFACTURE_UPDATE = 'SET_MANUFACTURE_UPDATE';
export const SET_MANUFACTURE_ERROR = 'SET_MANUFACTURE_ERROR';

// sample file
export const GET_SAMPLEFILE='GET_SAMPLEFILE';

// asset bulk import 
export const GET_ERRORLIST='GET_ERRORLIST';
export const GET_ASSETLIST ='GET_ASSETLIST';
export const GET_ASSETDETAILS='GET_ASSETDETAILS';
export const GET_ASSETDETAILS_TABLE ='GET_ASSETDETAILS_TABLE';

// <<<<<<< HEAD
// vendor bulk import 
export const GET_ERRORVENDORLIST='GET_ERRORVENDORLIST';
export const GET_VENDORLIST ='GET_VENDORLIST';
export const GET_VENDORDETAILS='GET_VENDORDETAILS';
export const GET_VENDORDETAILS_TABLE ='GET_VENDORDETAILS_TABLE';
export const GET_EXPORT_VENDOR ='GET_EXPORT_VENDOR';

// =======
// location bulk import 
export const GET_LOCATIONLIST ='GET_LOCATIONLIST';
export const GET_ERRORLISTLOCATION='GET_ERRORLISTLOCATION';
export const GET_LOCATIONDETAILS='GET_LOCATIONDETAILS';
export const GET_LOCATIONDETAILS_TABLE='GET_LOCATIONDETAILS_TABLE';

// Cotractor bulk import 
export const GET_CONTRACTORLIST ='GET_CONTRACTORLIST';
export const GET_CONTRACTORDETAILS='GET_CONTRACTORDETAILS';
export const GET_CONTRACTORDETAILS_TABLE ='GET_CONTRACTORDETAILS_TABLE';
export const GET_SYSTEMCATEGORYERROR ='GET_SYSTEMCATEGORYERROR';
// export const GET_VIRSION = 'GET_VIRSION';
