import React from 'react';
import Box from '@material-ui/core/Box';
// import useStyles from '../../index.style';
import useStyles from '../index.style';
import GridContainer from '../../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import StarsIcon from '@material-ui/icons/Stars';
import RateReviewIcon from '@material-ui/icons/RateReview';
import ImageIcon from '@material-ui/icons/Image';
import Typography from '@material-ui/core/Typography';
import Rating from '@material-ui/lab/Rating';

const TenantFeedback = ({ open, onCloseDialog, handelOpenAssignVendor, users }) => {
  const classes = useStyles();
  return (
    <>
      {/* <Divider variant="middle" /> */}
      <Box px={6} py={5}>
        {/* <Box mb={5} component="p" color="common.dark">
          Tenant Feedback:
        </Box> */}

        <GridContainer>
          <Grid item xs={6} sm={6}>
            <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
              <StarsIcon />
              <Box ml={5} display="flex">
                 
                <Typography className={classes.titleRoot1}>Rating : </Typography>
                    <Typography className={classes.titleRoot2}>
                    {/* <Box component="fieldset" mb={3} borderColor="transparent"> */}
        {/* <Typography component="legend">Controlled</Typography> */}
        <Rating
          name="simple-controlled"
          // value={value}
          // onChange={(event, newValue) => {
          //   setValue(newValue);
          // }}
        />
      {/* </Box> */}
                    </Typography>
              </Box>
            </Box>
          </Grid>
        </GridContainer>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <RateReviewIcon />
          <Box ml={5} display="flex">
          <Typography className={classes.titleRoot1}>Review:</Typography>
             {}
          </Box>
        </Box>

        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 5 }}>
          <ImageIcon />
          <Box ml={5} display="flex">
          <Typography className={classes.titleRoot1}>Images:</Typography>
             {}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default TenantFeedback;
