import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { requiredMessage } from '@jumbo/constants/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { getExportVendorFile } from 'redux/actions/BulkVendor';
import AppSelectBox from '@jumbo/components/Common/formElements/AppSelectBox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { getSampleFile } from 'redux/actions/SampaleFileDownload';
import { fetchStart, fetchSuccess } from 'redux/actions/Common';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.text.primary,
    },
  },
  serviceTypeList: {
    fontSize: 14,
    letterSpacing: 0.25,
    textOverflow: 'ellipsis',
    wordWrap: 'break-word',
  },
  typeList: {
    width: 535,
    fontSize: 14,
    height: 300,
    backgroundColor: theme.palette.background.default,
    overflow: 'auto',
  },
  imageButton: {
    fontSize: '10px',
    marginLeft: '5px',
    color: theme.palette.text.primary,
    textOverflow: 'ellipsis',
    maxWidth: '200px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  imageRoot: {
    marginTop: '5%',
  },
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
    '& .MuiFormLabel-root': {
      color: '#909090',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      color: theme.palette.text.primary,
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
  errText: {
    color: 'red',
    marginLeft: '16px',
    marginRight: '14px',
    fontSize: '0.75rem',
    marginTop: '3px',
    textAlign: 'left',
    fontFamily: 'Normal',
    fontWeight: 400,
    lineHeight: 1.66,
  },
  appSelectBox: {
    color: '#c7c3bf',
    '& .MuiFormLabel-root': {
      color: '#c7c3bf',
    },
  },
  paper: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '1000px',
    },
  },
  titleRoot1: {
    fontWeight: '600',
    whiteSpace: 'nowrap',
    '&.MuiTypography-body1': {
      fontSize: 14,
    },
  },
  titleRoot2: {
    fontWeight: '300',
    marginLeft: '5px',
    fontSize: 14,
    textOverflow: 'ellipsis',
    maxWidth: '350px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  userInfoRoot: {
    borderBottom: `1px solid ${theme.palette.borderColor.main}`,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
}));

const ImportExportSelection = ({ open, onCloseDialog, getSystemCtegorieParams }) => {
  const classes = useStyles();
  const { users } = useSelector(({ FMPROVIDER }) => FMPROVIDER);
  const { errorData } = useSelector(({ BULKVENDOR }) => BULKVENDOR);
  const { files } = useSelector(({ SAMPLEFILE }) => SAMPLEFILE);
  const { orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions, Vendor } = getSystemCtegorieParams;
  const [fmAdmin, setFMAdmin] = useState('');
  const [fmAdminError, setFMAdminError] = useState('');
  const [value, setValue] = React.useState('1');
  const dispatch = useDispatch();

  const onSubmitClick = () => {
    if (value == '1') {
      window.location.href = files?.link;
      onCloseDialog();
    }

    if (value == '2') {
      if (!fmAdmin) {
        setFMAdminError(requiredMessage);
      }
      if (fmAdmin) {
        onUserSave();
      }
    }
  };

  const onUserSave = () => {
    const exportVendorData = {
      fm_admin_id: fmAdmin,
    };
    dispatch(
      getExportVendorFile(
        fmAdmin,
        () => {
          onCloseDialog();
        },
        { onCloseDialog: () => onCloseDialog() },
      ),
    );
  };

  const handleChange = event => {
    setValue(event.target.value);
    setFMAdminError('');
  };

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>{'Select File'}</DialogTitle>

      <DialogContent dividers>
        <Box display="flex" mb={{ xs: 4, sm: 7 }}>
          <FormControl component="fieldset">
            <RadioGroup aria-label="query" name="query1" value={value} onChange={handleChange}>
              <FormControlLabel value="1" control={<Radio />} label="Download Sample File for Bulk Import" />
              <FormControlLabel value="2" control={<Radio />} label="Download FM Admin System Data" />
            </RadioGroup>
          </FormControl>
        </Box>

        {value == '2' ? (
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={12} className={classes.root}>
                <AppSelectBox
                  fullWidth
                  data={users?.data}
                  label="Fm Admin"
                  valueKey="_id"
                  variant="outlined"
                  labelKey="first_name"
                  value={fmAdmin}
                  onChange={e => {
                    setFMAdmin(prev => e.target.value);
                    setFMAdminError('');
                  }}
                  helperText={fmAdminError}
                />
              </Grid>
            </GridContainer>
          </Box>
        ) : null}

        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button onClick={onCloseDialog} variant="outlined">
            Cancel
          </Button>

          <Box ml={2}>
            <Button variant="contained" color="primary" style={{ minWidth: '100px' }} onClick={onSubmitClick}>
              Download
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ImportExportSelection;

ImportExportSelection.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
