import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import Button from '@material-ui/core/Button';
import { requiredMessage } from '@jumbo/constants/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
// import { DialogActions, DialogContentText} from "@material-ui/material";
// import { addNewContractor, getContractorList, updateClassCategoryList } from 'redux/actions/BulkContractor';
// import {getTenantBlukImport} from 'redux/actions/TenantBulkImport';
import {
  getTenantBlukImport,
  setCurrentTenantBluk,
  getTenantSampleFileDetails,
  addNewBulkImport,
} from 'redux/actions/TenantBlukImport';
import { emptySpace, numberOnly, numberOnly2, stringOnly } from '@jumbo/constants/ValidationRegex';
import { Typography } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import InputAdornment from '@material-ui/core/InputAdornment';
import NoRecordFound from '../NoRecordFound';
import { capitalizeFLetter } from '@jumbo/utils/commonHelper';
import AppSelectBox from '@jumbo/components/Common/formElements/AppSelectBox';
import InputLabel from '@material-ui/core/InputLabel';
// import AddToWarehouse from './AddToWarehouse'
import Divider from '@material-ui/core/Divider';
import ErrorTable from './ErrorTable';
// import useStyless from './index.style';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ErrorIcon from '@material-ui/icons/Error';
import { fetchSuccess } from 'redux/actions';
const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },

  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.text.primary,
    },
  },
  serviceTypeList: {
    fontSize: 14,
    letterSpacing: 0.25,
    textOverflow: 'ellipsis',
    wordWrap: 'break-word',
  },
  typeList: {
    width: 535,
    fontSize: 14,
    height: 300,
    backgroundColor: theme.palette.background.default,
    overflow: 'auto',
  },
  imageButton: {
    fontSize: '10px',
    marginLeft: '5px',
    color: theme.palette.text.primary,
    textOverflow: 'ellipsis',
    maxWidth: '200px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  imageRoot: {
    marginTop: '5%',
  },
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
    '& .MuiFormLabel-root': {
      color: '#909090',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      color: theme.palette.text.primary,
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
  errText: {
    color: 'red',
    marginLeft: '16px',
    marginRight: '14px',
    fontSize: '0.75rem',
    marginTop: '3px',
    textAlign: 'left',
    fontFamily: 'Normal',
    fontWeight: 400,
    lineHeight: 1.66,
  },
  appSelectBox: {
    color: '#c7c3bf',
    '& .MuiFormLabel-root': {
      color: '#c7c3bf',
    },
  },
  paper: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '1000px',
    },
  },
  titleRoot1: {
    fontWeight: '600',
    whiteSpace: 'nowrap',
    '&.MuiTypography-body1': {
      fontSize: 14,
    },
  },
  titleRoot2: {
    fontWeight: '300',
    marginLeft: '5px',
    fontSize: 14,
    textOverflow: 'ellipsis',
    maxWidth: '350px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  disable_btn: {
    // @extend %primary-btn;
    maxWidth: '120px',
    fontSize: '12px',
    height: '35px',
    fontWeight: 'bold',
    borderRadius: ' 4px',
    marginRight: '10px',
    border: '1px solid #999999',
    // backgroundColor: '#cccccc',
    // color:'#666666',
  },
  add_btn: {
    // @extend %primary-btn,
    // extends:' %primary-btn',
    maxWidth: ' 120px',
    fontSize: '12px',
    height: ' 35px',
    fontWeight: 'bold',
    borderRadius: '4px',
    marginRight: '10px',
  },
  userInfoRoot: {
    borderBottom: `1px solid ${theme.palette.borderColor.main}`,
    // padding: '20px 24px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
}));

const AddEditUser = ({
  open,
  onCloseDialog,
  getTenantParams,
  setOpenWareHouseDialog,
  handelClose,
  errroDialog,
  setErrroDialog,
  addButton,
  setAddButton,
  liveData
}) => {
  const classes = useStyles();
  const { CurrentTenantBulk } = useSelector(({ TENANTBULK }) => TENANTBULK);

  const { users } = useSelector(({ FMPROVIDER }) => FMPROVIDER);
  const { errorData } = useSelector(({ TENANTBULK }) => TENANTBULK);
  console.log(errorData, 'Error');
  const { orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions, tenantType } = getTenantParams;
  // const { orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions, Location } = getSystemCtego  rieParams;
  const [classFile, setClassFile] = useState('');
  const [locationImage, setLocationImage] = useState('');
  const [fmAdmin, setFMAdmin] = useState('');
  const [csvError, setCsvError] = useState('');
  const [fmAdminError, setFMAdminError] = useState('');
  const [imageError, setImageError] = useState('');
  const dispatch = useDispatch();
  // const [errroDialog, setErrroDialog] = React.useState(false);
  // const [btnDisable, setBtnDisable] = useState(true);

  // useEffect(() => {
  //   setBtnDisable(false);
  // }, []);

  const onSubmitClick = e => {
    // setBtnDisable(false);

    if (!fmAdmin) {
      setFMAdminError(requiredMessage);
    }

    if (classFile.type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      setCsvError('Please Choose excel file.');
    }
    if (!classFile) {
      setCsvError(requiredMessage);
    }
    if (classFile && fmAdmin && classFile.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      // dispatch(fetchSuccess('Is in Progressing....'));
      setAddButton(true);
      onUserSave();
      onCloseDialog();
    }
  };

  const onUserSave = e => {
    // if (CurrentTenantBulk) {
    //   const formData = {
    //     category_name: classCategoryId,
    //   };

    //   onCloseDialog();
    //   dispatch(getTenantBlukImport(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions, tenantType));
    // } else {

    const formData = new FormData();
    formData.append('tenantImportFile', classFile);
    formData.append('fm_admin_id', fmAdmin);

    dispatch(
      addNewBulkImport(
        formData,
        {
          setErrroDialog: () => setErrroDialog(true),
          onCloseDialog: () => onCloseDialog(),
          setAddButton: () => setAddButton(false),
        },
        () =>
          dispatch(getTenantBlukImport(orderBy, order, filterOptions, debouncedSearchTerm, 0, rowsPerPage, tenantType)),
        // setBtnDisable(true),
      ),
    );

    // }
  };
  // const handelClose = () => {
  //   setErrroDialog(false);
  //   onCloseDialog();
  // };

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>Import Tenants</DialogTitle>

      <Box>
        {/* <Dialog
          className={classes.paper}
          open={errroDialog}
          onClose={handelClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-description">Error Details</DialogTitle>
          <Divider />
          <DialogContent>
            <Box className={classes.userInfoRoot}>
              <Box px={6} py={5}>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
                  <ImportExportIcon />
                  <Box ml={5} display="flex">
                    <Typography className={classes.titleRoot1}>Total Rows Imported : </Typography>
                    <Typography className={classes.titleRoot2}>{errorData?.data?.total_rows_imported}</Typography>
                  </Box>
                </Box>

                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
                  <KeyboardArrowRightIcon />
                  <Box ml={5} display="flex">
                    <Typography className={classes.titleRoot1}>Total Rows Processed : </Typography>
                    <Typography className={classes.titleRoot2}>{errorData?.data?.total_rows_processed}</Typography>
                  </Box>
                </Box>

                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 5 }}>
                  <ErrorIcon />
                  <Box ml={5} display="flex">
                    <Typography className={classes.titleRoot1}>Total Rows With Errors : </Typography>
                    <Typography className={classes.titleRoot2}>{errorData?.data?.total_rows_with_errors}</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            
          </DialogContent>
          <DialogActions>
            <Button onClick={handelClose} autoFocus variant="outlined">
              Cancel
            </Button>
          </DialogActions>
        </Dialog> */}
      </Box>

      <DialogContent dividers>
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
          <GridContainer>
            <Grid item xs={12} sm={12} className={classes.root}>
              <AppSelectBox
                fullWidth
                data={users?.data}
                label="Fm Admin"
                valueKey="_id"
                variant="outlined"
                labelKey="first_name"
                value={fmAdmin}
                onChange={e => {
                  setFMAdmin(prev => e.target.value);
                  setFMAdminError('');
                }}
                helperText={fmAdminError}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Button variant="contained" component="label">
                <CloudUploadIcon />
                <p className={classes.imageButton}>{classFile?.size ? classFile?.name : 'Choose Excel File'}</p>
                <input
                  type="file"
                  hidden
                  onChange={event => {
                    setClassFile(event.target.files[0]);
                    setCsvError('');
                  }}
                  accept=".xlsx"
                />
              </Button>{' '}
              <br />
              <p className={classes.errText} style={{ color: 'red' }}>
                {csvError}
              </p>
            </Grid>
          </GridContainer>
        </Box>
        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button onClick={onCloseDialog} variant="outlined">
            Cancel
          </Button>

          <Box ml={2}>
            <Button
              disabled={addButton || (liveData !== 100 && liveData!=null) ? true : false}
              // className={btnDisable ? classes.disable_btn : classes.add_btn}
              variant="contained"
              color="primary"
              style={{ minWidth: '100px' }}
              // disabled={warrantTakenOn ? false : true}
              onClick={onSubmitClick}>
              Import
            </Button>
          </Box>

          {/* <Box ml={2}>
            <Button variant="contained" color="primary" style={{ minWidth: '100px' }} onClick={onSubmitClick1}>
              check
            </Button>
          </Box> */}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditUser;

AddEditUser.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};

{
  /* {errorData?.data?.total_rows_with_errors.length > 0 ? (
              errorData?.data?.total_rows_with_errors ? (
                <ErrorTable />
              ) : null
            ) : null} */
}
{
  /* <ErrorTable /> */
}
{
  /* <ErrorTable /> */
}
