import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import Common from './Common';
import Dashboard from './Dashboard';
import TaskList from './TaskList';
import MailApp from './MailApp';
import Chat from './Chat';
import ContactApp from './ContactApp';
import ProfileApp from './ProfileApp';
import WallApp from './WallApp';
import Auth from './Auth';
import Users from './Users';
import FMProviderManagement from './FMProviderManagement';
import ServiceType from './ServiceType';
import ServiceActivities from './ServiceActivities';
import IssueManagement from './IssueManagement';
import SubAdminManagement from './SubAdminManegement';
import ContentManager from './ContentManager';
import ContactUsQuery from './ContactUsQuery'
import Setting from './Setting'
import ServiceRequest from './ServiceRequest'
import VendorManagement from './VendorManagement';
import SystemCategorieList from './SystemCategorieList';
import ClassCategory from './ClassCategory';
import TypeCategory from './TypeCategory';
import SubTypeCategory from './SubTypeCategory';
import AssetType from './AssetType';
import Manufacture from './Manufacture'
import SampleFileDownload from './SampleFileDownload';
import BulkAsset from './BulkAsset';
import BulkVendor from './BulkVendor';
import BulkLocation from './BulkLocation';
import BulkContractor from './BulkContractor';
import TenantBulk from './TenantBulk';
export default history =>
  combineReducers({
    router: connectRouter(history),
    common: Common,
    taskList: TaskList,
    dashboard: Dashboard,
    mailApp: MailApp,
    chat: Chat,
    auth: Auth,
    contactApp: ContactApp,
    profileApp: ProfileApp,
    wallApp: WallApp,
    usersReducer: Users,
    FMPROVIDER: FMProviderManagement,
    SERVICETYPE: ServiceType,
    SERVICEACTIVITIES: ServiceActivities,
    ISSUEMANAGEMENT:IssueManagement,
    SUBADMIN:SubAdminManagement,
    CMS:ContentManager,
    CONTACTUSQUERY:ContactUsQuery,
    SETTING:Setting,
    SERVICEREQUEST:ServiceRequest,
    VENDORMANAGEMENT:VendorManagement,
    SYSTEMCATEGORIE:SystemCategorieList,
    CLASSCATEGORY:ClassCategory,
    TYPECATEGORY:TypeCategory,
    SUBTYPECATEGORY:SubTypeCategory,
    ASSETTYPE:AssetType,
    MANUFACTURE:Manufacture,
    SAMPLEFILE:SampleFileDownload,
    BULKASSET:BulkAsset,
    BULKVENDOR:BulkVendor,
    BULKLOCATION:BulkLocation,
    BULKCONTRACTOR:BulkContractor,
    TENANTBULK: TenantBulk,
  });
