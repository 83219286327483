import axios from '../../services/auth/jwt/config';
import { fetchStart, fetchSuccess,fetchError } from './Common';
import { SET_DASHBOARD_DATA } from '../../@jumbo/constants/ActionTypes';
import { DashboardApi } from '../../@jumbo/constants/ApiConstatnt';
import { history } from 'redux/store';
import { setAuthUser } from './Auth';
export const fetchDashboardData = () => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(DashboardApi)
      .then(({ data }) => {
        dispatch(fetchSuccess());
        dispatch({
          type: SET_DASHBOARD_DATA,
          payload: data.data,
        });
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};
