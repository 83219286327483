import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';
import {
  DELETE_BULK_SUBADMIN,
  FETCH_DATA_SUCCESS,
  GET_SUBADMIN,
  SET_SUBADMIN_DETAILS,
} from '../../@jumbo/constants/ActionTypes';
import {
  listSubAdmin,
  addSubAdmin,
  updateSubAdmin,
  editSubAdmin,
  deleteSubAdmin,
} from '../../@jumbo/constants/ApiConstatnt';
import { history } from 'redux/store';
import { setAuthUser } from './Auth';

export const getSubAdmin = (sortBy, sort, filterOptions, searchTerm, page, limit, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(listSubAdmin, {
        params: { sortBy: `${sortBy}:${sort}`, q: searchTerm, page: page + 1, limit: limit, status: filterOptions[0] },
      })
      .then(data => {
        if (data.status === 200) {
          dispatch({ type: FETCH_DATA_SUCCESS });
          dispatch({ type: GET_SUBADMIN, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError());
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Sub Admin not found.') {
          if (callbackFun) callbackFun();
          dispatch(fetchError());
          dispatch({ type: GET_SUBADMIN, payload: [] });
        } else if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

export const setCurrentSubAdmin = user => {
  return dispatch => {
    dispatch({ type: SET_SUBADMIN_DETAILS, payload: user });
  };
};

export const addNewSubAdmin = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(addSubAdmin, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('New sub admin added successfully.'));
          }, 2000);
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

export const sentMailToUser = () => {
  return dispatch => {
    dispatch(fetchSuccess('Email has been sent to user successfully'));
  };
};

export const updateSubadminData = (id, user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(editSubAdmin + id, user)
      .then(data => {
        if (data) {
          dispatch(fetchSuccess('Sub admin updated successfully.'));
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};
export const updateSubAdminStatus = (data, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(updateSubAdmin, data)
      .then(response => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Sub admin status updated successfully.'));
          }, 2000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

export const deleteBulkUsers = (userIds, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/users/bulk-delete', { userIds })
      .then(response => {
        if (response.status === 200) {
          dispatch(fetchSuccess('Selected users were deleted successfully.'));
          dispatch({ type: DELETE_BULK_SUBADMIN, payload: userIds });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const deleteUser = (userId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete(deleteSubAdmin + userId)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess('Sub admin deleted successfully.'));
          dispatch({ type: GET_SUBADMIN, payload: [] });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};
