import React, { useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import FAQEditor from './FAQEditor/FAQEditor';
import ComponentsDemo from '../../../@jumbo/components/PageComponents/layouts/ComponentsDemo';
import { FAQSlug } from '../../../@jumbo/constants/ApiConstatnt';
import { useDispatch, useSelector } from 'react-redux';
import { getContent } from '../../../redux/actions/ContentManager';

const useStyles = makeStyles(theme => ({
  section: {
    '&:not(:last-child)': {
      marginBottom: theme.typography.pxToRem(32),
    },
  },
  sectionHeading: {
    marginBottom: theme.typography.pxToRem(16),
  },
}));


const FAQ = [
  {
    label: 'FAQ',
    link: 'faq',
    component: <FAQEditor />,
    filename: './CkEditorExample.txt',
  },
];

const breadcrumbs = [
  { label: 'Home', link: '/' },
  { label: 'FAQ', isActive: true },
];

export default function CkEditor() {
  const classes = useStyles();
  // const { users } = useSelector(({ CMS }) => CMS);
  // const dispatch = useDispatch()
  // useEffect(() => {
  //   dispatch(getContent(FAQSlug))
  // }, [])
  return (
    <ComponentsDemo pageTitle="Content Manager"  breadcrumbs={breadcrumbs}>
      {FAQ.map((menu, index) => (
        <Box key={index} id={menu.link} className={classes.section}>
          <Typography component="h3" variant="inherit" className={classes.sectionHeading}>
            {menu.label}
          </Typography>
          
            {menu.component}
       
        </Box>
      ))}
    </ComponentsDemo>
  );
}
