import { GET_ABOUTUS, GET_FAQ, GET_PRIVACYPOLICY, GET_TERMSANDCONDITIONS } from "../../@jumbo/constants/ActionTypes";
import { AboutUs, AboutUsSlug, addCMS, FAQ, FAQSlug, listCMS, PrivacyPolicy, PrivacyPolicySlug, TermAndCondition, TermAndConditionSlug } from "../../@jumbo/constants/ApiConstatnt";
import { fetchError, fetchStart, fetchSuccess } from './Common';
// import axios from 'axios';
import {history} from 'redux/store'
import axios from '../../services/auth/jwt/config';
import { setAuthUser } from "./Auth";
export const getContent = (slug,callbackFun) => {
    return dispatch => {
      dispatch({ type: GET_ABOUTUS, payload: null });
      dispatch({ type: GET_PRIVACYPOLICY, payload: null });
      dispatch({ type: GET_TERMSANDCONDITIONS, payload: null });
      dispatch({ type: GET_FAQ, payload: null });
      dispatch(fetchStart());
      
      axios
        .get(listCMS+slug)
        .then(data => {
          
          // if(searchTerm && !data?.data?.data?.providers?.length && data?.data?.data?.page>1) {dispatch(getFMProvider(sortBy, sort, searchTerm, 0, limit, filterOptions, callbackFun))}
          if (data.status === 200) {
           
            if(slug === AboutUsSlug){
              dispatch(fetchSuccess());
                dispatch({ type: GET_ABOUTUS, payload: data.data.data });
            
                if (callbackFun) callbackFun(data.data);
            }
            if(slug === PrivacyPolicySlug){
              dispatch(fetchSuccess());
                dispatch({ type: GET_PRIVACYPOLICY, payload: data.data.data });
                
                if (callbackFun) callbackFun(data.data);
            }
            if(slug === TermAndConditionSlug){
              dispatch(fetchSuccess());
                dispatch({ type: GET_TERMSANDCONDITIONS, payload: data.data.data });
                if (callbackFun) callbackFun(data.data);
            } 
            
            if(slug === FAQSlug){
              dispatch(fetchSuccess());
                dispatch({ type: GET_FAQ, payload: data.data.data });
                if (callbackFun) callbackFun(data.data);
            }
           
          } else {
            dispatch(fetchError('There was something issue in responding server.'));
          }
        })
        .catch(error => {
       
          if(error?.response?.status===401 ){
            dispatch(setAuthUser(null));
            history.push('/signin')
          } 
          else {
            console.log('error',error?.response?.status)
            dispatch(fetchError(error?.response?.data?.message));
          }
            dispatch(fetchError(error?.response?.data?.message));
          
        });
    };
  };

  export const addContent = (slug, data, callbackFun) => {
    return dispatch => {
    //   dispatch(fetchStart());
      axios
        .post(addCMS+slug, data)
        .then(data => {
          if (data) {
            // setTimeout(() => {
            //   dispatch(fetchSuccess('New content updated successfully.'));
            // }, 2000);
  
            // dispatch({ type:  ADD_FMPROVIDER, payload: data.data });
            if (callbackFun) callbackFun(data.data);
          } else {
            dispatch(fetchError('There was something issue in responding server.'));
          }
        })
        .catch(error => {
          if(error?.response?.status===401 ){
            dispatch(setAuthUser(null));
            history.push('/signin')
          } 
          else {
            console.log('error',error?.response?.status)
            dispatch(fetchError(error?.response?.data?.message));
          }
        });
    };
  };
  