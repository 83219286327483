import React, { useEffect, useState } from 'react';
import { Paper, Table, TableCell, TableContainer, TableRow } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Divider from '@material-ui/core/Divider';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Typography } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import UserListRow from './UserListRow';
import UserTableHead from './UserTableHead';
import UserTableToolbar from './UserTableToolbar';
import { getComparator, stableSort } from '@jumbo/utils/tableHelper';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUser, getUsers, setCurrentUser } from 'redux/actions/Users';
import AddEditUser from './AddEditUser';
import ConfirmDialog from '@jumbo/components/Common/ConfirmDialog';
import { useDebounce } from '@jumbo/utils/commonHelper';
import ErrorTable from './AddEditUser/ErrorTable';
import useStyles from './index.style';
// import Dialog from '@material-ui/core/Dialog';
import UserDetailView from './UserDetailView';
import NoRecordFound from './NoRecordFound';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { useParams } from 'react-router';
import {
  getTenantBlukImport,
  setCurrentTenantBluk,
  getTenantSampleFileDetails,
  getExportSampleFileData,
} from 'redux/actions/TenantBlukImport';
import { user } from '@fake-db/apps/profile';
import { getFMProvider } from 'redux/actions/FMProviderManagement';
import SelectTenantFile from './UserTableToolbar/SelectTenantFile';
const breadcrumbs = [
  { label: 'Home', link: '/' },

  { label: 'Tenant Import', isActive: true },
];

const UsersModule = ({socket}) => {
  const classes = useStyles();
  // const { users } = useSelector(({ SERVICETYPE }) => SERVICETYPE);
  const { TenantBulkUser, errorData } = useSelector(({ TENANTBULK }) => TENANTBULK);

  const [orderBy, setOrderBy] = React.useState('created_at');
  const [order, setOrder] = React.useState('desc');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [openSelectFileDialog, setOpenSelectFileDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState({ name: '' });
  const [usersFetched, setUsersFetched] = useState(false);
  const [isFilterApplied, setFilterApplied] = useState(false);
  const [filterOptions, setFilterOptions] = React.useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchedData, setSearchedData] = useState('debouncedSearchPagevalidation');
  const [errroDialog, setErrroDialog] = React.useState(false);
  const [openWareHouseDialog, setOpenWareHouseDialog] = useState(false);
  const [addButton, setAddButton] = React.useState(false);
  const [onlyRowData, setOnlyRowData] = useState(1)
  const [liveData,setLiveData]=useState(null)
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  console.log(errroDialog, 'errroDialog');
  // console.log("service_type_user",users)
  console.log('TenantBulk', TenantBulkUser);
  const tenantType = 3;
  // const tenant = 3;
  const dispatch = useDispatch();
  let { _id } = useParams();

  useEffect(() => {
    socket.on('tenantBulkImportLiveUpdate', (data) => {
      console.log('socket message',data)
      setLiveData(data)
    });
    return () => {
      socket.off('tenantBulkImportLiveUpdate');
    };
  }, [])
  
  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm !== searchedData) {
      dispatch(
        getTenantBlukImport(orderBy, order, filterOptions, debouncedSearchTerm, 0, rowsPerPage, tenantType, () => {
          setFilterApplied(!!filterOptions.length || !!debouncedSearchTerm);
          setUsersFetched(true);
        }),
      );
      setSearchedData(debouncedSearchTerm);
      setPage(0);
    } else {
      dispatch(
        getTenantBlukImport(orderBy, order, filterOptions, debouncedSearchTerm, page, rowsPerPage, tenantType, () => {
          setFilterApplied(!!filterOptions.length || !!debouncedSearchTerm);
          setUsersFetched(true);
        }),
      );
    }
    dispatch(getFMProvider('name', 'asc', '', 0, -1, [1]));
    dispatch(getTenantSampleFileDetails(3));
    // dispatch(getTenantSampleFileDetails(3,() => {
    //   handleCloseSelectFileDialog();}));
    // dispatch(getExportSampleFileData())
  }, [dispatch, filterOptions, debouncedSearchTerm, orderBy, order, page, rowsPerPage]);

  // console.log('filter', filterOptions);
  const handleCloseUserDialog = () => {
    setOpenUserDialog(false);
    // setOpenSelectFileDialog(false)
    dispatch(setCurrentUser(null));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelected = TenantBulkUser.map(n => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleRowClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleUserView = user => {
    dispatch(setCurrentTenantBluk(user?._id, 0, () => setOpenViewDialog(true)));
    dispatch(setCurrentTenantBluk(user?._id, onlyRowData));
  };

  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
    dispatch(setCurrentUser(null));
  };
  const handleCloseSelectFileDialog = () => {
    setOpenSelectFileDialog(false);
  };
  const handleUserEdit = user => {
    dispatch(setCurrentUser(user));
    setOpenUserDialog(true);
  };

  const handleUserDelete = user => {
    setSelectedUser(user);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
    dispatch(deleteUser(selectedUser.id));
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const isSelected = id => selected.indexOf(id) !== -1;

  const handelClose = () => {
    setErrroDialog(false);
    setAddButton(false);
    // onCloseDialog();
    handleCloseUserDialog();
  };
  return (
    <PageContainer>
      {/* <h1>data{liveData}</h1> */}
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <UserTableToolbar
            selected={selected}
            setSelected={setSelected}
            onUserAdd={setOpenUserDialog}
            onSelectFileDialog={setOpenSelectFileDialog}
            onUserAdd1={openUserDialog}
            filterOptions={filterOptions}
            setFilterOptions={setFilterOptions}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            setSearchedData={setSearchedData}
            setPage={setPage}
            liveData={liveData}
            getTenantParams={{ _id, orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions }}
          />
          <TableContainer className={classes.container}>
            <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" aria-label="sticky enhanced table">
              <UserTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                // rowCount={users.length}
                rowCount={TenantBulkUser?.total ?? 1}
              />
              <TableBody>
                {/* {console.log("mapusers",users)} */}
                {!!TenantBulkUser?.data?.length ? (
                  // stableSort(users?.data, getComparator(order, orderBy))
                  TenantBulkUser?.data
                    ?.slice(0 * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <UserListRow
                        key={index}
                        row={row}
                        indexId={page * rowsPerPage + index}
                        onRowClick={handleRowClick}
                        onUserEdit={handleUserEdit}
                        onUserDelete={handleUserDelete}
                        onUserView={handleUserView}
                        isSelected={isSelected}
                        getTenantParams={{ _id, orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions }}
                      />
                    ))
                ) : (
                  <TableRow style={{ height: 53 * 6 }}>
                    <TableCell colSpan={7} rowSpan={10}>
                      {isFilterApplied ? (
                        <NoRecordFound>There are no records found.</NoRecordFound>
                      ) : (
                        <NoRecordFound>{usersFetched ? 'There are no records found.' : 'Loading data...'}</NoRecordFound>
                      )}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={TenantBulkUser?.total ?? 1}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </Paper>

        {openUserDialog && (
          <AddEditUser
            open={openUserDialog}
            onCloseDialog={handleCloseUserDialog}
            setOpenWareHouseDialog={setOpenWareHouseDialog}
            setErrroDialog={setErrroDialog}
            errroDialog={errroDialog}
            handelClose={handelClose}
            addButton={addButton}
            setAddButton={setAddButton}
            liveData={liveData}
            // order, debouncedSearchTerm, page, rowsPerPage
            // sortBy, sort, filterOptions, searchTerm, page, limit, callbackFun
            // sortBy, order,filterOptions, debouncedSearchTerm, page, rowsPerPage
            getTenantParams={{ orderBy, order, filterOptions, debouncedSearchTerm, page, rowsPerPage, tenantType }}
          />
        )}
        {errorData?.data?.total_rows_with_errors.length > 0 ? (
          errorData?.data?.total_rows_with_errors ? (
            <ErrorTable />
          ) : null
        ) : null}
        {openViewDialog && <UserDetailView open={openViewDialog} onCloseDialog={handleCloseViewDialog} />}
        {openSelectFileDialog && (
          <SelectTenantFile open={openSelectFileDialog} onCloseDialog={handleCloseSelectFileDialog} />
        )}

        {errroDialog ? (
          <Dialog
            className={classes.paper}
            open={errroDialog}
            onClose={handelClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-description">Error Details</DialogTitle>
            <Divider />
            <DialogContent>
              <Box className={classes.userInfoRoot}>
                <Box px={6} py={5}>
                  
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
                    <KeyboardArrowRightIcon />
                    <Box ml={5} display="flex">
                      <Typography className={classes.titleRoot1}>Total Rows Processed : </Typography>
                      <Typography className={classes.titleRoot2}>{errorData?.data?.total_rows_processed}</Typography>
                    </Box>
                  </Box>
                  
                  <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
                    <ImportExportIcon />
                    <Box ml={5} display="flex">
                      <Typography className={classes.titleRoot1}>Total Rows Imported : </Typography>
                      <Typography className={classes.titleRoot2}>{errorData?.data?.total_rows_imported}</Typography>
                    </Box>
                  </Box>

                  <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 5 }}>
                    <ErrorIcon />
                    <Box ml={5} display="flex">
                      <Typography className={classes.titleRoot1}>Total Rows With Errors:</Typography>
                      <Typography className={classes.titleRoot2}>{errorData?.data?.total_rows_with_errors}</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {errorData?.data?.rows_with_errors.length > 0 ? <ErrorTable /> : null}
            </DialogContent>
            <DialogActions>
              <Button onClick={handelClose} autoFocus variant="outlined">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        ) : null}

        <ConfirmDialog
          open={openConfirmDialog}
          title={`Confirm delete ${selectedUser.name}`}
          content={'Are you sure, you want to delete this user?'}
          onClose={handleCancelDelete}
          onConfirm={handleConfirmDelete}
        />
      </div>
    </PageContainer>
  );
};

export default UsersModule;
