import {
    GET_VENDORLIST,
    GET_VENDORDETAILS,
    GET_VENDORDETAILS_TABLE,
    GET_ERRORVENDORLIST,
    GET_EXPORT_VENDOR,
  } from '../../@jumbo/constants/ActionTypes';
  
  const INIT_STATE = {
    users: [],
    currentUser: null,
    details:null,
    details_table:null,
    errorData:null,
    export:null,
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_VENDORLIST: {
        return {
          ...state,
          users: action.payload,
        };
      }
  
      case GET_ERRORVENDORLIST: {
        return {
          ...state,
          errorData: action.payload,
        };
      }
  
      case GET_VENDORDETAILS: {
        return {
          ...state,
          details: action.payload,
        };
      }

      case GET_VENDORDETAILS_TABLE: {
        return {
          ...state,
          details_table: action.payload,
        };
      }
      
      case GET_EXPORT_VENDOR: {
        return {
          ...state,
          export: action.payload,
        };
      }

      default:
        return state;
    }
  };
  