// FM Provider
export const listFMProvider = 'fm-provider/list';
export const addFMProvider = 'fm-provider/add';
export const updateFMProvider = 'fm-provider/status-change/';
export const editFMProvider = 'fm-provider/update/';
export const deleteFMProvider = 'fm-provider/delete/';
// Service Activities
export const listServiceActivities = 'service-activities/list';
export const addServiceActivities = 'service-activities/add';
export const updateServiceActivities = 'service-activities/status-change/';
export const updateServiceActivitieApi = '/service-activities/update/';
export const deleteServiceActivitieApi = '/service-activities/delete/';

//Tenant Bluk Import
export const listTenantBlukImport = 'bulkImport/bulk-import-list';
export const addnewTenantBulkImport = 'bulkImport/tenant/bulk-import';
// export const uploadTenantBulkFile = '/bulkImport/tenant/bulk-import'
export const tenantBulkImortDetails = '/bulkImport/details/';
export const sampleFileDownload = '/sample-files/details/';
export const getExportData = '/bulkImport/tenant/export-data';
// Issue Management
export const listIssue = 'issue/list';
export const addIssue = 'issue/add';
export const updateIssue = 'issue/status-change/';
export const updateIssueApi = '/issue/update/';
export const deleteIssueApi = '/issue/delete/';

// Service Type
export const listServiceType = '/service-type/list';
export const addServiceType = '/service-type/add';
export const updateServiceTypeStatus = 'service-type/status-change';
export const updateServiceType = '/service-type/update/';
export const deleteServiceType = '/service-type/delete/';

// Sub Admin Management
export const listSubAdmin = '/sub-admin/list';
export const addSubAdmin = '/sub-admin/add';
export const updateSubAdmin = '/sub-admin/status-change';
export const editSubAdmin = 'sub-admin/update/';
export const deleteSubAdmin = 'sub-admin/delete/';
// content page manager
export const listCMS = 'cms/details/';
export const addCMS = 'cms/update/';
export const AboutUsSlug = 'about_us';
export const PrivacyPolicySlug = 'privacy_policy';
export const TermAndConditionSlug = 'terms_&_conds';
export const FAQSlug = 'faq';

export const DashboardApi = 'dashboard/vcg-admin';

// setting
export const virsionList = '/version/list';
export const pushVirsionList = '/version/create';
// Contact Us Query
export const listContactUsQuery = '/contact-us/list';
export const detailsContactUsQuery = '/contact-us/details/';
export const statusContactUsQuery = '/contact-us/close-request/';

// Priority
export const listPriority = '/priorities/view-priority'
export const addPriority ='/priorities/set-priority'

// Service Request module
export const listservicerequest = '/service-request/list'
export const servicerequeststatusupdate = '/fm-provider/service-request/status-update'
export const servicerequestadd = '/fm-provider/service-request/assign-vendor/'
export const servicerequestdetails = '/service-request/details/'
export const servicerequestrequsetquotation = '/fm-provider/service-request/request-quotation/'
export const servicerequestrequsetapprovework = '/fm-provider/service-request/approve-work-by-fm/'
export const servicerequestrequsetquotationlist = '/fm-provider/service-request/quotations-list/'
export const deleteservicerequest = '/fm-provider/service-request/delete/'

// Vendor Management 
export const vendorList ='/fm-provider/vendor/list'
export const addVendor = '/fm-provider/vendor/add'
export const updateVendor = '/fm-provider/vendor/update'
export const statusUpdateVendor = '/fm-provider/vendor/status-update'
export const vendorDetail='/fm-provider/vendor/details/'
export const deleteVendor = '/fm-provider/vendor/delete/'


// System category 
export const listSyatemcategories = 'categories/list'
export const addSystemCategorie ='categories/import'
export const addSystemCategorieManually ='categories/add'
export const updateSystemCategorie = 'categories/status-change'
export const SystemCategorieupdate = 'categories/update/'
export const systemCategoriedetails = 'categories/details/'
export const deleteCategorie = 'categories/delete/'
export const ManufacturerList = 'fm-provider/fm-items/manufacturer/list'
export const addItemWarehouse ='fm-provider/fm-items/save-to-warehouse'

// class category 
export const listClassCategory = 'categories/list'
export const addClassCategory ='categories/import'
export const addClassCategoryManually ='categories/add'
export const classCategoryStatusUpdate = 'categories/status-change'
export const deleteClassCategorie = 'categories/delete/'
export const classCategoriedetails = 'categories/details/'
export const classCategoryupdate = 'categories/update/'

// type category
export const listTypeCategory = 'categories/list'
export const addTypeCategory ='categories/import'
export const addTypeCategoryManually ='categories/add'
export const typeCategoryStatusUpdate = 'categories/status-change'
export const deleteTypeCategorie = 'categories/delete/'
export const typeCategoriedetails = 'categories/details/'
export const typeCategoryupdate = 'categories/update/'


// suntype category
export const listSubTypeCategory = 'categories/list'
export const addSubTypeCategory ='categories/import'
export const addSubTypeCategoryManually ='categories/add'
export const subtypeCategoryStatusUpdate = 'categories/status-change'
export const deleteSubTypeCategorie = 'categories/delete/'
export const subtypeCategoriedetails = 'categories/details/'
export const subtypeCategoryupdate = 'categories/update/'

//asset type 
export const listAssetType = 'asset-type/list'
export const addAssetType ='asset-type/import'
export const addAssetTypeManually ='asset-type/add'
export const deleteAssetTypeId = 'asset-type/delete/'
export const assettypeStatusUpdate = 'asset-type/status-change'
export const assettypedetails = 'asset-type/details/'
export const assettypeupdate = 'asset-type/update/'
export const assettypeimportfile = 'bulkImport/asset/export-data'

// Manufacture 

export const listManufacture = 'manufacturer/list'
export const manufactuerStatusUpdate = 'manufacturer/status-change'
export const addManufacture ='manufacturer/import'
export const addManufactureManually ='manufacturer/add'
export const deleteMAnufacture = 'manufacturer/delete/'
export const manufacturedetails = 'manufacturer/details/'
export const manufactureupdate = 'manufacturer/update/'

//sample file 
export const samplefile='sample-files/details/'

// bulk asset
export const addAsset ='bulkImport/asset/bulk-import'
export const listAsset = 'bulkImport/bulk-import-list'
export const assetdetails = 'bulkImport/details/'

// <<<<<<< HEAD
//bulk vendor
export const addVendorBulk = 'bulkImport/vendor/bulk-import'
export const listVendor = 'bulkImport/bulk-import-list'
export const vendorDetails = 'bulkImport/details/'
export const exportVendorBulk = 'bulkImport/vendor/export-data'
// =======
//bulk location 
export const listLocation = 'bulkImport/bulk-import-list'
export const addLocation ='bulkImport/location/bulk-import'
export const locationdetails = 'bulkImport/details/'
export const locationexport = 'bulkImport/location/export-data'

// contractor bulk
export const listContractor = 'bulkImport/bulk-import-list'
export const addContractor ='bulkImport/contractor/bulk-import'
export const contractordetails = 'bulkImport/details/'
export const contractorexport = 'bulkImport/contractor/export-data'
